import {
  authReducer,
  AuthState, initialAuthState,
  initialNetworkFeatureState, initialState as layoutInitialState, layoutReducer,
  NetworkFeatureState, networkReducer, State as LayoutState
} from '@gfs/store/common';
import {
  addItemsReducer,
  FeatureState as addItemsState,
  initialState as addItemsInitialState
} from '@gfs/store/feature/add-items';
import {
  quickAddItemsReducer,
  FeatureState as quickAddItemsState,
  initialState as quickAddItemsInitialState
} from '@gfs/store/feature/quick-add-items';
import {
  cartReducer,
  FeatureState as CartFeatureState,
  initialState as cartFeatureInitialState
} from '@gfs/store/feature/cart';
import {
  customerUnitSelectionReducer,
  FeatureState as CustomerUnitFeature,
  initialState as CustomerUnitInitialState
} from '@gfs/store/feature/customer-unit-selection';
import * as customerItems from '@gfs/store/inventory/reducers/customerItems.reducer';
import * as worksheets from '@gfs/store/inventory/reducers/worksheets.reducer';
import {
  ActionReducerMap, createSelector
} from '@ngrx/store';
import * as category from './category.reducer';
import * as measurementUnit from './measurementUnit.reducer';
import * as recipe from './recipe.reducer';

export interface AppState {
  auth: AuthState;
  layout: LayoutState;
  category: category.State;
  network: NetworkFeatureState;
  recipe: recipe.State;
  addItemsFeature: addItemsState;
  quickAddItemsFeature: quickAddItemsState;
  measurementUnit: measurementUnit.State;
  cartFeature: CartFeatureState;
  customerUnitSelectionFeature: CustomerUnitFeature;
  worksheets: worksheets.State;
  customerItems: customerItems.State;
}

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  layout: layoutReducer,
  category: category.categoryReducer,
  recipe: recipe.recipeReducer,
  measurementUnit: measurementUnit.measurementUnitReducer,
  addItemsFeature: addItemsReducer,
  quickAddItemsFeature: quickAddItemsReducer,
  network: networkReducer,
  cartFeature: cartReducer,
  customerUnitSelectionFeature: customerUnitSelectionReducer,
  worksheets: worksheets.worksheetReducer,
  customerItems: customerItems.customerItemsReducer,

};

export const initialState = {
  auth: initialAuthState,
  network: initialNetworkFeatureState,
  layout: layoutInitialState,
  category: category.initialState,
  recipe: recipe.initialState,
  measurementUnit: measurementUnit.initialState,
  addItemsFeature: addItemsInitialState,
  quickAddItemsFeature: quickAddItemsInitialState,
  cartFeature: cartFeatureInitialState,
  customerUnitSelectionFeature: CustomerUnitInitialState,
  worksheets: worksheets.initialState,
  customerItems: customerItems.initialState
};

export const selectAllRecipes = createSelector(
  (state: AppState) => state.recipe,
  (rec) => {
    if (rec.recipes !== null) {
      return Object.keys(rec.recipes).map(id => {
        return rec.recipes[id];
      });
    } else {
      return [];
    }
  }
);

export const selectAllCategories = createSelector(
  (state: AppState) => state.category,
  (cat) => {
    if (cat.categories !== null) {
      return Object.keys(cat.categories).map(id => {
        return cat.categories[id];
      });
    } else {
      return [];
    }
  }
);
