<div class="customer-unit-heading">
  <h1 class="heading">
    {{ 'CUSTOMER_UNIT.SELECT_A_LOCATION' | translate }}
  </h1>
</div>

<form (ngSubmit)='submit()' [formGroup]="customerSearchForm">
  <div class="controls">
    <div>
      <label for="customerId">{{ 'CUSTOMER_UNIT.CUSTOMER_ID' | translate }}:</label>
      <input id="customerId" type="text" formControlName="customerId">
    </div>
    <div>
      <label for="customerName">{{ 'CUSTOMER_UNIT.CUSTOMER_NAME' | translate }}:</label>
      <input id="customerName" type="text" formControlName="customerName">
    </div>
    <div>
      <label for="city">{{ 'CUSTOMER_UNIT.CITY' | translate }}:</label>
      <input id="city" type="text" formControlName="city">
    </div>
    <div>
      <label for="stateOrProvinceCode">{{ 'CUSTOMER_UNIT.STATE_OR_PROVINCE_ABBR' | translate }}:</label>
      <input id="stateOrProvinceCode" type="text" formControlName="stateOrProvinceCode">
    </div>
    <div>
      <label for="countryCode">{{ 'CUSTOMER_UNIT.COUNTRY_ABBR' | translate }}:</label>
      <mat-select formControlName="countryCode" [(ngModel)]="selectedCountryCode">
        <mat-option *ngFor="let cCode of countryCodes" [value]="cCode">
          {{ cCode }}
        </mat-option>
      </mat-select>
    </div>
  </div>
  <button type="submit" class="primary-button large-button">{{ 'CUSTOMER_UNIT.SEARCH' | translate }}</button>
</form>

<ng-container [ngSwitch]="searchState$ | async">

  <ng-container *ngSwitchCase="2">
    {{ 'ADD_ITEMS.NO_RESULTS_SUB' | translate }}
  </ng-container>

  <ng-container *ngSwitchCase="1">
    <ng-container *ngFor="let result of searchResults$ | async">
      <mat-card>
        <div class="customer-search-result">
          <div class="result-details">
            <p>{{result.customerName}}, #{{result.customerId}}</p>
            <p>{{result.city}}, {{result.stateProvinceCode}}</p>
          </div>
          <div class="result-control">
            <button class="secondary-button" (click)="selectCustomerLocation(result)">
              {{ 'CUSTOMER_UNIT.SELECT' | translate }}
            </button>
          </div>
        </div>
      </mat-card>
    </ng-container>
  </ng-container>

</ng-container>