import {
    Constants,
    InventoryConstants
} from '@gfs/constants';
import {
    AuthResolver,
    InjectionTokens,
    featureAuthorizationGuard,
    setFeatureAuthorizationResolver,
    unitSelectionGuardFn
} from '@gfs/shared-services';
import {
    CustomerUnitSelectionComponentWrapperComponent,
    EditComponentWrapperComponent
} from '.';
import { UserContextFactoryService } from '@recipe-ui/services/architect/recipe-user-context-factory.service';
import { Authorization } from '@gfs/store/common';
import { getRecipeResolverFn } from '@recipe-ui/v2/resolvers/preloaders/get-recipe.resolver.fn';
import AuthorizedRoute from '@gfs/shared-services/models/AuthorizedRoute';
import { ApplicationUserRole } from '@gfs/shared-models';
import { AuthGuard, CallbackComponent } from '@gfs/shared-services/auth';
import { CustomerUnitSearchComponent } from './customer-unit-search/customer-unit-search.component';


const customerRecipeProfitCalculatorCustomerUnitSelectionRoute: AuthorizedRoute = {
    path: Constants.RecipeFeaturePaths
        .RECIPE_PROFIT_CALCULATOR[ApplicationUserRole.Customer]
        .RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATH,
    component: CustomerUnitSelectionComponentWrapperComponent,
    data: {
        entitlementFilter: InventoryConstants.RECIPE_CALCULATOR_OKTA_ROLES,
        requiredAuthorizations: undefined,
        setAuthorizations: {
            [Authorization.RecipeProfitCalculator]: true
        }
    },
    resolve: {
        setFeatureAuthorizationResolver
    },
    canActivate: [
        AuthGuard,
    ],
    providers: []
};

const employeeRecipeProfitCalculatorCustomerSearchRoute: AuthorizedRoute = {
    ...customerRecipeProfitCalculatorCustomerUnitSelectionRoute,
    component: CustomerUnitSearchComponent,
    path: Constants.RecipeFeaturePaths
        .RECIPE_PROFIT_CALCULATOR[ApplicationUserRole.Employee]
        .RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATH,
    canActivate: [
        AuthGuard
    ],
    resolve: {
        ...customerRecipeProfitCalculatorCustomerUnitSelectionRoute.resolve,
        auth: AuthResolver
    },
};


const customerRecipeProfitCalculatorRoute: AuthorizedRoute = {
    path: Constants.RecipeFeaturePaths
        .RECIPE_PROFIT_CALCULATOR[ApplicationUserRole.Customer]
        .RECIPE_PROFIT_CALCULATOR_PATH,
    component: EditComponentWrapperComponent,
    canActivate: [
        unitSelectionGuardFn,
        featureAuthorizationGuard,
        AuthGuard,
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
    resolve: {
        initialState: getRecipeResolverFn
    },
    data: {
        setAuthorizations: undefined,
        requiredAuthorizations: {
            [Authorization.RecipeProfitCalculator]: true
        }
    },
    providers: [
        {
            provide: InjectionTokens.IAPP_CONTEXT,
            useFactory: UserContextFactoryService.factory,
            deps: [UserContextFactoryService],
        }
    ]
}

const employeeRecipeProfitCalculatorRoute: AuthorizedRoute = {
    ...customerRecipeProfitCalculatorRoute,
    path: Constants.RecipeFeaturePaths
        .RECIPE_PROFIT_CALCULATOR[ApplicationUserRole.Employee]
        .RECIPE_PROFIT_CALCULATOR_PATH,
    canActivate: [
        featureAuthorizationGuard,
        AuthGuard
    ],
    resolve: {
        ...customerRecipeProfitCalculatorRoute.resolve,
        auth: AuthResolver
    }
};


export const RecipeProfitCalculatorRoutes: AuthorizedRoute[] = [
    {
        path: Constants.RecipeFeaturePaths.RECIPE_PROFIT_CALCULATOR.callback,
        component: CallbackComponent
    },
    customerRecipeProfitCalculatorCustomerUnitSelectionRoute,
    customerRecipeProfitCalculatorRoute,
    employeeRecipeProfitCalculatorCustomerSearchRoute,
    employeeRecipeProfitCalculatorRoute
];
