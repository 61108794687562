
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title class="recipe-name">
        {{ recipe.name }}
      </mat-panel-title>
      <app-category-recipe-card-triple-dot-menu
        class="tripledot-container"
        [recipe]="recipe"
        [isMobile]="isMobile"
        (deleteRecipeClicked)="deleteRecipeClicked.emit($event)"
      >
      </app-category-recipe-card-triple-dot-menu>
    </mat-expansion-panel-header>
    <div class="alignment-container">
      <app-category-recipe-card-pricing-information
        class="pricing-information"
        [recipe]="recipe"
      >
      </app-category-recipe-card-pricing-information>
    </div>
  </mat-expansion-panel>

