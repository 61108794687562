import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CustomerSearchResult, IAppContext } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import { GetCustomerLocations, IAuthStateFacade, SelectLocation } from '@gfs/store/common';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';

interface CustomerSearchForm {
  customerId: FormControl<string>,
  customerName: FormControl<string>,
  city: FormControl<string>,
  stateOrProvinceCode: FormControl<string>,
  countryCode: FormControl<string>
}

export enum SearchState {
  NoSearch = 0,
  SearchResults = 1,
  SearchResultsEmpty = 2
}

@Component({
  selector: 'gfs-customer-unit-search-component',
  templateUrl: 'customer-unit-search.component.html',
  styleUrls: ['./customer-unit-search.component.scss'],
})
export class CustomerUnitSearchComponent {

  customerSearchForm = new FormGroup<CustomerSearchForm>({
    customerId: new FormControl<string>(null),
    customerName: new FormControl<string>(null),
    city: new FormControl<string>(null),
    stateOrProvinceCode: new FormControl<string>(null),
    countryCode: new FormControl<string>(null),
  });

  searchResults$: Observable<CustomerSearchResult[]> = this.store
    .select(state => state.auth.customerSearchResults);
  isMobile$: Observable<boolean> = this.appContext.isMobile;
  searchState$: Observable<SearchState> = this.searchResults$
    .pipe(
      map(results => CustomerUnitSearchComponent.mapSearchResultsToSearchStates(results))
    );

  constructor(
    public store: Store<IAuthStateFacade>,
    @Inject(InjectionTokens.IAPP_CONTEXT) private appContext: IAppContext,
  ) { }

  countryCodes = [
    'CA',
    'US' // The primary use-case for the Recipe Profit Calculator is for US customers. 
  ];
  selectedCountryCode = this.countryCodes[1];

  static mapSearchResultsToSearchStates(
    searchResults: CustomerSearchResult[]
  ): SearchState {

    if (searchResults == null) {
      return SearchState.NoSearch
    }

    if (searchResults.length == 0) {
      return SearchState.SearchResultsEmpty
    }

    return SearchState.SearchResults
  }

  submit() {
    const form = this.customerSearchForm.value;
    this.store.dispatch(new GetCustomerLocations({
      customerSearchRequest: {
        customerId: form.customerId,
        customerName: form.customerName,
        city: form.city,
        stateOrProvinceCode: form.stateOrProvinceCode,
        countryCode: form.countryCode
      }
    }));
  }

  selectCustomerLocation(searchResult: CustomerSearchResult) {
    this.store.dispatch(new SelectLocation({
      customerPK: searchResult.customerPK,
      customerName: searchResult.customerName,
    }));
  }
}
