import { Inject, Injectable, Injector } from '@angular/core';
import {
    CustomerPK,
    Duration,
    RECIPE_PROFIT_CALCULATOR_CONFIG,
    RecipeProfitCalculatorConfig,
    IAppContext,
    IDictionary, ImageData, IngredientType,
    ItemReference,
    LocalizedMeasurementUnitOptionGroupx,
    PrintableIngredientEntry,
    PrintableRecipeVM,
    reallyCoolOpts,
    Recipe,
    RecipeCategory,
    RecipeIngredient,
    RecipeIngredientPrice,
    RecipePrice,
    RecipePriceRequest,
    RecipePriceType,
    ResolvedItem,
    ResolvedItemDataSource,
    ResolvedMeasurementUnitGroup
} from '@gfs/shared-models';
import {
    BaseComponentStore,
    CategoryService,
    defaultCalculatorConfiguration,
    getError,
    InjectionTokens, ItemDataService,
    LoadingState,
    LocalizedValueUtilsService,
    RecipeService, SavingState, unitGr, WINDOW
} from '@gfs/shared-services';
import { caseInsensitiveEquals } from '@gfs/shared-services/extensions/primitive';
import {
    firstValueFrom,
    isTruthy, toEntityDictionary,
    wrap$
} from '@gfs/shared-services/extensions/rxjs';
import { AppState } from '@gfs/store/inventory/reducers';
import { tapResponse } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { MeasurementUnitOptionService } from 'libs/shared-components/src/v2/form/measurement-unit-option.service';
import { createAdHocItemDataSource, DropDownOption, findItemInCollection, getItemDataFn$, isItemReferenceEqual } from 'libs/shared-components/src/v2/form/v2.form.module';
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, from, iif, Observable, of, range } from 'rxjs';
import { catchError, concatMap, filter, first, map, tap, toArray, withLatestFrom } from 'rxjs/operators';
import { calculateBatchPortionCost, recipeFormControlFeelConfig } from './form-factory.service';
import { UnitsHttpService } from 'libs/shared-services/src/v2/services/unit/unit-http-service.service';

export type RecipeState = {
    recipeBatchPortionCost?: number;
    recipePriceType: RecipePriceType;
    recipePriceValue: string;

    recipeTotalCost?: number;
    id?: string;
    name?: string;
    description?: string;
    categoryId?: string;
    type?: 'RECIPE' | 'BATCHRECIPE' | 'MENUITEMRECIPE';
    menuPrice?: number;
    margin?: string
    foodCostPercent?: string;
    batchYieldUnit?: string;
    batchYieldQty?: string;
    cookTimeHours?: string; // time required to cook recipe
    cookTimeMinutes?: string; // time required to cook recipe
    prepTimeHours?: string; // time required to prepare recipe
    prepTimeMinutes?: string; // time required to prepare recipe
    prepInstructions?: string; // preparation instructions
    ingredients?: RecipeIngredientState[];
    images?: RecipeImageState[];
    entityState: EntityState;
    calculationState: CalculationState;
};

export interface RecipeImageState {
    key?: string;
    content: string;
    entityState: EntityState;
    ordinal: number;
}

export type RecipeIngredientState = {
    id: string,
    itemId: string,
    itemType: IngredientType,
    sourceItemId: string,
    sourceItemType: IngredientType,
    unitType: string,
    quantity: string,
    yieldPercent: string,
    purchasePrice: number;
    portionPrice: number;
    calculationState: CalculationState;
    hasCompletedOneCalculation: boolean;
    ordinal: number; // Not returned from api. Mapped in RecipeService
    entityState: EntityState;
    configuredCountingUnits: string[];
};

export type ManageRecipeVM = {
    isLoading: boolean;
    isSaving: boolean;
    isSaveComplete: boolean;
    isReady: boolean;
    recipe: RecipeState;
    recipeCategories: RecipeCategory[];
    measurementUnits: LocalizedMeasurementUnitOptionGroupx[];
    measurementUnitsLookup: IDictionary<DropDownOption>;
    error: string;
};


export const enum EntityState {
    NEW = 'NEW',
    UPDATED = 'UPDATED',
    DELETED = 'DELETED',
    PERSISTED = 'PERSISTED',
    PLACEHOLDER = 'PLACEHOLDER'
}

export const enum CalculationState {
    WritingData = 'WRITINGDATA',
    New = 'NEW',
    NotReady = 'NOT_READY',
    CalculationFailed = 'FAILED',
    CalculationPending = 'PENDING',
    CalculationInProgress = 'INPROGRESS',
    CalculationComplete = 'RESOLVED',
}

export interface ErrorState {
    errorMsg: string;
}

export type CallState = LoadingState | SavingState | ErrorState;
const DefaultRecipeType = 'RECIPE';

export interface RecipeEditState {
    callState: CallState;
    recipe?: RecipeState;
    categories?: RecipeCategory[];
    itemData?: ResolvedItem[];
    measurementUnits?: ResolvedMeasurementUnitGroup[];
}

@Injectable({
    providedIn: 'root'
})
export class RecipeEditContainerFactory {

    private instanceSubject$ = new BehaviorSubject<RecipeEditStore>(null);
    instance$ = this.instanceSubject$.pipe(isTruthy());
    constructor(private injector: Injector) { }
    reset() {
        const newInstance = this.injector.get(RecipeEditStore);
        this.instanceSubject$.next(newInstance);
    }
}

const EmptyDurationData = {
    hours: '',
    minutes: '',
};

const DefaultDurationData = EmptyDurationData;
const artificalUnassignedCategory = {
    name: 'CATEGORY.UNASSIGNED',
    id: '',
    ordinal: -1
} as RecipeCategory;


@Injectable()
export class RecipeEditStore extends BaseComponentStore<RecipeEditState> {

    public recipeId : string = '';
    public itemRefList: ItemReference[] =[]

    constructor(
        @Inject(InjectionTokens.IAPP_CONTEXT) public appContext: IAppContext,
        store: Store<AppState>,
        private itemDataSvc: ItemDataService,
        private recipeService: RecipeService,
        private categoryService: CategoryService,
        private translate: TranslateService,
        private measurementUnitOptSvc: MeasurementUnitOptionService,
        @Inject(RECIPE_PROFIT_CALCULATOR_CONFIG) public recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
        @Inject(WINDOW) public window: Window,
    ) {
        super(store, {
            callState: LoadingState.INIT,
        });
    }
    mapRecipeImageStateToImageData = mapRecipeImageStateToImageDataFn;
    createNewRecipeIngredient = createNewRecipeIngredientFn;

    //#region internal selectors
    private readonly error$: Observable<string> = this.select(state => getError(state.callState));

    private readonly recipe$ = this.select(({
        recipe
    }) => recipe);

    private isLoading$ = this.select(({ callState }) => callState === LoadingState.LOADING);
    private isReady$ = this.select(({ callState }) => callState === LoadingState.LOADED);
    private isSaving$ = this.select(({ callState }) => callState === SavingState.SAVING);
    private isSaveComplete$ = this.select(({ callState }) => callState === SavingState.SAVED);

    //#region UPDATERS
    updateError = this.updater((state: RecipeEditState, error: string) => {
        return ({
            ...state,
            callState: {
                errorMsg: error
            }
        });
    });

    setLoading = this.updater((state: RecipeEditState) => ({
        ...state,
        callState: LoadingState.LOADING
    }));

    setLoaded = this.updater((state: RecipeEditState) => ({
        ...state,
        callState: LoadingState.LOADED
    }));

    setSaving = this.updater((state: RecipeEditState) => ({
        ...state,
        callState: SavingState.SAVING
    }));

    setSaved = this.updater((state: RecipeEditState) => ({
        ...state,
        callState: SavingState.SAVED
    }));

    appendNewCategory = this.updater((state: RecipeEditState, newCategory: RecipeCategory) => ({
        ...state,
        categories: [...state.categories, newCategory]
    }));

    getItemDataFnRef$ = getItemDataFn$;

    //#endregion
    //#region EFFECTS
    initRecipeById$(recipeId$: string | Observable<string>): Observable<[RecipeState, RecipeCategory[]]> {
        this.itemRefList =[]
        return wrap$(recipeId$)
            .pipe(
                tap(() => { this.setLoading(); }),
                concatMap(recipeId =>
                    this.refreshRecipePriceById$(recipeId)
                        .pipe(
                            map(() => recipeId),
                            catchError(() => of(recipeId)),
                        )
                ),
                concatMap(recipeId => this.getRecipeData$(recipeId)),
                withLatestFrom(this.appContext.customerPK),
                concatMap(([[recipeItemData, itemData], customerPK]) => {
                    return forkJoin([
                        this.mapResolvedItemToRecipeState$(recipeItemData),
                        this.getSortedRecipeCategories$(customerPK)
                    ])
                        .pipe(
                            tapResponse(([recipe, categories]) => {
                                this.setState(state => ({
                                    ...state,
                                    itemData: [...itemData, recipeItemData],
                                    recipe,
                                    categories,
                                    measurementUnits: recipeItemData.units.units
                                }));
                                this.setLoaded();
                            },
                                (e: string) => this.updateError(e)
                            )
                        );
                }
                )
            );
    }

    initNewRecipe$():
        Observable<[RecipeState, RecipeCategory[], ResolvedMeasurementUnitGroup[]]> {
        this.recipeId = '';
        this.itemRefList =[];
        return firstValueFrom(this.appContext.customerPK)
            .pipe(
                tap(() => {
                    this.setLoading();
                }),
                first(),
                concatMap((customerPK) =>
                    forkJoin([
                        of(customerPK),
                        this.getNewRecipeData$()
                    ])
                ),
                concatMap(([customerPK, [recipeItemData, itemData]]) => {
                    return forkJoin([
                        this.mapResolvedItemToRecipeState$(recipeItemData),
                        this.getSortedRecipeCategories$(customerPK),
                        firstValueFrom(this.itemDataSvc.getUOMsForItemReference(recipeItemData)
                            .pipe(map(h => {
                                return h.units.units;
                            })))
                    ])
                        .pipe(catchError(err => {
                            throw err;
                        })
                        )
                        .pipe(
                            tapResponse(([recipe, categories, measurementUnits]) => {
                                this.setState(state => ({
                                    ...state,
                                    itemData,
                                    recipe,
                                    categories,
                                    measurementUnits
                                }));
                                this.setLoaded();
                            },
                                (e: string) => this.updateError(e)
                            )
                        );
                }
                ),
                first()
            );
    }

    private getSortedRecipeCategories$(customerPK: CustomerPK) {
        return forkJoin([
            this.categoryService.getCategories(customerPK)
                .pipe(
                    first(),
                    map(categoryResult => [artificalUnassignedCategory, ...categoryResult])
                ),
            this.categoryService.getCategoriesOrder(customerPK).pipe(first())
        ]).pipe(
            map(([categories, categoriesOrder]) => [...categories].sort(
                (a, b) => categoriesOrder.order.indexOf(a.id) - categoriesOrder.order.indexOf(b.id)
            ))
        );
    }

    updateRecipe$(value: RecipeState): Observable<{ updatedRecipeId: string }> {
        return firstValueFrom(this.appContext.customerPK)
            .pipe(
                tap(() => { this.setSaving(); }),
                withLatestFrom(this.select(s => s.itemData)),
                concatMap(([customerPK, itemData]) => {

                    const recipeImageSignedUrls = value.images
                        .map(vm => this.mapRecipeImageStateToImageData(vm));

                    const ingredients = value.ingredients.map((r) => {
                        const ref = itemData.find(g =>
                            g.itemReference.key === r.itemId
                            && g.itemReference.type === r.itemType
                        );
                        const { key: itemId, type: itemType } = ref.itemReference.parent || ref.itemReference;
                        const {
                            unitType: unit,
                            quantity,
                            id: ingredientId
                        } = r;

                        return ({
                            customerPK,
                            id: ingredientId,
                            recipeId: value.id,
                            itemId,
                            itemType,
                            unit,
                            quantity: quantity?.replace(',', '.'),

                        } as RecipeIngredient);
                    });
                    const ingredientsOrder = ingredients.map(h => h.id).filter(r => !!r);

                    /* eslint-disable prefer-const */
                    let {
                        name,
                        categoryId,
                        description,
                        id,
                        type,
                        batchYieldQty: totalYieldQty,
                        batchYieldUnit: totalYieldUnit,
                        menuPrice,
                        prepInstructions,
                        prepTimeHours,
                        prepTimeMinutes,
                        cookTimeHours,
                        cookTimeMinutes,
                    } = value;
                    if (categoryId === '') { categoryId = null; }


                    const recipe = {
                        name,
                        id,
                        customerPK,
                        categoryId,
                        description,
                        ingredientsOrder,
                        ingredients,
                        details: {
                            batch: {
                                totalYieldQty: totalYieldQty?.replace(',', '.'),
                                totalYieldUnit
                            },
                            menu: {
                                menuPrice
                            }
                        },
                        preparationsDetails: {
                            prepInstructions,
                            cookTime: {
                                hours: cookTimeHours,
                                minutes: cookTimeMinutes
                            },
                            prepTime: {
                                hours: prepTimeHours,
                                minutes: prepTimeMinutes
                            }
                        },
                        recipeImageSignedUrls
                    } as Recipe;

                    if (caseInsensitiveEquals(type, 'BATCHRECIPE')) {
                        delete recipe.details.menu;
                    }
                    if (caseInsensitiveEquals(type, 'MENUITEMRECIPE')) {
                        delete recipe.details.batch;
                    }

                    return forkJoin([
                        of(recipe),
                        iif(() => {
                            return !!recipe.id;
                        },
                            this.recipeService.getIngredientsByRecipeId({ recipeId: recipe.id }),
                            of<RecipeIngredient[]>([]))
                        ,
                    ]).pipe(
                        map(([recipeUpdate, originalIngredients]) => {
                            const ingredientsIndex = recipeUpdate.ingredients
                                .map(r => r.id)
                                .filter(j => !!j);

                            originalIngredients
                                .filter(r => ingredientsIndex.indexOf(r.id) === -1)
                                .map(ingredient => ({
                                    ...ingredient,
                                    isDeleted: true
                                }))
                                .forEach(deletedIngredient => {
                                    recipeUpdate.ingredients.push(deletedIngredient);
                                });

                            return recipeUpdate;
                        })
                    );
                }),
            ).pipe(
                concatMap(recipe => this.upsertRecipe$(recipe)),
                map(recipe => ({ updatedRecipeId: recipe.id })),
                catchError((err) => { throw err; }),
                tap(() => { this.setSaved(); }),
            );
    }

    upsertRecipe$(
        recipe: Recipe
    ): Observable<Recipe> {
        if (this.recipeProfitCalculatorConfig.isNavigatingFromCalculator(this.window)) {
            return of(recipe);
        }

        return iif(
            () => !!recipe.id,
            this.recipeService.patchRecipe(recipe),
            this.recipeService.createRecipe(recipe, false)
        );
    }

    createNewRecipeCategory$(categoryName: string): Observable<RecipeCategory> {
        return firstValueFrom(this.appContext.customerPK)
            .pipe(
                concatMap((customerPK) => this.categoryService.createCategory(categoryName, customerPK)),
                tap(x => { this.appendNewCategory(x); }),
                catchError(() => EMPTY)
            );
    }

    recalculatePricing$(req: RecipePriceRequest): Observable<RecipePrice> {
        const opts = { ...this.getDefaultMeasurementUnitOptions(), useDisplayItem: false };

        return of(req)
            .pipe(
                withLatestFrom(this.appContext.customerPK),
                map(([recipePriceRequest, customerPK]) => ({ ...recipePriceRequest, customerPK } as RecipePriceRequest)),
                concatMap(priceReq => forkJoin([
                    of(priceReq),
                    from(priceReq.ingredients)
                        .pipe(
                            concatMap(ingredientPriceRequest => {
                                const ingredientRef = {
                                    key: ingredientPriceRequest.ingredientId,
                                    type: ingredientPriceRequest.ingredientType
                                } as ItemReference;
                                return forkJoin([
                                    of(ingredientPriceRequest),
                                    firstValueFrom(this.getItemData$(ingredientRef, opts))
                                ]).pipe(
                                    map(([b, resolvedItem]) => {
                                        if (resolvedItem.itemReference.parent) {
                                            const { key, type } = resolvedItem.itemReference.parent;
                                            b.ingredientId = key;
                                            b.ingredientType = type as IngredientType;
                                        }
                                        return b;
                                    })
                                );
                            }),
                            toArray(),
                        )
                ]))
            ).pipe(
                map(([a, ingredients]) => ({ ...a, ingredients })),
                tap(() => {
                    this.setLoading();
                }),
                concatMap((y) => {
                    console.log('Remote calculation in progress..');
                    return this.recipeService.priceCalculator(y);
                }),
                concatMap(w => {
                    return forkJoin([
                        of(w),
                        from(w.ingredientPrices)
                            .pipe(
                                concatMap(q => {
                                    const o = this.getDefaultMeasurementUnitOptions();
                                    return forkJoin([
                                        of(q),
                                        firstValueFrom(this.getItemDataForDisplay$(
                                            { key: q.ingredientId, type: q.ingredientType } as ItemReference, o)
                                        )
                                    ]).pipe(
                                        map(([b, n]) => {
                                            if (n.itemReference.parent) {
                                                const { key, type } = n.itemReference.parent;
                                                b.ingredientId = key;
                                                b.ingredientType = type as IngredientType;
                                            } else {
                                                const { key, type } = n.itemReference;
                                                b.ingredientId = key;
                                                b.ingredientType = type as IngredientType;
                                            }
                                            return b;
                                        })
                                    );
                                }),
                                toArray()
                            )
                    ]);
                }),
                map(([priceResult, ingredientPrices]) => ({ ...priceResult, ingredientPrices })),
                tapResponse(
                    () => {
                        this.setLoaded();
                    },
                    (e: string) => { this.updateError(e); }
                )
            );
    }


    createNewIngredient$(itemReference: ItemReference) {
        if(itemReference.type === "GFS"){
            this.itemRefList.push(itemReference)
        }
        return firstValueFrom(this.itemDataSvc.resolveItemsByReference(itemReference.type === "GFS" ? this.itemRefList : [itemReference], this.recipeId , 'createIngredients'))
            .pipe(
                concatMap(resolvedItems => {
                    const referencedItem = findItemInCollection(resolvedItems, itemReference, false);
                    return forkJoin([
                        of(resolvedItems),
                        this.mapResolvedRecipeIngredientToRecipeIngredientState$([
                            this.createNewRecipeIngredient(referencedItem),
                        ]).pipe(
                            map(newIngredients => newIngredients[0]),
                        ),
                    ]);
                }),
                first(),
                tap(([allResults, newIngredientState]) => {
                    this.setState((state) => ({
                        ...state,
                        itemData: [...state.itemData, ...allResults],
                        recipe: {
                            ...state.recipe,
                            ingredients: [...state.recipe.ingredients, newIngredientState]
                        }
                    }));
                })
            );
    }

    getRecipeData$(selectedId: string): Observable<[ResolvedItem, ResolvedItem[]]> {
        return of(selectedId)
            .pipe(
                first(),
                map(Id => this.createRecipeItemReferenceFromId(Id)),
                concatMap((recipeItemRef) => this.loadRecipeData$(recipeItemRef,selectedId)),
            );
    }

    getNewRecipeData$(): Observable<[ResolvedItem, ResolvedItem[]]> {
        return forkJoin([
            of({
                itemReference: {
                    type: 'MENUITEMRECIPE'
                },
                recipeItem: {
                }
            } as ResolvedItem
            ),
            of(new Array<ResolvedItem>())
        ]);
    }


    getItemData$(itemRef: ItemReference, opts?: reallyCoolOpts): Observable<ResolvedItem> {
        opts = {
            ...this.getDefaultMeasurementUnitOptions(),
            ...opts
        };
        return this.getItemDataFnRef$(itemRef, opts);
    }

    isItemTypeInItemTypeUnitsConfiguration(
        itemData: ResolvedItem,
    ): boolean {
        const type = itemData?.itemReference?.type;
        return type in unitGr;
    }

    getItemDataForDisplay$(
        itemref: ItemReference,
        opts: reallyCoolOpts
    ) {
        return opts.itemDataSource(itemref, opts)
            .pipe(
                concatMap(itemData => {

                    // @note determine if the item reference is a renderable one,
                    const isItemTypeInItemTypeUnitsConfiguration = this.isItemTypeInItemTypeUnitsConfiguration(itemData);
                    if (isItemTypeInItemTypeUnitsConfiguration) {
                        return of(itemData);
                    }

                    // @note if its not renderable, it should have a linked child to render and drive business logic
                    return this.select(state =>
                        state.itemData
                            .filter(z => !!z.itemReference.parent)
                            .find(z => isItemReferenceEqual(z.itemReference.parent, itemref))
                    );
                })
            );
    }

    private createStoreItemDataSource$(
        itemref: ItemReference,
        opts: ResolvedItemDataSource
    ): Observable<ResolvedItem> {
        return this.select(state => findItemInCollection(state.itemData, itemref, opts.useDisplayItem));
    }

    isRecipeNameUnique$(
        pk$: Observable<CustomerPK>,
        recipeName: string,
        omitId: string
    ): Observable<boolean> {
        return pk$
            .pipe(
                first(),
                concatMap(pk => this.recipeService.getRecipes(pk)),
                concatMap(recipes => from(recipes)),
                filter(recipe => recipe.id !== omitId),
                filter(recipe => recipe.deleted === false),
                map(recipe => recipe.name.toLowerCase()),
                isTruthy(),
                toArray(),
                map(recipe => recipe.indexOf(recipeName.toLowerCase()) === -1)
            );
    }

    private createRecipeItemReferenceFromId(y: string): ItemReference {
        return ({
            key: y,
            type: 'RECIPE'
        } as ItemReference);
    }

    private loadRecipeData$(recipeItemRef: ItemReference , recipeId?:string): Observable<[ResolvedItem, ResolvedItem[]]> {
        return this.itemDataSvc.resolveItemsByReference([recipeItemRef],recipeId)
            .pipe(
                concatMap(loadedItems => from(loadedItems)),
                first(),
                concatMap(recipeItem => this.loadRecipeIngredients$(recipeItem , recipeId)),
            );
    }

    private loadRecipeIngredients$(
        recipeItem: ResolvedItem , 
        recipeId?:string
    ): Observable<[ResolvedItem, ResolvedItem[]]> {
        return forkJoin([
            of(recipeItem),
            firstValueFrom(this.convertRecipeIngredientsToItemReference$(recipeItem)
                .pipe(
                    concatMap(refs => {
                        return this.itemDataSvc.resolveItemsByReference(refs,recipeId);
                    }),
                )
            )
        ]);
    }

    private refreshRecipePriceById$(recipeId: string): Observable<RecipePrice> {
        return this.recipeService.refreshRecipePriceById(recipeId);
    }

    private convertRecipeIngredientsToItemReference$(resolvedItem: ResolvedItem) {
        return of(resolvedItem)
            .pipe(
                first(),
                concatMap(recipeItem =>
                    of(recipeItem)
                        .pipe(
                            map(item => item.recipeItem.ingredients),
                            first(ingredients => !!ingredients, [] as RecipeIngredient[]),
                            catchError(() => of([] as RecipeIngredient[])),
                        )
                ),
                concatMap(ingredients => from(ingredients)),
                map(ingredient => ({ key: ingredient.itemId, type: ingredient.itemType } as ItemReference)),
                toArray(),
            );
    }

    refreshItemDataByItemReference$(
        itemRef: ItemReference,
    ): Observable<ResolvedItem> {
        return this.itemDataSvc.resolveItemsByReference([itemRef],this.recipeId,'createNewIngredient')
            .pipe(
                concatMap(updatedItemData => from(updatedItemData)),
                tap((updateItemData) => {
                    this.patchState(state => {
                        const idx = state.itemData.findIndex(
                            e =>
                                isItemReferenceEqual(e.itemReference, updateItemData.itemReference)
                        );

                        if (idx === -1) {
                            return {};
                        }

                        const itemData = [...state.itemData];
                        // if(itemData.){}
                        itemData[idx] = updateItemData;
                        return ({ itemData });
                    });
                }));
    }

    private calculateRecipeMargin(recipePrice: { totalCost: number, menuPrice: number }) {
        if (!recipePrice) { return '0'; }
        const { totalCost, menuPrice } = recipePrice;
        return (
            ((totalCost ?? 0) - (menuPrice ?? 0)) * -1
        ).toFixed(2);
    }

    //#region Measurement Units customized
    private getDefaultMeasurementUnitOptions(): reallyCoolOpts {
        return {
            minText: false,
            useDisplayItem: true,
            itemDataSource: this.createStoreItemDataSource$.bind(this),
        } as reallyCoolOpts;
    }

    private getNewItemMeasurementUnitOptions(): reallyCoolOpts {
        return {
            minText: false,
            useDisplayItem: true,
            itemDataSource: (itemReference) => {
                return this.itemDataSvc.getUOMsForItemReference({ itemReference } as ResolvedItem);
            },
        } as reallyCoolOpts;
    }

    // @note creates a hot observable
    getLocalizedMeasurementUnits$(
        itemReference: ItemReference,
        opts?: reallyCoolOpts,
    ): Observable<LocalizedMeasurementUnitOptionGroupx[]> {
        opts = {
            ...this.getDefaultMeasurementUnitOptions(),
            ...opts
        };
        return this.measurementUnitOptSvc.getLocalizedMeasurementUnits$(itemReference, opts);
    }

    createLocalizedMeasurementUnitLookup$(
        itemReference: ItemReference,
        opts: reallyCoolOpts
    ) {

        opts = { ...this.getDefaultMeasurementUnitOptions(), ...opts };
        return this.measurementUnitOptSvc.createLocalizedMeasurementUnitLookup$(
            itemReference,
            opts
        );
    }

    //#endregion

    createVM$(): Observable<ManageRecipeVM> {

        return this.select(
            this.isLoading$,
            this.isReady$,
            this.isSaving$,
            this.isSaveComplete$,
            this.recipe$,
            this.select(({ categories }) => categories),
            this.recipe$.pipe(
                isTruthy(),
                concatMap(g =>
                    this.getLocalizedMeasurementUnits$(
                        { key: g.id, type: 'RECIPE' },
                        { ...this.getNewItemMeasurementUnitOptions(), useDisplayItem: false }
                    )
                )
            ),
            this.recipe$.pipe(
                isTruthy(),
                concatMap(g =>
                    this.createLocalizedMeasurementUnitLookup$(
                        { key: g.id, type: 'RECIPE' },
                        { ...this.getNewItemMeasurementUnitOptions(), useDisplayItem: false }
                    )
                )
            ),
            this.error$,
            (isLoading, isReady, isSaving,
                isSaveComplete, recipe, recipeCategories,
                measurementUnits, measurementUnitsLookup, error) => ({
                    isLoading,
                    isReady,
                    isSaving,
                    isSaveComplete,
                    recipe,
                    recipeCategories,
                    measurementUnits,
                    measurementUnitsLookup,
                    error,
                })
        );
    }

    //#endregion


    //#region MAPPERS/RESOLVERS

    private mapResolvedItemToRecipeState$(
        item: ResolvedItem
    ): Observable<RecipeState> {
        const { recipeItem: recipe } = item;
        return of(recipe)
            .pipe(
                concatMap(resolvedRecipe =>
                    forkJoin([
                        forkJoin([
                            of(resolvedRecipe),
                            of(resolvedRecipe).pipe(
                                map(sourceRecipe => sourceRecipe.ingredients),
                                first(ingredients => !!ingredients, [] as RecipeIngredient[]),
                                catchError(() => of([] as RecipeIngredient[]))
                            ),
                            of(resolvedRecipe).pipe(
                                concatMap(g =>
                                    range(0, recipeFormControlFeelConfig.maxImageCount)
                                        .pipe(
                                            withLatestFrom(wrap$(g.recipeImageSignedUrls ?? [])),
                                            map(([, signedUrls], idx) => signedUrls[idx]),
                                            map((url, ordinal) => {
                                                return {
                                                    ordinal,
                                                    index: ordinal,
                                                    content: url?.resourceURI,
                                                    key: url?.resourceKey,
                                                    entityState: !!url ? EntityState.PERSISTED : EntityState.PLACEHOLDER
                                                } as RecipeImageState;
                                            })
                                        )
                                ),
                                toArray(),
                                catchError(() => of([] as RecipeImageState[]))
                            ),
                        ]),
                        forkJoin([
                            of(resolvedRecipe).pipe(
                                map(source => source.preparationsDetails.cookTime ?? DefaultDurationData),
                                catchError(() => of(DefaultDurationData))
                            ),
                            of(resolvedRecipe).pipe(
                                map(source => source.preparationsDetails.prepTime ?? DefaultDurationData),
                                catchError(() => of(DefaultDurationData))
                            ),
                            of(resolvedRecipe).pipe(
                                map(source => source.preparationsDetails.prepInstructions ?? ''),
                                catchError(() => of(''))
                            ),
                        ]),
                        forkJoin([
                            of(resolvedRecipe).pipe(
                                map(source => source.id ?? null),
                                map(source => source ? EntityState.PERSISTED : EntityState.NEW),
                                catchError(() => of(EntityState.NEW))
                            ),
                            of(resolvedRecipe).pipe(
                                map(source => {
                                    return source.categoryId ?? '';
                                }),
                                catchError(() => of(''))
                            ),
                            of(resolvedRecipe).pipe(
                                map(source => source.name),
                                catchError(() => of(''))
                            ),
                            of(resolvedRecipe).pipe(
                                map(source => source.subType ?? 'menuItem'),
                                map(subType => {
                                    return (({
                                        ['menuItem']: 'MENUITEMRECIPE',
                                        ['batch']: 'BATCHRECIPE',
                                    })[subType]);
                                }
                                ),
                                catchError(() => of(DefaultRecipeType))
                            ),
                        ]),
                        forkJoin([
                            of(resolvedRecipe).pipe(
                                map(source => source.details.batch.totalYieldQty ?? ''),
                                withLatestFrom(this.language$),
                                map(([v, lang]) => caseInsensitiveEquals(lang, 'fr_CA') ? v.replace('.', ',') : v),
                                catchError(() => of(''))
                            ),
                            of(resolvedRecipe).pipe(
                                map(source => source.details.batch.totalYieldUnit ?? ''),
                                catchError(() => of(''))
                            ),
                            this.getMenuPrice$(resolvedRecipe, defaultCalculatorConfiguration.constantValue),
                        ]),
                        forkJoin([
                            this.getFoodCostPercent$(resolvedRecipe),
                            this.getCalculatedRecipeMargin$(resolvedRecipe),
                            this.getTotalFoodCost$(resolvedRecipe),
                            this.getIngredientPrices$(resolvedRecipe)
                                .pipe(
                                    toEntityDictionary(e => `${e.ingredientType}_${e.ingredientId}`)
                                ),
                        ]),
                    ])
                ),
                concatMap(([
                    [sourceRecipe, sourceIngredients, images],
                    [cookTime, prepTime, prepInstructions],
                    [entityState, categoryId, name, recipeType],
                    [totalYieldQty, totalYieldUnit, menuPrice],
                    [foodCostPercent, margin, totalCost, ingredientPrices],
                ]) => {
                    return forkJoin([
                        of(sourceRecipe),
                        of(sourceIngredients),
                        of(ingredientPrices),
                        of({
                            id: sourceRecipe.id,
                            entityState,
                            type: recipeType,
                            name,
                            batchYieldQty: totalYieldQty,
                            batchYieldUnit: totalYieldUnit,
                            recipeBatchPortionCost: calculateBatchPortionCost(recipeType, totalYieldQty, totalCost),
                            menuPrice,
                            categoryId,
                            description: sourceRecipe.description,
                            recipePriceType: defaultCalculatorConfiguration.constantType,
                            recipePriceValue: menuPrice,
                            foodCostPercent,
                            margin,
                            recipeTotalCost: totalCost,
                            cookTimeHours: cookTime.hours,
                            cookTimeMinutes: cookTime.minutes,
                            prepTimeHours: prepTime.hours,
                            prepTimeMinutes: prepTime.minutes,
                            prepInstructions,
                            images,
                            calculationState: CalculationState.CalculationComplete
                        } as RecipeState),
                        this.mapResolvedRecipeIngredientToRecipeIngredientState$(sourceIngredients)
                    ]);
                }),
                concatMap(([, , priceDictionary, recipeState, recipeIngredient]) =>
                    forkJoin([
                        of(recipeState),
                        from(recipeIngredient)
                            .pipe(
                                concatMap(t => updateFormRecipePricingInfo$(t, priceDictionary, this.language$)),
                                toArray()
                            )
                    ])),
                map(([recipeState, ingredientsState]) =>
                    ({ ...recipeState, ingredients: ingredientsState } as RecipeState)),
                first(),
                tap(r => { console.log(r); })
            );
    }

    private getIngredientPrices$(r: Recipe) {
        return of(r).pipe(map(x => x.recipePrice?.ingredientPrices ?? []));
    }

    private getTotalFoodCost$(r: Recipe) {
        return of(r).pipe(map(x => x.recipePrice?.totalCost ?? ''));
    }

    private getCalculatedRecipeMargin$(r: Recipe) {
        return forkJoin([
            of(r).pipe(
                map(source => source.recipePrice.totalCost ?? 0),
                catchError(() => of(0))
            ),
            this.getMenuPrice$(r, defaultCalculatorConfiguration.constantValue)
        ])
            .pipe(
                map(([totalCost, menuPrice]) => this.calculateRecipeMargin({ totalCost, menuPrice })),
                catchError(() => of(''))
            );
    }

    private getMenuPrice$(r: Recipe, defaultValue) {
        return of(r).pipe(
            map(source => {
                return source.details.menu.menuPrice ?? defaultValue;
            }),
            catchError(() => of(defaultValue))
        );
    }

    private getFoodCostPercent$(r: Recipe) {
        return of(r).pipe(
            map(source => source.recipePrice?.foodCostPercent ?? 0),
            catchError(() => of(0)),
            map(n => n.toFixed(2))
        );
    }

    private mapResolvedRecipeIngredientToRecipeIngredientState$(recipeIngredients: RecipeIngredient[]): Observable<RecipeIngredientState[]> {
        return from(recipeIngredients)
            .pipe(
                concatMap(recipeIngredient =>
                    forkJoin([
                        of(recipeIngredient),
                        of(recipeIngredient).pipe(map(e => e.id ? EntityState.PERSISTED : EntityState.NEW)),
                    ])
                ),
                map(([
                    sourceItem,
                    entityState,
                ]) => {
                    return ({
                        entityState,
                        id: sourceItem.id,
                        itemId: sourceItem.itemId,
                        itemType: sourceItem.itemType,
                        unitType: sourceItem.unit,
                        quantity: sourceItem.quantity,
                        ordinal: sourceItem.ordinal, // Not returned from api. Mapped in RecipeService
                        yieldPercent: '30',
                    } as RecipeIngredientState);
                }),
                toArray(),
            );
    }

    mapRecipeIngredientsToPrintableIngredientEntries(
        recipeIngredients: RecipeIngredient[],
        itemData: ResolvedItem[]
    ): Observable<PrintableIngredientEntry[]> {
        return from(recipeIngredients)
            .pipe(
                concatMap(
                    (ingredient) => {
                        const ingredientItemRef = {
                            key: ingredient.itemId,
                            type: ingredient.itemType
                        };
                        let optsBase = this.getDefaultMeasurementUnitOptions();
                        // optsBase.displayItem:true
                        let ingredientItemSource = {
                            ...optsBase,
                            displayItem: false,
                            itemDataSource: createAdHocItemDataSource(itemData),
                        };

                        let displayItemsSource = {
                            ...optsBase,
                            displayItem: true,
                            itemDataSource: createAdHocItemDataSource(itemData),
                        };

                        return forkJoin([
                            of(ingredient),
                            firstValueFrom(getItemDataFn$(ingredientItemRef, ingredientItemSource)),
                            firstValueFrom(this.getItemDataForDisplay$(ingredientItemRef, displayItemsSource))
                        ]);
                    }
                ),
                concatMap(([ingredient, ingredientItem, displayItem]) => forkJoin([
                    of(ingredient),
                    of(ingredientItem),
                    of(displayItem),
                    of(displayItem)
                        .pipe(
                            concatMap(() => firstValueFrom(this.createLocalizedMeasurementUnitLookup$(
                                displayItem.itemReference,
                                {
                                    minText: true,
                                    useDisplayItem: true,
                                    itemDataSource: createAdHocItemDataSource(itemData),
                                })))
                        )
                ])),
                withLatestFrom(this.language$),
                map(([[{ quantity, unit: unitKey }, ingredientItem, displayItem, units], lang]) => ({
                    formattedMeasurement: formatIngredientMeasurement(displayItem, quantity, units, unitKey),
                    ingredientName: this.getIngredientName(ingredientItem, lang),
                    displayItem:displayItem
                } as PrintableIngredientEntry)),
                toArray(),
            )
    }

    createPrintableRecipe$(recipeId: string , batchCostUnit?:string): Observable<PrintableRecipeVM> {
        return of(recipeId)
            .pipe(
                concatMap((id) => {
                    return this.getRecipeData$(id);
                }),
                concatMap(([resolvedItem, itemData]) => {
                    return forkJoin([
                        this.mapResolvedItemToRecipeState$(resolvedItem).pipe(
                            withLatestFrom(this.language$),
                            map(([recipeState, l]) =>
                                this.mapRecipeStateToPrintableRecipeVM(recipeState, l)
                            )
                        ),
                        of(this.getRecipeImageUris(resolvedItem.recipeItem)),
                        this.mapRecipeIngredientsToPrintableIngredientEntries(
                            resolvedItem.recipeItem?.ingredients || [],
                            itemData),
                    ]).pipe(
                        map(([recipe, orderedImageUriList, ingredients]) => ({
                            ...recipe,
                            batchCostUnit,
                            orderedImageUriList,
                            ingredients
                        } as PrintableRecipeVM))
                    );
                }),
            );
    }

    mapRecipeStateToPrintableRecipeVM(
        recipeState: RecipeState,
        language: string
    ): PrintableRecipeVM {
        return ({
            isLoading: false,
            isReady: true,
            id: recipeState.id,
            orderedImageUriList: [],
            ingredients: [],
            prepInstructions: recipeState.prepInstructions,
            recipeName: recipeState.name,
            margin:recipeState.margin,
            menuPrice:recipeState.menuPrice,
            recipeTotalCost:recipeState.recipeTotalCost,
            foodCostPercentage: recipeState.foodCostPercent,
            type:recipeState.type,
            batchYieldQty:recipeState.batchYieldQty,
            batchYieldUnit:recipeState.batchYieldUnit,
            recipeBatchPortionCost:recipeState.recipeBatchPortionCost,
            formattedCookTime: formatDuration(
                {
                    hours: recipeState.cookTimeHours,
                    minutes: recipeState.cookTimeMinutes
                },
                language,
                this.translate
            ),
            formattedPrepTime: formatDuration(
                {
                    hours: recipeState.prepTimeHours,
                    minutes: recipeState.prepTimeMinutes
                },
                language,
                this.translate
            ),
        });
    }

    private getIngredientName(item: ResolvedItem, lang: string): string {
        switch (item.itemReference.type) {
            case 'GENERAL':
                return item.generalItem.description;
            case 'CUSTOM':
                return item.customItem.description;
            case 'RECIPE':
                return item.recipeItem.name;
            case 'GFS':
                return LocalizedValueUtilsService.resolveLocalizedValue(item.gfsItem?.description, lang);
            default:
                throw new Error('Unhandled Item Reference Type...');
        }
    }

    private getRecipeImageUris = (recipe: Recipe): string[] => {
        const urls = recipe.recipeImageSignedUrls;
        return !urls
            ? []
            : urls
                .filter(image => !!image.resourceURI)
                .map(image => image.resourceURI);
    };

    buildPrintableRecipeVMFromRecipeState$(
        recipeState: RecipeState,
    ): Observable<PrintableRecipeVM> {
        const vm$: Observable<PrintableRecipeVM> =
            forkJoin([
                of(recipeState),
                this.language$.pipe(
                    first(),
                ),
                this.appContext.customerPK.pipe(
                    first(),
                ),
            ]).pipe(
                concatMap(([recipeState, language, pk]) => {
                    const recipeIngredients = recipeState.ingredients
                        .map(ingredient => {
                            const recipeIngredient: RecipeIngredient = {
                                id: ingredient.id,
                                customerPK: pk,
                                recipeId: recipeState.id,
                                itemId: ingredient.itemId,
                                itemType: ingredient.itemType,
                                subType: 'subtype',
                                unit: ingredient.unitType,
                                quantity: ingredient.quantity,
                                isDeleted: false,
                                ordinal: ingredient.ordinal,
                                configuredCountingUnits: ingredient.configuredCountingUnits
                            };
                            return recipeIngredient;
                        });

                    const printableRecipeVM = this
                        .mapRecipeStateToPrintableRecipeVM(recipeState, language);

                    const itemRefSource: ItemReference[] = recipeIngredients
                        .map(ingredient => ({
                            key: ingredient.itemId,
                            type: ingredient.itemType
                        }));
                    const itemDatas = this.itemDataSvc.resolveItemsByReference(itemRefSource);
                    return forkJoin([
                        itemDatas.pipe(first())
                    ]).pipe(
                        concatMap(([itemData]) => {
                            return this.mapRecipeIngredientsToPrintableIngredientEntries(
                                recipeIngredients,
                                itemData)
                                .pipe(
                                    map(printableIngredientEntries => {
                                        return { ...printableRecipeVM, ingredients: printableIngredientEntries }
                                    })
                                );
                        })
                    )
                })
            );

        return vm$;
    }
}




function formatIngredientMeasurement(item: ResolvedItem, quantity: string, units: IDictionary<DropDownOption>, unit: string): string {
    return formatPortionUnitQty(
        item,
        quantity
    ) ??
        formatMeasurementUnitQty(
            quantity,
            units[unit],
        );
}

function updateFormRecipePricingInfo$(t: RecipeIngredientState, priceDictionary: IDictionary<RecipeIngredientPrice>, language$: Observable<string>): Observable<RecipeIngredientState> {
    return forkJoin([
        of(t),
        tryResolveIngredientPricingInfo$(priceDictionary, t),
        firstValueFrom(language$)
    ]).pipe(
        map(([ingredientState, pricingInfo, lang]) => {

            if (!!ingredientState.quantity && lang === 'fr_CA') {
                ingredientState.quantity = ingredientState.quantity?.replace('.', ',');
            }

            return ({
                ...ingredientState,
                purchasePrice: pricingInfo.purchasePrice,
                portionPrice: pricingInfo.portionPrice,
                calculationState: pricingInfo.calculationState,
            } as RecipeIngredientState);
        }));
}

type IngredientPriceInfoResult = {
    portionPrice: number,
    purchasePrice: number,
    calculationState: CalculationState
};

function tryResolveIngredientPricingInfo$(
    priceDictionary: { [s: string]: RecipeIngredientPrice; },
    recipeIngredient: RecipeIngredientState
): Observable<IngredientPriceInfoResult> {
    const defaultPricingInfoResult: IngredientPriceInfoResult = {
        portionPrice: 0,
        purchasePrice: 0,
        calculationState: CalculationState.New,
    };

    return of(priceDictionary)
        .pipe(
            map(g => g[`${recipeIngredient.itemType}_${recipeIngredient.itemId}`]),
            map((ingredientPrice) => {
                const ingredientPriceInfoResult = { ...ingredientPrice, calculationState: CalculationState.CalculationComplete } as IngredientPriceInfoResult;
                return ingredientPriceInfoResult ?? defaultPricingInfoResult;
            })
        );
}

//#region primitive extensions
//#endregion

//#region domain extensions
export function createNewRecipeIngredientFn(resolvedItem: ResolvedItem): RecipeIngredient {
    let preselectedUnit = '';

    if (caseInsensitiveEquals(resolvedItem.itemReference.type, 'RECIPE')) {
        if (resolvedItem.recipeItem.itemType === 'BATCHRECIPE') {
            preselectedUnit = resolvedItem.recipeItem.details.batch.totalYieldUnit;
        }
        if (resolvedItem.recipeItem.itemType === 'MENUITEMRECIPE') {
            preselectedUnit = 'menuItem_each';
        }
    }

    return {
        itemId: resolvedItem.itemReference.key,
        itemType: resolvedItem.itemReference.type,
        quantity: '0',
        isDeleted: false,
        unit: preselectedUnit,
        ordinal: 999,
    } as RecipeIngredient;
}
//#endregion

//#region rxjs extensions
//#endregion




function mapRecipeImageStateToImageDataFn(vm: RecipeImageState): ImageData {
    return (vm.content?.startsWith('data:') ?? false
        ? {
            imageDataBase64: vm.content,
        }
        : {
            resourceKey: vm.key,
            resourceURI: vm.content,
        }) as ImageData;
}

function formatPortionUnitQty(
    item: ResolvedItem,
    qty: string,
): string {
    if (!item?.gfsItem?.portionUom?.length) { return null; }
    return `${qty} ${item.gfsItem.portionUom}`;
}

function formatMeasurementUnitQty(
    qty: string,
    unit: DropDownOption
): string {
    if (unit) {
        return `${qty} ${unit.name}`;
    }
    return '';
}

function formatDuration(d: Duration, lang: string, translate: TranslateService) {
    let result = '';

    const hoursComponent = formatDurationComponent(
        d.hours,
        translate.instant('RECIPE.VOCABULARY.HOURS')
    );
    const minutesComponent = formatDurationComponent(
        d.minutes,
        translate.instant('RECIPE.VOCABULARY.MINUTES')
    );

    result += hoursComponent;
    if (result.length > 0) {
        result += ` `;
    }
    result += minutesComponent;
    return result;
}

function formatDurationComponent(value: string, unit: string) {
    let result = '';
    if (value && value.length > 0 && value !== '0') {
        result += `${value} ${unit}`;
    }
    return result;
}
