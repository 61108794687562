import { Component} from '@angular/core';
import { setRecipeInfiniteScroll } from '@gfs/store/recipe/actions/category.actions';
import { AppState } from '@gfs/store/recipe/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-category-header',
  templateUrl: './category-header.component.html',
  styleUrls: ['./category-header.component.scss'],
})
export class CategoryHeaderComponent {
  infiniteScrollState$: Observable<boolean> = this.store.select(state=> state.category.isInfinitescroll)

  constructor(private store: Store<AppState>){}

  toggleInfiniteScrolling(state) {
    this.store.dispatch(new setRecipeInfiniteScroll(state.checked));
  }
}
