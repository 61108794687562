import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  message: string;
  confirmation: string;
  cancellation: string;
}

@Component({
  selector: 'app-recipe-cancel-confirm',
  templateUrl: './recipe-cancel-confirm.component.html',
  styleUrls: ['./recipe-cancel-confirm.component.scss'],
})
export class RecipeCancelConfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<RecipeCancelConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  closeModal() {
    this.dialogRef.close();
  }
}
