import { Inject, Injectable } from '@angular/core';
import {
    AbstractControl,
    UntypedFormArray, UntypedFormControl,
    UntypedFormGroup, ValidationErrors, ValidatorFn, Validators
} from '@angular/forms';
import { DisposableFormGroup } from '@gfs/shared-components';
import {
    CustomerPK,
    IAppContext, RecipeIngredientPriceRequest,
    RecipePrice,
    RecipePriceRequest,
    RecipePriceType
} from '@gfs/shared-models';
import {
    InjectionTokens,
    optionHasValue,
    sanitizeOptionValue
} from '@gfs/shared-services';
import { isTruthy, toEntityDictionary } from '@gfs/shared-services/extensions/rxjs';
import { PriceUtilsService } from '@recipe-ui/services/price-utils.service';
import { formIsTouchedAndDirty, getFormArrayControls } from 'libs/shared-components/src/v2/form/v2.form.module';
import {
    combineLatest, EMPTY, forkJoin, from, iif, Observable,
    of,
    Subject
} from 'rxjs';
import {
    catchError,
    concatMap,
    debounceTime,
    distinctUntilChanged,
    filter, map, mergeMap, startWith, switchMap, takeUntil,
    tap,
    toArray,
    withLatestFrom
} from 'rxjs/operators';
import {
    CalculationState, RecipeImageState, RecipeIngredientState,
    RecipeState
} from './edit.component.store';


export type RecipeEditFormControl = {
    f: DisposableFormGroup;
    destroy$: Observable<void>
    onRecalculateRecipePrice$: Observable<RecipePriceRequest>;
    notifyCalculationInProgress$: Subject<void>;
    notifyCalculationCompleted$: Subject<RecipePrice>;
    tryRemoveIngredientAtIndex$: Subject<number>;
    addNewIngredient$: Subject<RecipeIngredientState>;
};

export const stateFilter = [
    CalculationState.New,
    CalculationState.CalculationComplete,
    CalculationState.CalculationFailed,
    CalculationState.NotReady,
    CalculationState.CalculationPending,
    CalculationState.CalculationInProgress,
];

export const recipeFormControlFeelConfig = {
    generalUIDebounce: 500,
    BeginCalculationDebounceMS: 500,
    maxImageCount: 3

};

export function createRequiredWhenTypeIsBatchRecipeValidator(control: AbstractControl) {
    const sanitizedVal = sanitizeOptionValue(control.value, null);
    if (control.parent) {
        const isRequired = control.parent.value.type === 'BATCHRECIPE';
        if (isRequired && !optionHasValue(sanitizedVal)) { return { required: true }; }
    }
    return null;
}

export function ingredientPortionValidator(): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors | null => {
        const { quantity, unitType } = group.value;

        if (!!unitType && (!quantity)) {
            return { qtyRequired: true };
        }
        if (!unitType && !!quantity && +quantity !== 0) {
            return { unitRequired: true };
        }

        if (quantity === '.'
            || quantity === ','
            || quantity === '/') {
            return { qtyInvalid: true };
        }

        return null;
    };
}

export function createIsUniqueNameValidatorAsync(
    pk: Observable<CustomerPK>,
    checkIsUnique: (pk: Observable<CustomerPK>, recipeName: string, omitId: string) => Observable<boolean>) {
    return (control: AbstractControl):
        Observable<ValidationErrors> | null => {
        return checkIsUnique(pk, control.value, control.parent.value.id)
            .pipe(
                switchMap(isUnique =>
                    iif(
                        () => isUnique,
                        of(null),
                        of({ 'recipeExists': true }),
                    )
                ),
            );
    };
}


// @note item-data-service
@Injectable({
    providedIn: 'root',
})
export class RecipeEditFormControlFactoryService {

    /**
     *
     */
    constructor(
        @Inject(InjectionTokens.IAPP_CONTEXT)
        public appCtx: IAppContext,
    ) { }

    createFormControl(
        recipeState: RecipeState,
        isUniqueRecipeName: (pk: Observable<CustomerPK>, recipeName: string, omitId: string) => Observable<boolean>
    ): RecipeEditFormControl {

        const images = (recipeState.images ?? ([] as RecipeImageState[]));
        const ingredients = (recipeState.ingredients ?? ([] as RecipeIngredientState[]));

        const form = this.createReactiveRecipeFormGroup(isUniqueRecipeName);
        this.addIngdientFormGroupsToForm(ingredients.length, form.f, form.destroy$);
        this.addImageStateToForm(images, form.f);
        return form;
    }

    addImageStateToForm(
        images: RecipeImageState[],
        recipeForm: DisposableFormGroup
    ) {
        recipeForm.patchValue({ calculationState: CalculationState.WritingData });
        const controlsArray = (recipeForm.controls.images as UntypedFormArray);

        images.forEach((image, idx) => {
            controlsArray.insert(idx, new UntypedFormControl(image));
        });

        recipeForm.patchValue({ calculationState: CalculationState.CalculationComplete },
            { emitEvent: false }
        );
    }

    private addIngdientFormGroupsToForm(
        ingredientCount: number,
        rootFormGroup: UntypedFormGroup,
        destroy$: Observable<void>
    ) {
        rootFormGroup.patchValue({ calculationState: CalculationState.WritingData });

        for (let index = 0; index < ingredientCount; index++) {
            (rootFormGroup.controls.ingredients as UntypedFormArray)
                .insert(index, createNewRecipeIngredientFormGroup(destroy$));
        }

        rootFormGroup.patchValue({ calculationState: CalculationState.CalculationComplete }, { emitEvent: false });
    }

    private createReactiveRecipeFormGroup(
        isUniqueRecipeName: (pk: Observable<CustomerPK>, recipeName: string, omitId: string) => Observable<boolean>
    ): RecipeEditFormControl {

        const destroy$ = new Subject<void>();
        const f = this.createDisposableRecipeFormGroup(isUniqueRecipeName, destroy$);
        const notifyCalculationInProgress$ = new Subject<void>();
        const notifyCalculationCompleted$ = new Subject<RecipePrice>();
        const $signalForRecalculation = new Subject<RecipePriceRequest>();
        const removeIngredientAtIndex$ = new Subject<number>();
        const addNewIngredient$ = new Subject<RecipeIngredientState>();

        combineLatest([
            createBehaviorOnRecipeTypeChangeValidateBatchInput$(f),
            createBehaviorRecipeAutoCalculation$(f, notifyCalculationCompleted$),
            createBehaviorIngredientManagement$(f)
        ]).pipe(
            takeUntil(destroy$)
        ).subscribe();


        const formControlResult = {
            f,
            onRecalculateRecipePrice$: this.createNotifierRecalculateRecipePrice$(f, $signalForRecalculation, notifyCalculationCompleted$),
            notifyCalculationCompleted$,
            notifyCalculationInProgress$,
            destroy$: destroy$.asObservable(),
            tryRemoveIngredientAtIndex$: removeIngredientAtIndex$,
            addNewIngredient$
        } as RecipeEditFormControl;

        return formControlResult;

        function createBehaviorOnRecipeTypeChangeValidateBatchInput$(form: UntypedFormGroup) {
            return combineLatest([
                form.valueChanges.pipe(
                    takeUntil(destroy$),
                    map(formValue => formValue.type === 'BATCHRECIPE'),
                    distinctUntilChanged(),
                    tap(() => {
                        form.controls.batchYieldQty.updateValueAndValidity();
                        form.controls.batchYieldUnit.updateValueAndValidity();
                    })
                )
            ]);
        }

        function createBehaviorRecipeAutoCalculation$(
            form: UntypedFormGroup,
            onCalculationComplete$: Observable<RecipePrice>
        ) {
            return combineLatest([

                // @note auto-calc Batch Portion Cost (recipeBatchPortionCost)
                combineLatest([
                    form.statusChanges.pipe(distinctUntilChanged()),
                    form.controls.batchYieldUnit.valueChanges.pipe(
                        startWith(form.value.batchYieldUnit),
                        distinctUntilChanged()
                    ),
                    form.controls.batchYieldQty.valueChanges.pipe(
                        startWith(form.value.batchYieldQty),
                        distinctUntilChanged()
                    ),
                ]).pipe(
                    takeUntil(destroy$),
                    distinctUntilChanged(),
                    tap(([, , batchYieldQty]) => {
                        if (form.dirty) {
                            const { type, recipeTotalCost } = form.value;
                            const recipeBatchPortionCost = calculateBatchPortionCost(type, batchYieldQty, recipeTotalCost);
                            form.patchValue({ recipeBatchPortionCost });
                        }
                    })
                ),

                combineLatest([
                    // @note change calculation constant to food cost percent
                    form.controls.foodCostPercent.valueChanges.pipe(
                        startWith(null),
                        isTruthy(),
                        takeUntil(destroy$),
                        map(v => +v),
                        distinctUntilChanged(),
                        debounceTime(1000),
                        filter(() => formIsTouchedAndDirty(form)),
                        tap((value) => {
                            form.patchValue({
                                recipePriceType: RecipePriceType.FOOD_COST_PERCENT,
                                recipePriceValue: value,
                            });
                            console.log('change constant to food cost percent', value);
                        })
                    ).pipe(startWith(null)),

                    // @note change calculation constant to menu price
                    form.controls.menuPrice.valueChanges.pipe(
                        startWith(null),
                        isTruthy(),
                        takeUntil(destroy$),
                        map(v => +v),
                        distinctUntilChanged(),
                        debounceTime(1000),
                        filter(() => formIsTouchedAndDirty(form)),
                        tap((value) => {
                            form.patchValue({
                                recipePriceType: RecipePriceType.MENU_PRICE,
                                recipePriceValue: value,
                            });
                            console.log('change constant to food menu-price', value);
                        })
                    ).pipe(startWith(null)),

                    // @note change calculation constant to margin
                    form.controls.margin.valueChanges.pipe(
                        startWith(null),
                        isTruthy(),
                        takeUntil(destroy$),
                        map(v => +v),
                        distinctUntilChanged(),
                        debounceTime(1000),
                        filter(() => formIsTouchedAndDirty(form)),
                        tap((value) => {
                            form.patchValue({
                                recipePriceType: RecipePriceType.MARGIN,
                                recipePriceValue: value,
                            });
                            console.log('change constant to margin', value);
                        })
                    ).pipe(startWith(null))
                ]).pipe(
                    // @note effect/gate to control the form going into a pending calculation status
                    filter(() => form.dirty),
                    filter(() =>
                        getValidRecipeIngredientsFromForm(form).length > 0
                    ),
                    tap(() => {
                        form.patchValue({
                            calculationState: CalculationState.CalculationPending
                        });
                    })
                ),

                // @note on calculation state change
                combineLatest([
                    form.controls.calculationState.valueChanges.pipe(distinctUntilChanged()),
                    form.statusChanges.pipe(distinctUntilChanged()),
                ]).pipe(
                    takeUntil(destroy$),
                    filter(([calculationState]) => calculationState === CalculationState.CalculationPending),
                    tap(() => {
                        $signalForRecalculation.next({
                            calculationConstantType: form.value.recipePriceType,
                            calculationConstantValue: form.value.recipePriceValue,
                        } as any);
                    })
                ),

                // @note handle the completion of the calculation
                onCalculationComplete$.pipe(
                    takeUntil(destroy$),
                    concatMap((result) => {

                        const ingredientPriceResult$ = of(result.ingredientPrices).pipe(
                            toEntityDictionary((e) => `${e.ingredientType}_${e.ingredientId}`),
                            concatMap(priceDictionary => forkJoin([of(priceDictionary), of(Object.keys(priceDictionary))])),
                        );
                        const { type, batchYieldQty } = form.value;
                        const ingredientForms = (form.controls.ingredients as UntypedFormArray);
                        const recipeBatchPortionCost = calculateBatchPortionCost(type, batchYieldQty, result.totalCost).toFixed(2);

                        form.markAsUntouched();
                        form.patchValue({
                            calculationState: CalculationState.WritingData,
                        });


                        form.patchValue({
                            menuPrice: Number(result.menuPrice).toFixed(2),
                            foodCostPercent: result.foodCostPercent.toFixed(2),
                            recipeTotalCost: result.totalCost.toFixed(2),
                            recipeBatchPortionCost,
                            margin: result.margin.toFixed(2),
                        });
                        form.markAsUntouched();
                        return from(ingredientForms.controls)
                            .pipe(
                                filter(({ value: { calculationState } }) => calculationState === CalculationState.CalculationInProgress),
                                withLatestFrom(ingredientPriceResult$),
                                concatMap(([ingredientForm, [priceResponse]]) => {
                                    return forkJoin([
                                        of(ingredientForm),
                                        of(priceResponse[getIngredientKey(ingredientForm)])
                                    ]);
                                }),
                                map(([control, price]) => {
                                    if (!!price) {
                                        const { portionPrice, purchasePrice } = price;
                                        control.patchValue({
                                            hasCompletedOneCalculation: true,
                                            portionPrice,
                                            purchasePrice,
                                            calculationState: CalculationState.WritingData
                                        });
                                    } else {
                                        control.patchValue({
                                            hasCompletedOneCalculation: true,
                                            portionPrice: null,
                                            purchasePrice: null,
                                            calculationState: CalculationState.WritingData
                                        });
                                        control.setErrors({ ...control.errors, unableToComplete: true });
                                    }
                                }),
                                catchError(err => {
                                    console.error('recipe calculation failed to write response data', err);
                                    return EMPTY;
                                }),
                                isTruthy(),
                                toArray()
                            );
                    }),
                    tap(() => {
                        const ingredientsForms = (form.controls.ingredients as UntypedFormArray).controls;

                        ingredientsForms
                            .filter(e => e.value.calculationState === CalculationState.WritingData)
                            .forEach(element => {
                                element.patchValue({ calculationState: CalculationState.CalculationComplete },
                                    { emitEvent: false }
                                );
                            });
                        const allCompleted = ingredientsForms.map(r =>
                            r.value.calculationState === CalculationState.CalculationComplete
                        ).indexOf(false) === -1;

                        if (allCompleted) {
                            form.patchValue({ calculationState: CalculationState.CalculationComplete },
                                { emitEvent: false }
                            );
                            console.log(`calculation completed`);
                        }

                    }),
                )
            ]);
        }

        function createBehaviorIngredientManagement$(
            form: UntypedFormGroup,
        ) {
            return combineLatest([
                // @note add new ingredient
                addNewIngredient$.pipe(
                    filter((ingredientState) =>
                        isNotDuplicateIngredient(form, ingredientState)
                        && isNotThisIngredient(form, ingredientState)
                    ),
                    map((ingredientState) =>
                        addNewIngredientToFormState$(ingredientState, form)
                    ),
                ),
                // @note remove ingredient at index
                removeIngredientAtIndex$
                    .pipe(
                        tap((removeIdx) => {
                            const ingredients = (form.controls.ingredients as UntypedFormArray);
                            ingredients.removeAt(removeIdx);
                            const newCalculationState = ingredients.controls.length > 0
                                ? CalculationState.CalculationPending
                                : CalculationState.CalculationComplete;
                            form.markAsDirty();
                            form.patchValue({ calculationState: newCalculationState });
                            form.updateValueAndValidity();
                        })
                    ),
            ]);
        }

        function addNewIngredientToFormState$(
            ingredientState: RecipeIngredientState,
            recipeFormGroup: UntypedFormGroup,
        ): void {
            recipeFormGroup.patchValue({ calculationState: CalculationState.WritingData });

            const newIngredientFormGroup = createNewRecipeIngredientFormGroup(destroy$);
            ingredientState.calculationState = CalculationState.WritingData;
            newIngredientFormGroup.reset(ingredientState);

            (recipeFormGroup.controls.ingredients as UntypedFormArray)
                .insert((recipeFormGroup.controls.ingredients as UntypedFormArray).length, newIngredientFormGroup);

            newIngredientFormGroup.patchValue({ calculationState: CalculationState.CalculationComplete });


            recipeFormGroup.patchValue({ calculationState: CalculationState.CalculationComplete });
            recipeFormGroup.markAsDirty();
            recipeFormGroup.updateValueAndValidity();

        }

    }

    private createDisposableRecipeFormGroup(isUniqueRecipeName: (pk: Observable<CustomerPK>, recipeName: string, omitId: string) => Observable<boolean>, destroy$: Subject<void>) {
        const result = new UntypedFormGroup({
            id: new UntypedFormControl(null, {}),
            name: new UntypedFormControl('', {
                asyncValidators: [
                    createIsUniqueNameValidatorAsync(this.appCtx.customerPK, isUniqueRecipeName)
                ],
                validators: [Validators.required],
            }),
            type: new UntypedFormControl('', {
                asyncValidators: [],
                validators: [Validators.required],
            }),
            categoryId: new UntypedFormControl('', {
                asyncValidators: [],
                validators: [],
            }),
            menuPrice: new UntypedFormControl('', {
                asyncValidators: [],
                validators: [],
                updateOn: 'blur'
            }),
            margin: new UntypedFormControl('0', {
                asyncValidators: [],
                validators: [],
                updateOn: 'blur'
            }),
            foodCostPercent: new UntypedFormControl('', {
                asyncValidators: [],
                validators: [],
                updateOn: 'blur'
            }),
            recipePriceType: new UntypedFormControl(RecipePriceType.DEFAULT, {
                asyncValidators: [],
                validators: [],
            }),
            recipePriceValue: new UntypedFormControl('0', {
                asyncValidators: [],
                validators: [],
            }),
            recipeTotalCost: new UntypedFormControl('', {
                asyncValidators: [],
                validators: [],
            }),
            recipeBatchPortionCost: new UntypedFormControl('', {
                asyncValidators: [],
                validators: [],
            }),
            batchYieldQty: new UntypedFormControl('', {
                validators: [
                    createRequiredWhenTypeIsBatchRecipeValidator
                ],
                updateOn: 'blur'
            }),
            batchYieldUnit: new UntypedFormControl('', {
                validators: [
                    createRequiredWhenTypeIsBatchRecipeValidator
                ],
            }),
            prepTimeHours: new UntypedFormControl(null, {
                asyncValidators: [],
                validators: [Validators.min(0)],
            }),
            prepTimeMinutes: new UntypedFormControl(null, {
                asyncValidators: [],
                validators: [Validators.max(59), Validators.min(0)],
            }),
            cookTimeHours: new UntypedFormControl(null, {
                asyncValidators: [],
                validators: [Validators.min(0)],
            }),
            cookTimeMinutes: new UntypedFormControl(null, {
                asyncValidators: [],
                validators: [Validators.max(59), Validators.min(0)],
            }),
            prepInstructions: new UntypedFormControl(null, {
                asyncValidators: [],
                validators: [],
            }),
            images: new UntypedFormArray([]),
            ingredients: new UntypedFormArray([]),
            calculationState: new UntypedFormControl(CalculationState.New, {
                asyncValidators: [],
                validators: [],
            }),
        }) as DisposableFormGroup;
        result.dispose = destroy$.next.bind(destroy$);
        return result;
    }

    private createNotifierRecalculateRecipePrice$(
        form: UntypedFormGroup,
        $signalForRecalculation: Subject<RecipePriceRequest>,
        notifyCalculationCompleted$: Subject<RecipePrice>
    ) {
        return $signalForRecalculation
            .pipe(
                isTruthy(),
                mergeMap((priceRequest) => {
                    const validIngredients = getValidRecipeIngredientsFromForm(form);
                    console.log('valid Ingredients', validIngredients);
                    return iif(
                        () => validIngredients.length > 0,
                        of(validIngredients).pipe(
                            map(() => {
                                priceRequest.ingredients = getValidRecipeIngredientsFromForm(form);
                                (form.controls.ingredients as UntypedFormArray).controls.forEach(element => {
                                    if (element.valid && element.value.calculationState === CalculationState.CalculationPending) {
                                        element.patchValue({
                                            calculationState: CalculationState.CalculationInProgress,
                                        });
                                    }
                                });
                                form.patchValue({ calculationState: CalculationState.CalculationInProgress });
                                return priceRequest;
                            })
                        ),
                        of('EMPTY').pipe(tap(() => {
                            notifyCalculationCompleted$.next({
                                totalCost: 0,
                                foodCostPercent: 0,
                                menuPrice: form.value.menuPrice,
                                margin: 0,
                                ingredientPrices: [],
                            } as RecipePrice);
                        }))

                    );
                })
            );
    }



}
function getValidRecipeIngredientsFromForm(y: UntypedFormGroup) {
    return getFormArrayControls(y.controls.ingredients)
        .filter(k => k.valid && k.value.quantity && k.value.unitType)
        .map(k => k.value as RecipeIngredientState)
        .map(k => ({
            ingredientId: k.itemId,
            ingredientType: k.itemType,
            quantity: PriceUtilsService.staticConvertFractionToDecimal(k.quantity?.replace(',', '.')),
            unit: k.unitType,
            yieldPercent: k.yieldPercent
        } as RecipeIngredientPriceRequest));
}


function createNewRecipeIngredientFormGroup(destroy$: Observable<void>) {
    const f = createRecipeIngredientFormGroup();
    combineLatest([
        createBehaviorPortionPriceRecalculation(f)
    ]).pipe(
        takeUntil(destroy$)
    ).subscribe();
    return f;


    function createBehaviorPortionPriceRecalculation(ingredientForm: UntypedFormGroup) {
        return combineLatest([
            ingredientForm.controls.unitType.valueChanges
                .pipe(
                    startWith(ingredientForm.value.unitType),
                    distinctUntilChanged()
                ),
            ingredientForm.controls.quantity.valueChanges
                .pipe(
                    startWith(ingredientForm.value.quantity),
                    distinctUntilChanged()
                ),
        ]).pipe(
            filter(() => ingredientForm.dirty
            ),
            tap(([unitType, quantity]) => {
                ingredientForm.parent.parent.patchValue({
                    recipePriceType: RecipePriceType.MENU_PRICE,
                    recipePriceValue:  ingredientForm.parent.parent.value.menuPrice,
                } as any);
                tryBeginIngredientCalculation(unitType, quantity, ingredientForm);
                tryBeginRecipeCalculation(ingredientForm);
            }),
        );
    }

    function tryBeginIngredientCalculation(unitType: any, quantity: any, ingredientForm: UntypedFormGroup) {
        const canCalc = optionHasValue(unitType) && optionHasValue(quantity);
        const newstatus = canCalc ? CalculationState.CalculationPending : CalculationState.NotReady;
        ingredientForm.controls.calculationState.setValue(newstatus);
    }

    function tryBeginRecipeCalculation(form: UntypedFormGroup) {
        if (form.value.calculationState === CalculationState.CalculationPending) {
            form.parent.parent.patchValue({
                calculationState: CalculationState.CalculationPending
            } as any);
        }
    }
}

export function createRecipeIngredientFormGroup() {
    return new UntypedFormGroup({
        id: new UntypedFormControl('', {
            asyncValidators: [],
            validators: [],
        }),
        itemId: new UntypedFormControl('', {
            asyncValidators: [],
            validators: [Validators.required],
        }),
        itemType: new UntypedFormControl('', {
            asyncValidators: [],
            validators: [Validators.required],
        }),
        unitType: new UntypedFormControl('', {
            asyncValidators: [],
            validators: [],
            updateOn: 'blur'
        }),
        quantity: new UntypedFormControl('', {
            asyncValidators: [],
            validators: [
                Validators.min(0)
            ],
        }),
        entityState: new UntypedFormControl('', {
            asyncValidators: [],
            validators: [],
        }),
        yieldPercent: new UntypedFormControl('30', {
            asyncValidators: [],
            validators: [Validators.required],
        }),
        purchasePrice: new UntypedFormControl(null, {
            asyncValidators: [],
            validators: [],
        }),
        portionPrice: new UntypedFormControl('', {
            asyncValidators: [],
            validators: [],
        }),
        ordinal: new UntypedFormControl(-1, {
            asyncValidators: [],
            validators: [],
        }),
        calculationState: new UntypedFormControl(CalculationState.New, {
            asyncValidators: [],
            validators: [],
        }),
        itemRef: new UntypedFormControl(null),
        hasCompletedOneCalculation: new UntypedFormControl(false),
    },
        {
            validators: [
                ingredientPortionValidator()
            ],
        }
    );
}

function getIngredientKey(ingredientForm: AbstractControl) {
    const form = ingredientForm.value as RecipeIngredientState;
    return `${form.itemType}_${form.itemId}`;
}

export function calculateBatchPortionCost(recipeType, batchYieldQty: string, recipeTotalCost) {
    batchYieldQty = (batchYieldQty ?? '0').replace(',', '.');
    const batchYield = PriceUtilsService.staticConvertFractionToDecimal(batchYieldQty);

    if (recipeType !== 'BATCHRECIPE' || batchYield === 0) {
        return 0;
    }

    const totalCost = parseFloat(recipeTotalCost ?? 0); // convert fraction to number

    const r = totalCost / batchYield;

    return isNaN(r) ? null : r;
}

function isNotDuplicateIngredient(form: UntypedFormGroup, ingredientState: RecipeIngredientState) {
    return !(form.controls.ingredients as UntypedFormArray).controls.some(h => h.value.itemId === ingredientState.itemId);
}
function isNotThisIngredient(form: UntypedFormGroup, ingredientState: RecipeIngredientState) {
    return form.value.id !== ingredientState.itemId;
}
