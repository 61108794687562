import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Constants, InventoryConstants } from '@gfs/constants';
import { CustomerUnitSelectionComponent, GeneralLedgerRoutes, ManageCustomItemsComponent, ManageCustomItemsRoutes, MobileHamburgerMenuComponent } from '@gfs/shared-components';
import { InjectionTokens, featureAuthorizationGuard, setFeatureAuthorizationResolver, unitSelectionGuardFn } from '@gfs/shared-services';
import { PreparingAppComponent } from '@inventory-ui/v2/common/preparing-app/preparing-app.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { CategoryComponent } from './category/category/category.component';
import { ErrorComponent } from './error/error/error.component';
import { AuthResolver } from '../../../../libs/shared-services/src/lib/resolvers/auth-resolver';
import { EditRecipeV2Component } from './v2/edit/edit.component';
import { authResolverFn } from '../../../../libs/shared-services/src/lib/resolvers/auth-resolver.fn';
import { getRecipeResolverFn } from './v2/resolvers/preloaders/get-recipe.resolver.fn';
import { saveInProgressFn } from '@inventory-ui/v2/common/guards/save-in-progress.guard.fn';
import { externalUriResolverFn } from 'apps/inventory-ui/src/app/v2/resolvers/external-uri.resolver.fn';
import { Authorization } from '@gfs/store/common';
import { unitSelectionSingleUnitRecipeRedirectGuardFn } from '../../../../libs/shared-services/src/lib/guards/recipe-unit-selection-single-unit-redirect.guard.fn'
import { AuthGuard, CallbackComponent } from '@gfs/shared-services/auth';
import { ApplicationUserRole } from '@gfs/shared-models';
import { CustomerUnitSearchComponent } from 'libs/shared-components/src/lib/recipe-profit-calculator/customer-unit-search/customer-unit-search.component';
import { UserContextFactoryService } from './services/architect/recipe-user-context-factory.service';
import { EditComponentWrapperComponent } from 'libs/shared-components/src/lib/recipe-profit-calculator';
const v2Routes: Routes = [{
  path: 'recipe',
  component: EditRecipeV2Component,
  canActivate: [AuthGuard, unitSelectionGuardFn],
  runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  resolve: {
    initialState: getRecipeResolverFn
  },
  canDeactivate: [saveInProgressFn],
},
{
  path: 'recipe/:id',
  component: EditRecipeV2Component,
  runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  resolve: {
    initialState: getRecipeResolverFn
  },
  canActivate: [AuthGuard, unitSelectionGuardFn],
  canDeactivate: [saveInProgressFn],
}];

const routes: Routes = [
  {
    path: '',
    redirectTo: Constants.SharedFeaturePaths.CUSTOMER_SELECTION_PATH,
    pathMatch: 'full',
  },
  {
    path: 'implicit/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'redirect/:reference',
    component: PreparingAppComponent,
    runGuardsAndResolvers: 'always',
    resolve: [externalUriResolverFn]
  },
  {
    path: 'category',
    component: CategoryComponent,
    resolve: {
      auth: authResolverFn
    },
    canActivate: [
      AuthGuard,
      unitSelectionGuardFn,
    ]
  },
  {
    path: 'mobile-hamburger-menu',
    canActivate: [OktaAuthGuard],
    component: MobileHamburgerMenuComponent,
  },
  {
    path: Constants.SharedFeaturePaths.CUSTOMER_SELECTION_PATH,
    component: CustomerUnitSelectionComponent,
    data: {
      entitlementFilter: InventoryConstants.RECIPE_OKTA_ROLES,
      [Authorization.RecipeProfitCalculator]: false
    },
    resolve: {
      auth: authResolverFn
    },
    canActivate: [
      OktaAuthGuard,
      unitSelectionSingleUnitRecipeRedirectGuardFn
    ]
  },
  {
    path: Constants.RecipeFeaturePaths.RECIPE_PROFIT_CALCULATOR.callback,
    component: CallbackComponent
  },
  {
    path: Constants.SharedFeaturePaths.MANAGE_NONGFS_ITEMS_PATH,
    canActivate: [AuthGuard, unitSelectionGuardFn],
    component: ManageCustomItemsComponent,
    data: {
      setAuthorizations: undefined,
      requiredAuthorizations: {
          [Authorization.RecipeProfitCalculator]: true
      }
    }
},
  {
    path: Constants.RecipeFeaturePaths
        .RECIPE_PROFIT_CALCULATOR[ApplicationUserRole.Employee]
        .RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATH,
    component: CustomerUnitSearchComponent,
    data: {
        entitlementFilter: InventoryConstants.RECIPE_CALCULATOR_OKTA_ROLES,
        requiredAuthorizations: undefined,
        setAuthorizations: {
            [Authorization.RecipeProfitCalculator]: true
        }
    },
    resolve: {
        setFeatureAuthorizationResolver
    },
    canActivate: [
        AuthGuard,
    ],
    providers: []
  },
  {
  path: Constants.RecipeFeaturePaths
      .RECIPE_PROFIT_CALCULATOR[ApplicationUserRole.Employee]
      .RECIPE_PROFIT_CALCULATOR_PATH,
  component: EditComponentWrapperComponent,
  canActivate: [
      unitSelectionGuardFn,
      featureAuthorizationGuard,
      AuthGuard, 
  ],
  runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  resolve: {
      initialState: getRecipeResolverFn
  },
  data: {
    setAuthorizations: undefined,
    requiredAuthorizations: {
        [Authorization.RecipeProfitCalculator]: true
    }
},
  providers: [
      {
          provide: InjectionTokens.IAPP_CONTEXT,
          useFactory: UserContextFactoryService.factory,
          deps: [UserContextFactoryService],
      }
  ]
  },
  ...v2Routes,
  ...GeneralLedgerRoutes,
  { path: 'error', component: ErrorComponent },
  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: [
    AuthResolver
  ]
})
export class AppRoutingModule { }
