import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MaterialModule } from '../material.module';
import { CategoryComponent } from './category/category.component';
import { CategoryCreateModalComponent } from './category-create-modal/category-create-modal.component';
import { CategoryDeleteModalComponent } from './category-delete-modal/category-delete-modal.component';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryRecipeCardComponent } from './category-recipe-card/category-recipe-card.component';
import { CategoryRecipePanelComponent } from './category-recipe-panel/category-recipe-panel.component';
import { RecipeConstants } from '@gfs/constants';
import { RecipeDeleteModalComponent } from './recipe-delete-modal/recipe-delete-modal.component';
import { RecipeIconService } from '../services/images/recipe-icon.service';
import { CategorySelectComponent } from './category-select/category-select.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CategoryRecipeListComponent } from './category-recipe-list/category-recipe-list.component';
import { CategoryRecipeCardTripleDotMenuComponent } from './category-recipe-card-triple-dot-menu/category-recipe-card-triple-dot-menu.component';
import { CategoryRecipeCardMobileComponent } from './category-recipe-card-mobile/category-recipe-card-mobile.component';
import { CategoryRecipeCardPricingInformationComponent } from './category-recipe-card-pricing-information/category-recipe-card-pricing-information.component';
import { CategoryHeaderComponent } from './category-header/category-header.component';
import { CategoryRecipeSearchInputComponent } from './category-recipe-search-input/category-recipe-search-input.component';
import { PipeSharingModule } from '@recipe-ui/pipes/pipe-sharing-module/pipe-sharing.module';
import { SharedDirectivesModule } from '@gfs/directives';

const { icons } = RecipeConstants;

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatCardModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatListModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    PipeSharingModule,
    TranslateModule.forChild(),
    SharedDirectivesModule
  ],
  declarations: [
    CategoryComponent,
    CategoryListComponent,
    CategoryRecipeCardComponent,
    CategoryRecipePanelComponent,
    CategoryCreateModalComponent,
    CategoryDeleteModalComponent,
    RecipeDeleteModalComponent,
    CategorySelectComponent,
    CategoryRecipeListComponent,
    CategorySelectComponent,
    CategoryRecipeCardTripleDotMenuComponent,
    CategoryRecipeCardMobileComponent,
    CategoryRecipeCardPricingInformationComponent,
    CategoryHeaderComponent,
    CategoryRecipeSearchInputComponent
  ],
  exports: [CategoryComponent]
})

export class CategoryModule {
  readonly categoryIcons = [
    icons.edit_icon,
    icons.plus_icon,
    icons.plus_icon_gray,
    icons.trash_icon,
    icons.triple_dot_menu,
    icons.triple_dot_menu_white,
    icons.import_guide_document,
    icons.search_icon,
    icons.search_gray_background,
    icons.arrow,
    icons.arrow_up_left,
    icons.refresh_icon,
    icons.binoculars_icon,
    icons.menu_item,
    icons.batch_recipe,
    icons.info_icon,
    icons.bread,
    icons.cake,
    icons.dough,
    icons.meat,
    icons.sauce,
    icons.soup,

  ];

  constructor(public recipeIconService: RecipeIconService) {
    recipeIconService.registerIcons(this.categoryIcons);
  }
}
