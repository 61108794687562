import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddItemDialogComponent } from '@gfs/shared-components';
import { CustomerPK, Recipe, RecipeCategory } from '@gfs/shared-models';
import { CreateCategoryAttempt, DeleteCategoryAttempt, GetCategoriesAttempt, PatchCategoryAttempt } from '@gfs/store/recipe/actions/category.actions';
import { DeleteRecipeAttempt, GetRecipesAttempt } from '@gfs/store/recipe/actions/recipe.actions';
import { AppState, selectAllCategories, selectAllRecipes } from '@gfs/store/recipe/reducers';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { CategoryCreateModalComponent } from '../category-create-modal/category-create-modal.component';
import { CategoryDeleteModalComponent } from '../category-delete-modal/category-delete-modal.component';
import { RecipeDeleteModalComponent } from '../recipe-delete-modal/recipe-delete-modal.component';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  isMobile$: Observable<boolean> = this.store.select(state => state.layout.isMobile);
  categories$: Observable<RecipeCategory[]> = this.store.pipe(select(selectAllCategories));
  recipes$: Observable<Recipe[]> = this.store.pipe(select(selectAllRecipes));
  customerPK$: Observable<CustomerPK> = this.store.select(state => state.auth.pk);

  constructor(
    public router: Router,
    private dialog: MatDialog,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.getCategories();
    this.getRecipes();


    const scrollContainer = document.getElementsByClassName('content')[0];
    scrollContainer.scrollTop = 0;
  }

  getCategories(): void {
    this.store.dispatch(new GetCategoriesAttempt());
  }

  getRecipes(): void {
    this.store.dispatch(new GetRecipesAttempt());
  }

  editCategoryHandler(category?: RecipeCategory): void {
    this.categories$.pipe(first()).subscribe(cats => {
      const dialogRef = this.dialog.open(CategoryCreateModalComponent, {
        data: {
          categories: cats,
          categoryName: category ? category.name : ''
        },
        panelClass: 'recipe-create-category-dialog'
      });

      dialogRef.afterClosed()
        .subscribe((categoryName: string) => {
          if (categoryName) {
            if (category) {
              this.store.dispatch(new PatchCategoryAttempt({
                ...category,
                name: categoryName
              }));
            } else {
              this.store.dispatch(new CreateCategoryAttempt(categoryName));
            }
          }
        });
    });
  }

  deleteCategoryHandler(category: RecipeCategory): void {
    this.categories$.pipe(first()).subscribe(cats => {
      const dialogRef = this.dialog.open(CategoryDeleteModalComponent, {
        data: {
          categories: cats,
          categoryName: category ? category.name : ''
        },
        disableClose: true,
        panelClass: 'recipe-delete-category-dialog'
      });

      dialogRef.afterClosed()
        .subscribe(categoryName => {
          if (categoryName) {
            this.store.dispatch(new DeleteCategoryAttempt(category));
          }
        });
    });
  }

  deleteRecipeHandler(recipe: Recipe): void {
    const dialogRef = this.dialog.open(RecipeDeleteModalComponent, {
      data: {
        recipeName: recipe ? recipe.name : ''
      },
      panelClass: 'recipe-delete-recipe-dialog'
    });

    dialogRef.afterClosed()
      .subscribe(recipeName => {
        if (recipeName) {
          this.store.dispatch(new DeleteRecipeAttempt(recipe));
        }
      });
  }

  onAddItemClick(): void {
    this.dialog.open(AddItemDialogComponent, {
      data: {
        selectRecipeIngredientMode: true,
      },
      panelClass: 'inventory-add-item-dialog',
    });
  }
}
