import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  categoryName: string;
}

@Component({
  selector: 'app-category-delete-modal',
  templateUrl: './category-delete-modal.component.html',
  styleUrls: ['./category-delete-modal.component.scss']
})
export class CategoryDeleteModalComponent implements OnInit {
  categoryName: string;
  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CategoryDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
    this.categoryName = (this.data && this.data.categoryName) ? this.data.categoryName : '';
    this.form = this.formBuilder.group({
      categoryName: this.data ? this.data.categoryName : ''
    });
  }

  closeModal() {
    this.form.value.categoryName = '';
    this.dialogRef.close();
  }

  submit(form) {
    this.dialogRef.close(form.value.categoryName);
  }

}
