import { Component, ChangeDetectionStrategy, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { CategoryUtilsService } from '@recipe-ui/services/category-utils.service';
import { select, Store } from '@ngrx/store';
import { AppState, selectAllCategories, selectAllRecipes } from '@gfs/store/recipe/reducers';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { CategoryOrder, Recipe, RecipeCategory } from '@gfs/shared-models';
import { GetCategoriesOrderAttempt, PutCategoriesOrderAttempt } from '@gfs/store/recipe/actions/category.actions';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CategoryListComponent implements OnInit, OnDestroy {

  @Output() editCategoryClicked: EventEmitter<RecipeCategory> = new EventEmitter<RecipeCategory>();
  @Output() deleteCategoryClicked: EventEmitter<RecipeCategory> = new EventEmitter<RecipeCategory>();

  categories$: Observable<RecipeCategory[]> = this.store.pipe(select(state => selectAllCategories(state)));
  recipes$: Observable<Recipe[]> = this.store.pipe(
    select(state => 
      selectAllRecipes(state)
    ),
    map(
      (recipe)=>recipe.filter((recipeUndeleted)=> !recipeUndeleted.deleted)
    )
  );
  categoriesOrder$: Observable<CategoryOrder> = this.store.select(state => {
    return state.category.categoryOrder;
  });

  componentSubscriptions: Subscription[] = [];
  orderedCategories: RecipeCategory[] = [];

  constructor(
    private categoryUtils: CategoryUtilsService,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.store.dispatch(new GetCategoriesOrderAttempt());

    combineLatest([this.categories$, this.categoriesOrder$])
      .pipe(
        map(([categories, categoryOrder]) => {
          return categories.map((category: RecipeCategory) => {
            category.ordinal = categoryOrder?.order.indexOf(category.id) ?? 1;

            return category;
          });
        })
      ).subscribe((categories: RecipeCategory[]) => {
        this.orderedCategories = categories;
      });
  }

  /**
   * cleanup subscriptions
   */
  ngOnDestroy() {
    this.componentSubscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  /**
   * construct an array of categories in the order specified by the
   * order param
   *
   * @param order a CategoryOrder that specifies the order for categories
   * @param categories array of RecipeCategory objects
   */
  orderCategories(order: CategoryOrder, categories: RecipeCategory[]) {
    const orderedCategories: RecipeCategory[] = [];

    order.order.forEach(categoryId => {
      const matchedCategory = categories.find(category => category.id === categoryId);

      orderedCategories.push(matchedCategory);
    });

    this.orderedCategories = orderedCategories;
  }

  /**
   * handle category list item dropped and reorder the category list
   *
   * @param event a [CdkDragDrop](https://material.angular.io/cdk/drag-drop/api#CdkDragDrop) event
   */
  drop(event: CdkDragDrop<string[]>) {
    const originIndex = event.previousIndex;
    const destinationIndex = event.currentIndex;

    const categoriesSubscription = this.categories$.pipe(take(1)).subscribe((categories: RecipeCategory[]) => {

      const orderedCategories = [...categories].sort((x, y) => x.ordinal - y.ordinal);

      if (originIndex !== destinationIndex) {

        const draggedCategory = orderedCategories.splice(originIndex, 1);
        orderedCategories.splice(destinationIndex, 0, draggedCategory[0]);

        const categoriesOrderSubscription = this.categoriesOrder$.pipe(take(1)).subscribe((categoriesOrder: CategoryOrder) => {
          if (categoriesOrder) {
            categoriesOrder.order = orderedCategories.map((category: RecipeCategory) => category.id);

            this.store.dispatch(new PutCategoriesOrderAttempt(categoriesOrder));
          }
        });

        this.componentSubscriptions.push(categoriesOrderSubscription);
      }
    });

    this.componentSubscriptions.push(categoriesSubscription);
  }

  /**
   * handle mouse click event and ENTER key pseudo event, expand
   * category panel and scroll into view
   *
   * @param category a single RecipeCategory
   */
  categoryClicked(category: RecipeCategory | any) {
    const id = 'category-recipe-panel-' + category.name.replace(' ', '-') + '-' + category.id;
    const el = document.getElementById(id);

    category.expandStatus = true;

    if (el) {
      el.scrollIntoView(true);
    }

    const container = document.getElementById('category-main-container');

    if (container) {
      window.scrollBy(0, -container.offsetTop); // Adjust scrolling with a negative value here
    }
  }

  /**
   * hide edit and delete buttons on new category item focus
   */
  clearFocus() {
    const elements = document.getElementsByClassName('icon-hover-bkg');

    Array.from(elements).forEach((element: HTMLElement) => {
      element.style.visibility = 'hidden';
    });
  }

  /**
   * show category edit and delete buttons on mouseover/focus events
   *
   * @param categoryId a single RecipeCategory Id
   * @param categoryName  a single RecipeCategory name
   */
  onFocus(categoryId: string, categoryName: string) {
    this.clearFocus();

    document.getElementById('category-list-delete-button-'
      + categoryName.replace(' ', '-') + '-' + categoryId).style.visibility = 'visible';
    document.getElementById('category-list-edit-button-'
      + categoryName.replace(' ', '-') + '-' + categoryId).style.visibility = 'visible';
  }

  /**
   * hide category edit and delete buttons on mouseout event
   *
   * @param categoryId a single RecipeCategory Id
   * @param categoryName  a single RecipeCategory name
   */
  onBlur(categoryId: string, categoryName: string) {
    document.getElementById('category-list-edit-button-' + categoryName.replace(' ', '-') + '-' + categoryId).style.visibility = 'hidden';
    document.getElementById('category-list-delete-button-' + categoryName.replace(' ', '-') + '-' + categoryId).style.visibility = 'hidden';
  }

  /**
   * handle right arrow keyboard event, move keyboard focus
   * among a category list item, edit button, and delete button
   *
   * @param categoryId a single RecipeCategory Id
   * @param categoryName a single RecipeCategory name
   */
  onRightArrow(categoryId: string, categoryName: string) {
    if (document.activeElement.id.startsWith('category-list-edit')) {
      this.setItemFocus('category-list-delete-button-' + categoryName.replace(' ', '-') + '-' + categoryId);
    } else if (document.activeElement.id.startsWith('category-list-delete')) {
      this.setItemFocus('category-list-item-' + categoryName.replace(' ', '-') + '-' + categoryId);
    } else {
      this.setItemFocus('category-list-edit-button-' + categoryName.replace(' ', '-') + '-' + categoryId);
    }
  }

  /**
   * handle left arrow keyboard event, move keyboard focus
   * among a category list item, edit button, and delete button
   *
   * @param categoryId a single RecipeCategory Id
   * @param categoryName a single RecipeCategory name
   */
  onLeftArrow(categoryId: string, categoryName: string) {
    if (document.activeElement.id.startsWith('category-list-edit')) {
      this.setItemFocus('category-list-item-' + categoryName.replace(' ', '-') + '-' + categoryId);
    } else if (document.activeElement.id.startsWith('category-list-delete')) {
      this.setItemFocus('category-list-edit-button-' + categoryName.replace(' ', '-') + '-' + categoryId);
    } else {
      this.setItemFocus('category-list-delete-button-' + categoryName.replace(' ', '-') + '-' + categoryId);
    }
  }

  /**
   * focus a dom node
   *
   * @param id dom node id
   */
  setItemFocus(id: string) {
    document.getElementById(id).focus();
  }

  /**
   * determine if a category has recipes
   *
   * @param categoryId a single RecipeCategory Id
   * @returns Observable<boolean>
   */
  hasRecipes(categoryId: string): Observable<boolean> {
    return this.recipes$.pipe(
      first(),
      map(recipes => this.categoryUtils.hasRecipes(categoryId, recipes))
    );
  }

  /**
   * get all recipes that belong to a RecipeCategory
   *
   * @param categoryId a single RecipeCategory Id
   * @returns Observable<Recipe[]>
   */
  getRecipes(categoryId: string): Observable<Recipe[]> {
    return this.recipes$.pipe(
      first(),
      map(recipes => this.categoryUtils.getRecipes(categoryId, recipes))
    );
  }

  /**
   * call editCategoryClicked event emitter with a category event
   *
   * @param category a single RecipeCategory
   */
  onEditCategory(category: RecipeCategory) {
    this.editCategoryClicked.emit(category);
  }

  /**
   * call deleteCategoryClicked event emitter with a category event
   *
   * @param category a single RecipeCategory
   */
  onDeleteCategory(category: RecipeCategory) {
    this.deleteCategoryClicked.emit(category);
  }
}
