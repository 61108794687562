import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AddItemDialogComponent } from '@gfs/shared-components';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-save-bar',
  templateUrl: './save-bar.component.html',
  styleUrls: ['./save-bar.component.scss'],
})
export class SaveBarComponent implements OnInit, OnDestroy {

  @Input() allowSave: boolean;
  @Input() showWarningIndicator = false;
  @Output() ingredientAdded = new EventEmitter<{
    itemId: string;
    itemType: string;
  }>();
  @Output() backClicked = new EventEmitter<void>();

  public ingredientDialogRef: MatDialogRef<AddItemDialogComponent, any>;
  public newIngredientSubscription: Subscription;

  nextIngredient$ = new Subject<{ itemId: string; itemType: string }>();

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.newIngredientSubscription = this.nextIngredient$
      .subscribe((x) => this.ingredientAdded.emit(x));
  }

  ngOnDestroy(): void {
    this.newIngredientSubscription.unsubscribe();
  }

  onAddIngredientClick(): void {
    this.ingredientDialogRef = this.dialog.open(AddItemDialogComponent, {
      data: {
        selectRecipeIngredientMode: true,
        ingredentOutput$: this.nextIngredient$,
      },
      panelClass: 'inventory-add-item-dialog',
    });
  }

  back() {
    this.backClicked.emit();
  }
}
