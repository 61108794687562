import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PriceUtilsService {

  static staticConvertFractionToDecimal(fraction: string): number {
    return new PriceUtilsService().convertFractionToDecimal(fraction);
  }

  convertFractionToDecimal(fraction: string): number {
    const fracSplit = fraction.split(/[\/\\]/);
    if (fracSplit.length > 1) {
      const nomSplit = fracSplit[0].trim().split(' ');
      let whole = '0';
      let nom = nomSplit[0];
      const denom = fracSplit[1];
      if (nomSplit.length > 1) {
        whole = nomSplit[0];
        nom = nomSplit[1];
      }
      return parseInt(whole, 10) + (parseInt(nom, 10) / parseInt(denom, 10));
    }
    return parseFloat(fraction);
  }
}
