import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileAddedEvent, ImageRemovedEvent, PhotoListItemViewModel, ReorderImageEvent } from '../photo-manager.model';

@Component({
  selector: 'app-photo-list-item',
  templateUrl: './photo-list-item.component.html',
  styleUrls: ['./photo-list-item.component.scss']
})
export class PhotoListItemComponent {

  @Input()
  vm: PhotoListItemViewModel;

  @Output()
  fileSelected = new EventEmitter<FileAddedEvent>();

  @Output()
  imageRemoved = new EventEmitter<ImageRemovedEvent>();

  @Output()
  moveImage = new EventEmitter<ReorderImageEvent>();

}
