import { MeasurementUnit } from '@gfs/shared-models';
import * as measurementUnit from '../actions/measurementUnit.action';


export interface State {
  measurementUnits: { [s: string]: MeasurementUnit };
  isLoading: boolean;
  error: string;
}

export const initialState: State = {
  measurementUnits: null,
  isLoading: false,
  error: ''
};

export function measurementUnitReducer(state: State = initialState, action: measurementUnit.ActionUnion): State {
  switch (action.type) {
    case measurementUnit.ActionTypes.GetMeasurementUnitsAttempt: {
      return {
        ...state,
        isLoading: true
      };
    }
    case measurementUnit.ActionTypes.GetMeasurementUnitsSuccess: {
      const unitMap = action.units.reduce((acc, next) => {
        acc[next.id] = next;
        return acc;
      }, {});
      return {
        ...state,
        isLoading: false,
        measurementUnits: unitMap
      };
    }
    case measurementUnit.ActionTypes.GetMeasurementUnitsError: {
      return {
        ...state,
        isLoading: false,
        error: action.error.message
      };
    }
    default: {
      return state;
    }
  }
}
