import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { RecipePriceType } from '@gfs/shared-models';
import { RecipeinitialStateErrorMatcher } from '@recipe-ui/services/shared/recipe-error-state-matcher/recipe-error-state-matcher';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-recipe-pricing-panel',
  templateUrl: './recipe-pricing-panel.component.html',
  styleUrls: ['./recipe-pricing-panel.component.scss'],
})
export class RecipePricingPanelComponent implements OnInit, OnDestroy {
  @Input() parentForm: UntypedFormGroup;
  @Input() recipeType: string;
  @Input() totalFoodCost: number;
  @Input() pricePerPortion: number;
  @Input() pricePerPortionLabel: string; // deprecated
  @Input() pricePerPortionUnit: string;
  @Input() isPriceCalcuationInProgress$: Observable<boolean>;
  @Output() calculationConstantChanged = new EventEmitter<RecipePriceType>();
  recipePriceType = RecipePriceType;
  errorMatcher = new RecipeinitialStateErrorMatcher();

  $pricingModel = new BehaviorSubject<RecipePriceType>(RecipePriceType.MENU_PRICE);
  $destroyNotifer = new Subject<void>();

  ngOnInit(): void {
    this.$pricingModel.pipe(
      takeUntil(this.$destroyNotifer),
      distinctUntilChanged(),
      tap(g => { this.calculationConstantChanged?.emit(g); })).subscribe();
  }

  ngOnDestroy(): void {
    this.$destroyNotifer.next();
  }
}
