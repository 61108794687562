import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileAddedEvent, ImageRemovedEvent, PhotoListItemViewModel, ReorderImageEvent } from '../photo-manager.model';

@Component({
  selector: 'app-photo-list',
  templateUrl: './photo-list.component.html',
  styleUrls: ['./photo-list.component.scss']
})
export class PhotoListComponent {

  @Input()
  collection: PhotoListItemViewModel[] = [];

  @Output()
  fileSelected = new EventEmitter<FileAddedEvent>();

  @Output()
  imageRemoved = new EventEmitter<ImageRemovedEvent>();

  @Output()
  moveImage = new EventEmitter<ReorderImageEvent>();
}
