import { NgModule } from '@angular/core';
import { RecipeV2Module } from '@recipe-ui/v2/v2.module';
import { CustomerUnitSelectionComponentWrapperComponent, EditComponentWrapperComponent } from '.';
import { CommonModule } from '@angular/common';
import { CustomerUnitSelectionModule } from '@gfs/shared-components';
import { CustomerUnitSearchComponent } from './customer-unit-search/customer-unit-search.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { CategoryModule } from '../../../../../apps/recipe-ui/src/app/category/category.module'


@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatSelectModule,
        RecipeV2Module,
        TranslateModule,
        CustomerUnitSelectionModule,
        CategoryModule
    ],
    declarations: [
        EditComponentWrapperComponent,
        CustomerUnitSelectionComponentWrapperComponent,
        CustomerUnitSearchComponent,
    ],
    exports: [
        EditComponentWrapperComponent,
        CustomerUnitSelectionComponentWrapperComponent,
        CustomerUnitSearchComponent,
    ]
})
export class RecipeProfitCalculatorModule { }
