<ng-container [formGroup]="parentForm" *ngIf="!!parentForm">
  <mat-expansion-panel class="darkpanel" [expanded]="true">
    <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
      <mat-panel-title tabindex="-1">
        {{ 'RECIPE.PRICING.TITLE' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <gfs-loading-overlay [hidden]="!(isPriceCalcuationInProgress$ | async)">
    </gfs-loading-overlay>
    <div class="recipe-type-radio-group">
      <div class="price-list">
        <div class="price-list-item">
          <div class="price-item-label">
            {{ 'RECIPE.PRICING.TOTAL_FOOD_COST' | translate }}
            <div class="price-item-subtext">
              {{ 'RECIPE.PRICING.TOTAL_FOOD_COST_DESCRIPTION' | translate }}
            </div>
          </div>
          <div class="price-item-value">
            <gfs-localized-currency-value [value]="totalFoodCost"></gfs-localized-currency-value>
          </div>
        </div>
        <div *ngIf="recipeType === 'MENUITEMRECIPE'" class="price-list-item">
          <div class="price-item-label">
            {{ 'RECIPE.PRICING.FOOD_COST_PERCENTAGE' | translate }}
          </div>
          <div class="price-item-value">
            <mat-form-field tight appearance="outline">
              <span matSuffix>%</span>
              <input formControlName="foodCostPercent" [errorStateMatcher]="errorMatcher" (change)="$pricingModel.next('FOOD_COST_PERCENT')"
                (click)="$event.target.select()" attr.aria-label="{{
                  'RECIPE.PRICING.FOOD_COST_PERCENTAGE' | translate
                }}" class="align-right" matInput type="number" value="0" min="0" />
                <mat-error >Food cost % cannot be negative.</mat-error> 
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="recipeType === 'MENUITEMRECIPE'" class="price-list-item">
          <div class="price-item-label">
            {{ 'RECIPE.PRICING.MENU_PRICE' | translate }}
          </div>
          <div class="price-item-value">
            <mat-form-field tight appearance="outline">
              <span matPrefix>$</span>
              <input formControlName="menuPrice" [errorStateMatcher]="errorMatcher" (change)="$pricingModel.next('MENU_PRICE')"
                (click)="$event.target.select()" attr.aria-label="{{ 'RECIPE.PRICING.MENU_PRICE' | translate }}"
                class="align-right" matInput type="number" value="0.00" min="0"/>
                <mat-error >Menu price cannot be negative.</mat-error> 
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="recipeType === 'MENUITEMRECIPE'" class="price-list-item">
          <div class="price-item-label">
            {{ 'RECIPE.PRICING.MARGIN' | translate }}
          </div>
          <div *ngIf="parentForm.controls['margin'].value < 0">
            <gfs-warning-icon class="warning-icon" [i18nTooltip]="'You have a negative margin !'"></gfs-warning-icon>
          </div>
         
          <div class="price-item-value">
            <mat-form-field tight appearance="outline">
              <span matPrefix>$</span>
              <input formControlName="margin" [errorStateMatcher]="errorMatcher" (change)="$pricingModel.next('MARGIN')" (click)="$event.target.select()"
                attr.aria-label="{{ 'RECIPE.PRICING.MARGIN' | translate }}" class="align-right" matInput type="number"
                precision="2" value="0.00" />
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="recipeType === 'BATCHRECIPE' && !!pricePerPortionUnit && !!pricePerPortion" class="price-list-item">
          <div class="price-list-item">
            <div class="price-item-label">
              {{ 'RECIPE.PRICING.COST_PER_UNIT' | translate : { value: pricePerPortionUnit} }}
              <div class="price-item-subtext">
                {{ 'RECIPE.PRICING.COST_PER_UNIT_DESCRIPTION' | translate }}
              </div>
            </div>
            <div class="price-item-value">
              <gfs-localized-currency-value [value]="pricePerPortion"></gfs-localized-currency-value>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>
