import { Injectable } from '@angular/core';
import {
  AddItemsModalAppSettings,
  IAddItemsModalAppSettingsProviderService,
  AppConfigService
} from '@gfs/shared-services';

@Injectable({
  providedIn: 'root',
})
export class InventoryAddItemsModalAppSettingsProviderServiceService
  implements IAddItemsModalAppSettingsProviderService {
  constructor(private configService: AppConfigService) {}
  getSettings(): AddItemsModalAppSettings {
    const config = this.configService.getSettings();
    return {
      FF_ADD_NON_GFS_ITEMS: config.FF_ADD_NON_GFS_ITEMS,
      FF_SEARCH_FILTER_CATALOGUE: config.FF_SEARCH_FILTER_CATALOGUE,
      FF_SEARCH_FILTER_NONGFS_ITEMS: config.FF_SEARCH_FILTER_NONGFS_ITEMS,
      FF_SEARCH_FILTER_ORDER_GUIDE_ITEMS: config.FF_SEARCH_FILTER_ORDER_GUIDE_ITEMS,
      FF_SEARCH_FILTER_RECIPES_ITEMS: config.FF_SEARCH_FILTER_RECIPES_ITEMS,
      FF_CUSTOM_COUNT_UNITS: config.FF_CUSTOM_COUNT_UNITS
    };
  }
}
