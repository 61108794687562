import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-batch-yield-help-dialog',
  templateUrl: './batch-yield-help-dialog.component.html',
  styleUrls: ['./batch-yield-help-dialog.component.scss'],
})
export class BatchYieldHelpDialogComponent {
  constructor(public dialogRef: MatDialogRef<BatchYieldHelpDialogComponent>) {}

}
