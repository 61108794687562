import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TruncatedPipe } from './truncated.pipe';
import { CategoryRecipesCountPipe } from './category-recipes-count.pipe';
import { WithCategoryIdPipe } from './with-category-filter.pipe';
import { RecipeNameFilterPipe } from './recipe-name-filter.pipe';

@NgModule({
  declarations: [
    TruncatedPipe,
    CategoryRecipesCountPipe,
    WithCategoryIdPipe,
    RecipeNameFilterPipe,
  ],
  imports: [CommonModule],
  exports: [
    TruncatedPipe,
    CategoryRecipesCountPipe,
    WithCategoryIdPipe,
    RecipeNameFilterPipe,
  ],
})
export class PipeSharingModule {}
