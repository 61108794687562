import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RecipeService } from '@gfs/shared-services';
import { AppState } from '@gfs/store/recipe/reducers';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, debounceTime, distinctUntilChanged, startWith, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-category-recipe-search-input',
  templateUrl: './category-recipe-search-input.component.html',
  styleUrls: ['./category-recipe-search-input.component.scss'],
})
export class CategoryRecipeSearchInputComponent implements OnInit , AfterViewInit , OnDestroy{
  @Output() beginSearch: EventEmitter<string> = new EventEmitter<string>();

  @Input() searchText: string;
  isMobile$: Observable<boolean> = this.store.select(state => state.layout.isMobile);
  isOnline$: Observable<boolean> = this.store.select(state => state.network.isOnline);
  constructor(private store: Store<AppState>,private svc: RecipeService) { }

  notifier = new Subject<void>();

  searchTermSubject = new BehaviorSubject('');
  searchTermChange = new BehaviorSubject('');


  ngOnInit(): void {
    this.searchTermSubject
      .asObservable()
      .pipe(
        startWith(''),
        takeUntil(this.notifier),
        tap((filterText) => {
          this.svc.setRecipeNameFilter(filterText);
        })
      )
      .subscribe();
  }
  oninputChange(value:string) {
    this.searchTermChange.next(value)
  }
  ngAfterViewInit() {
    this.searchTermChange.pipe(
      debounceTime(900),
        distinctUntilChanged(),
        takeUntil(this.notifier),
        tap((inputText:any) => {
          this.searchTermSubject.next(inputText)
          this.svc.setRecipeNameFilter(inputText);
        })
    ).subscribe()
}

  ngOnDestroy(): void {
    this.notifier.next();
  }
}
