import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RecipeImageUploadBase64 } from '@recipe-ui/ui/photo-manager/services/recipe-image-upload';
import { FileAddedEvent, UIFileAddedEvent } from '../photo-manager.model';

@Component({
  selector: 'app-photo-content-placeholder',
  templateUrl: './photo-content-placeholder.component.html',
  styleUrls: ['./photo-content-placeholder.component.scss']
})
export class PhotoContentPlaceholderComponent {

  @Input()
  imageIndex = -1;

  @Output() fileSelected = new EventEmitter<FileAddedEvent>();
  toUIFileAddedEvent = ($event: Event) => $event as any as UIFileAddedEvent;

  constructor(
    public base64ImageService: RecipeImageUploadBase64
  ) { }

  openAddFileDialog = ($clickEvent: Event) => {
    this.base64ImageService.openAdhocFileSelect(($innerEvent: Event) => {
      const e = this.toUIFileAddedEvent($innerEvent);
      this.fileSelected.emit({ file: e.target.files[0], imageIndex: this.imageIndex });
    },
      this.imageIndex);
  };

  addFile = ($event: DragEvent) => {
    $event.preventDefault();
    this.fileSelected.emit({ file: $event.dataTransfer.files[0], imageIndex: this.imageIndex });
  };
}
