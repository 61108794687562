import * as category from '../actions/category.actions';
import { CategoryOrder, RecipeCategory } from '@gfs/shared-models';

export interface State {
  categories: { [s: string]: RecipeCategory };
  categoryOrder: CategoryOrder;
  isLoading: boolean;
  error: string;
  isInfinitescroll: boolean
}

export const initialState: State = {
  categories: null,
  categoryOrder: null,
  isLoading: false,
  error: '',
  isInfinitescroll:false
};

export function categoryReducer(state: State = initialState, action: category.ActionUnion): State {
  switch (action.type) {
    case category.ActionTypes.SetCategoryExpandStatus: {
      state.categories[action.payload.categoryId].expandStatus = action.payload.status;
      return state;
    }
    case category.ActionTypes.CreateCategoryAttempt:
    case category.ActionTypes.GetCategoriesAttempt: {
      return {
        ...state,
        isLoading: true
      };
    }
    case category.ActionTypes.GetCategoriesSuccess: {
      const catMap = action.categories.reduce((acc, next) => {
        acc[next.id] = next;
        return acc;
      }, {});
      return {
        ...state,
        isLoading: false,
        categories: catMap
      };
    }
    case category.ActionTypes.GetCategoriesOrderSuccess: {
      return {
        ...state,
        categoryOrder: action.categoriesOrder,
        isLoading: false
      };
    }
    case category.ActionTypes.CreateCategorySuccess: {
      return {
        ...state,
        isLoading: false,
        categories: {
          ...state.categories,
          [action.category.id]: action.category
        }
      };
    }
    case category.ActionTypes.PatchCategoryAttempt: {
      return {
        ...state,
        isLoading: true,
        categories: {
          ...state.categories,
          [action.category.id]: {
            ...state.categories[action.category.id],
            name: action.category.name
          }
        }
      };
    }
    case category.ActionTypes.PutCategoriesOrderSuccess: {
      return {
        ...state,
        isLoading: false,
        categoryOrder: action.categoryOrder
      };
    }
    case category.ActionTypes.PatchCategorySuccess:
    case category.ActionTypes.DeleteCategorySuccess: {
      return {
        ...state,
        isLoading: false
      };
    }
    case category.ActionTypes.DeleteCategoryAttempt: {
      const withoutDeleted = Object.keys(state.categories)
        .filter(key => key !== action.category.id)
        .reduce((acc, next) => {
          acc[next] = state.categories[next];
          return acc;
        }, {});

      return {
        ...state,
        isLoading: true,
        categories: withoutDeleted
      };
    }
    case category.ActionTypes.CreateCategoryError:
    case category.ActionTypes.PatchCategoryError:
    case category.ActionTypes.DeleteCategoryError:
    case category.ActionTypes.GetCategoriesError: {
      return {
        ...state,
        isLoading: false,
        error: action.error.message
      };
    }
    case category.ActionTypes.setRecipeInfiniteScroll: {
      return {
        ...state,
        isInfinitescroll: action.infiniteScrollState
      };
    }
    default: {
      return state;
    }
  }
}
