import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrintableRecipeVM } from '@gfs/shared-models';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-print-recipe-dialog',
  templateUrl: './print-recipe-dialog.component.html',
  styleUrls: ['./print-recipe-dialog.component.scss'],
})
export class PrintRecipeDialogComponent implements OnInit {
  vm: Observable<PrintableRecipeVM> = null;
  constructor(
    public dialogRef: MatDialogRef<PrintRecipeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PrintRecipeDialogComponentData,
    private spinner: LoadingSpinnerOverlayService

  ) {
    this.spinner.show();
  }

  ngOnInit(): void {
    this.vm = this.data.vm$.pipe(
      tap(() => { this.spinner.hide(); })
    );
  }
}

export interface PrintRecipeDialogComponentData {
  vm$: Observable<PrintableRecipeVM>;
}
