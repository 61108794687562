import { Injectable } from '@angular/core';
import { IInventoryDataProvider } from '@gfs/shared-services';
import { AppState } from '@gfs/store/recipe/reducers';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class InventoryDataProviderService {
  constructor(private store: Store<AppState>) {}
  public static factory(factory: InventoryDataProviderService) {
    return factory.Build();
  }
  Build(): IInventoryDataProvider {
    return {
      products: this.store.select((x) => {
        return x.addItemsFeature.countableItems;
      }),
    };
  }
}
