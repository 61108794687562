import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, tap, mergeMap, withLatestFrom, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import {
  ActionUnion,
  ActionTypes,
  GetMeasurementUnitsSuccess,
  GetMeasurementUnitsError
} from '../actions/measurementUnit.action';
import { MeasurementUnitService } from '../../../lib/services/measurement-unit.service';
import { MessageService } from '../../../lib/services/message.service';

@Injectable()
export class MeasurementUnitEffects {

  constructor(
    private actions$: Actions<ActionUnion>,
    private measurementUnitService: MeasurementUnitService,
    private messageService: MessageService,
    private store: Store<AppState>
    ) {}


  getMeasurementUnits$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetMeasurementUnitsAttempt),
    withLatestFrom(this.store),
    mergeMap(([action, state]) => {
      return this.measurementUnitService.getMeasurementUnits(state.auth.pk)
        .pipe(
          map(units => new GetMeasurementUnitsSuccess(units)),
          catchError(err => of(new GetMeasurementUnitsError(err))
          )
      );
    })
  ));


  measurementUnitActionLogger$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.GetMeasurementUnitsError),
    tap(err => {
      this.messageService.queue(err.error.message);
    })
  ), { dispatch: false });
}
