import { Injectable } from '@angular/core';
import { Cart, Entitlement } from '@gfs/shared-models';
import { ICustomerUnitSelectionFeatureBridge } from '@gfs/store/feature/customer-unit-selection';
import { Store } from '@ngrx/store';
import { AppState } from '@gfs/store/recipe/reducers';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CustomerUnitSelectionFactoryService {
  constructor(private store: Store<AppState>) { }

  public static factory(factory: CustomerUnitSelectionFactoryService) {
    return factory.Build();
  }

  Build(): ICustomerUnitSelectionFeatureBridge {
    return {
      selectedEntitlement: this.store.select((state) => {
        if (state.auth.pk && state.auth.user) {
          return state.auth.user.entitlements.find(
            (ent) => ent.customerPK.customerId === state.auth.pk.customerId
          );
        }
        return null;
      }),
      cartsQuantities: this.store.select((state) => state.cartFeature.cartsQuantities),
    };
  }
}


// @Injectable({
//   providedIn: 'root',
// })
// export class CustomerUnitSelectionFactoryService2 {
//   constructor(private store: Store<AppState>) { }

//   public static factory(factory: CustomerUnitSelectionFactoryService) {
//     return factory.Build();
//   }

//   Build(): Observable<Entitlement> {
//     return this.store.select((state) =>
//       state.auth.user.entitlements
//         .find(
//           (ent) => ent.customerPK.customerId === state.auth.pk.customerId
//         )
//     )
//   }
// }
