import { Action } from '@ngrx/store';
import { RecipeCategory, CategoryOrder } from '@gfs/shared-models';
import { HttpErrorResponse } from '@angular/common/http';


export enum ActionTypes {
  SetCategoryExpandStatus = '[Category] Set Category Expand Status',
  GetCategoriesAttempt = '[Category] Get Categories Attempt',
  GetCategoriesSuccess = '[Category] Get Categories Success',
  GetCategoriesError = '[Category] Get Categories Error',
  GetCategoriesOrderAttempt = '[Category] Get Categories Order Attempt',
  GetCategoriesOrderSuccess = '[Category] Get Categories Order Success',
  GetCategoriesOrderError = '[Category] Get Categories Order Error',
  CreateCategoryAttempt = '[Category] Create Category Attempt',
  CreateCategorySuccess = '[Category] Create Category Success',
  CreateCategoryError = '[Category] Create Category Error',
  PatchCategoryAttempt = '[Category] Patch Category Attempt',
  PatchCategorySuccess = '[Category] Patch Category Success',
  PatchCategoryError = '[Category] Patch Category Error',
  PutCategoriesOrderAttempt = '[Category] Put Category Attempt',
  PutCategoriesOrderSuccess = '[Category] Put Category Success',
  PutCategoriesOrderError = '[Category] Put Category Error',
  DeleteCategoryAttempt = '[Category] Delete Category Attempt',
  DeleteCategorySuccess = '[Category] Delete Category Success',
  DeleteCategoryError = '[Category] Delete Category Error',
  setRecipeInfiniteScroll = '[Category] Use infinite scroll',
}

export class SetCategoryExpandStatus implements Action {
  readonly type = ActionTypes.SetCategoryExpandStatus;
  constructor(public payload: { status: boolean, categoryId: string }) {}
}

export class GetCategoriesAttempt implements Action {
  readonly type = ActionTypes.GetCategoriesAttempt;
}

export class GetCategoriesSuccess implements Action {
  readonly type = ActionTypes.GetCategoriesSuccess;
  constructor(public categories: RecipeCategory[]) {}
}

export class GetCategoriesError implements Action {
  readonly type = ActionTypes.GetCategoriesError;
  constructor(public error: HttpErrorResponse) {}
}

export class GetCategoriesOrderAttempt implements Action {
  readonly type = ActionTypes.GetCategoriesOrderAttempt;
}

export class GetCategoriesOrderSuccess implements Action {
  readonly type = ActionTypes.GetCategoriesOrderSuccess;
  constructor(public categoriesOrder: CategoryOrder) { }
}

export class GetCategoriesOrderError implements Action {
  readonly type = ActionTypes.GetCategoriesOrderError;
  constructor(public error: HttpErrorResponse) { }
}

export class CreateCategoryAttempt implements Action {
  readonly type = ActionTypes.CreateCategoryAttempt;
  constructor(public categoryName: string) {}
}

export class CreateCategorySuccess implements Action {
  readonly type = ActionTypes.CreateCategorySuccess;
  constructor(public category: RecipeCategory) {}
}

export class CreateCategoryError implements Action {
  readonly type = ActionTypes.CreateCategoryError;
  constructor(public error: HttpErrorResponse) {}
}

export class PatchCategoryAttempt implements Action {
  readonly type = ActionTypes.PatchCategoryAttempt;
  constructor(public category: RecipeCategory) {}
}

export class PatchCategorySuccess implements Action {
  readonly type = ActionTypes.PatchCategorySuccess;
  constructor(public category: RecipeCategory) {}
}

export class PatchCategoryError implements Action {
  readonly type = ActionTypes.PatchCategoryError;
  constructor(public error: HttpErrorResponse) {}
}

export class PutCategoriesOrderAttempt implements Action {
  readonly type = ActionTypes.PutCategoriesOrderAttempt;
  constructor(public categoryOrder: CategoryOrder) { }
}

export class PutCategoriesOrderSuccess implements Action {
  readonly type = ActionTypes.PutCategoriesOrderSuccess;
  constructor(public categoryOrder: CategoryOrder) { }
}

export class PutCategoriesOrderError implements Action {
  readonly type = ActionTypes.PutCategoriesOrderError;
  constructor(public error: HttpErrorResponse) { }
}


export class DeleteCategoryAttempt implements Action {
  readonly type = ActionTypes.DeleteCategoryAttempt;
  constructor(public category: RecipeCategory) {}
}

export class DeleteCategorySuccess implements Action {
  readonly type = ActionTypes.DeleteCategorySuccess;
}

export class DeleteCategoryError implements Action {
  readonly type = ActionTypes.DeleteCategoryError;
  constructor(public error: HttpErrorResponse) {}
}

export class setRecipeInfiniteScroll implements Action {
  readonly type = ActionTypes.setRecipeInfiniteScroll;
  constructor(public infiniteScrollState: boolean) { }
}

export type ActionUnion =
  SetCategoryExpandStatus |
  GetCategoriesAttempt |
  GetCategoriesSuccess |
  GetCategoriesError |
  GetCategoriesOrderAttempt |
  GetCategoriesOrderSuccess |
  GetCategoriesOrderError |
  CreateCategoryAttempt |
  CreateCategorySuccess |
  CreateCategoryError |
  PatchCategoryAttempt |
  PatchCategorySuccess |
  PatchCategoryError |
  PutCategoriesOrderAttempt |
  PutCategoriesOrderSuccess |
  PutCategoriesOrderError |
  DeleteCategoryAttempt |
  DeleteCategorySuccess |
  DeleteCategoryError |
  setRecipeInfiniteScroll;

