<ng-container *ngIf="!!recipe?.details?.menu">
  <div class="pricing-row">
    <div class="pricing-item">
      <div class="pricing-label">
        {{ "CATEGORY.RECIPE_CARD.MARGIN" | translate | uppercase }}
      </div>
      <div class="pricing-value bold">
        {{ (calculatedPrice.margin * -1) | currency:'CAD':'$':undefined:(currentLang$ | async)}}
      </div>
    </div>
    <div class="pricing-item">
      <div class="pricing-label">
        {{ "CATEGORY.RECIPE_CARD.FOOD_COST_PERCENTAGE" | translate | uppercase }}
      </div>
      <div class="pricing-value bold">
        {{ (calculatedPrice.foodCostPercent | number: '1.2-2') + '%'}}
      </div>
    </div>
  </div>
  <div class="pricing-row">
    <div class="pricing-item">
      <div class="pricing-label">
        {{ "CATEGORY.RECIPE_CARD.MENU_PRICE" | translate | uppercase }}
      </div>
      <div class="pricing-value smaller">
        {{ calculatedPrice.menuPrice | currency:'CAD':'$':undefined:(currentLang$ | async)}}
      </div>
    </div>
    <div class="pricing-item">
      <div class="pricing-label">
        {{ "CATEGORY.RECIPE_CARD.TOTAL_FOOD_COST" | translate | uppercase }}
      </div>
      <div class="pricing-value smaller">
        {{ calculatedPrice.totalCost | currency:'CAD':'$':undefined:(currentLang$ | async)}}
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!!recipe?.details?.batch">
  <div class="batch-pricing-wrapper">
    <div class="pricing-row">
      <div class="pricing-item">
        <div class="pricing-label">
          {{ "CATEGORY.RECIPE_CARD.TOTAL_FOOD_COST" | translate | uppercase }}
        </div>
        <div class="pricing-value smaller">
          {{ calculatedPrice.totalCost | currency:'CAD':'$':undefined:(currentLang$ | async)}}
        </div>
      </div>
    </div>
    <div class="pricing-row">
      <div class="pricing-item">
        <div class="pricing-label">
          {{ "RECIPE.PRICING.COST_PER_PORTION" | translate | uppercase }}
        </div>
        <div class="pricing-value smaller">
          {{ calculatedPrice.portionPrice | currency:'CAD':'$':undefined:(currentLang$ | async)}}
        </div>
      </div>
      <div class="icon-wrapper">
        <mat-icon
          [inline]="true"
          svgIcon="info_icon_hover"
          matTooltip="{{ 'CATEGORY.RECIPE_CARD.PORTION_BASED_ON_UOM' | translate }}"
          aria-label="{{ 'CATEGORY.RECIPE_CARD.PORTION_BASED_ON_UOM' | translate }}"
          matTooltipPosition="above"
          [matTooltipClass]="'price-tooltip'"
        ></mat-icon>
      </div>
    </div>
  </div>
</ng-container>
