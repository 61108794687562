import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Recipe } from '@gfs/shared-models';

@Pipe({
  name: 'recipeNameFilter',
})
export class RecipeNameFilterPipe implements PipeTransform {
  transform(
    recipeList: Observable<Recipe[]>,
    searchTerm: string
  ): Observable<Recipe[]> {
    return recipeList.pipe(
      map((results) =>
        results.filter(
          (x) =>
            searchTerm === null ||
            searchTerm.trim() === '' ||
            (x.name ?? '').toLowerCase().includes(searchTerm.trim().toLowerCase())
        )
      )
    );
  }
}
