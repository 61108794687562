<div class="fixed-options-bar no-print">
  <button type="button" class="primary-button" (click)="onAddIngredientClick()">
    {{ 'RECIPE.CREATE.ADD_INGREDIENT' | translate }}
  </button>
  <div class="save-cancel-container">
    <button id="new-recipe-cancel" type="button" class="secondary-button" (click)="back()">
      {{ 'MODALS.CANCEL' | translate }}
    </button>
    <button mat-icon-button id="new-recipe-submit" type="submit" class="primary-button" [disabled]="!allowSave">
      {{ 'RECIPE.CREATE.SAVE_RECIPE' | translate }}
    </button>
  </div>
</div>
