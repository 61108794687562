<div class="error-modal-container">
  <img class="sorry-img" alt="{{ 'ERROR.SORRY_IMAGE.ALT_TEXT' | translate }}"
    src="{{ 'ERROR.SORRY_IMAGE.1X' | translate }}"
    srcset="{{'ERROR.SORRY_IMAGE.1X' | translate }} 1x, {{'ERROR.SORRY_IMAGE.2X' | translate }} 2x, {{'ERROR.SORRY_IMAGE.3X' | translate }} 3x" />
  <div class="error-title">
    {{ 'ERROR.ERROR_HEADER' | translate }}
  </div>
  <div class="error-subtitle">
    {{ 'ERROR.ERROR_SUB_TEXT' | translate }}
  </div>
  <div class="error-button-wrapper">
    <button class="primary-button large-button back-to-category-button" (click)="onBackToCategory()">
      {{ 'ERROR.BACK_BUTTON' | translate }}
    </button>
  </div>
</div>
