<button class="close" mat-button (click)="dialogRef.close()" >
  <mat-icon>close</mat-icon>
</button>
<h2>
  {{ 'RECIPE.BATCH_YIELD_HELP_DIALOG.HOW_MUCH_DOES_RECIPE_YIELD' | translate }}
</h2>
<div class="content">
  <div class="subheading">
    <h4>{{ 'RECIPE.BATCH_YIELD_HELP_DIALOG.SOME_EXAMPLES' | translate }}</h4>
  </div>
  <div class="horizontal-section-container">
    <div class="horizontal-section">
      <h4>
        {{
          'RECIPE.BATCH_YIELD_HELP_DIALOG.UNITS_VOLUME_LABEL'
            | translate
            | uppercase
        }}
      </h4>
      <ul>
        <li>
          <mat-icon matSuffix svgIcon="soup"></mat-icon>
          <span class="example-label">{{
            'RECIPE.BATCH_YIELD_HELP_DIALOG.SOUP' | translate
          }}</span>
          <span class="example-text">{{
            'RECIPE.BATCH_YIELD_HELP_DIALOG.50_FLUID_OUNCES' | translate
          }}</span>
        </li>
        <li>
          <mat-icon matSuffix svgIcon="sauce"></mat-icon>
          <span class="example-label">{{
            'RECIPE.BATCH_YIELD_HELP_DIALOG.SAUCE' | translate
          }}</span>
          <span class="example-text">{{
            'RECIPE.BATCH_YIELD_HELP_DIALOG.2_GALLONS' | translate
          }}</span>
        </li>
      </ul>
    </div>
    <div class="horizontal-section">
      <h4>
        {{
          'RECIPE.BATCH_YIELD_HELP_DIALOG.UNITS_WEIGHT_LABEL'
            | translate
            | uppercase
        }}
      </h4>
      <ul>
        <li>
          <mat-icon matSuffix svgIcon="dough"></mat-icon>
          <span class="example-label">{{
            'RECIPE.BATCH_YIELD_HELP_DIALOG.DOUGH' | translate
          }}</span>
          <span class="example-text">
            {{ 'RECIPE.BATCH_YIELD_HELP_DIALOG.160_OUNCES' | translate }}
          </span>
        </li>
        <li>
          <mat-icon matSuffix svgIcon="meat"></mat-icon>
          <span class="example-label">{{
            'RECIPE.BATCH_YIELD_HELP_DIALOG.MEAT' | translate
          }}</span>
          <span class="example-text">
            {{ 'RECIPE.BATCH_YIELD_HELP_DIALOG.8_POUNDS' | translate }}
          </span>
        </li>
      </ul>
    </div>
    <div class="horizontal-section">
      <h4>
        {{
          'RECIPE.BATCH_YIELD_HELP_DIALOG.UNITS_LABEL' | translate | uppercase
        }}
      </h4>
      <ul>
        <li>
          <mat-icon matSuffix svgIcon="cake"></mat-icon>
          <span class="example-label">{{
            'RECIPE.BATCH_YIELD_HELP_DIALOG.CAKE' | translate
          }}</span>
          <span class="example-text">{{
            'RECIPE.BATCH_YIELD_HELP_DIALOG.12_PIECES' | translate
          }}</span>
        </li>
        <li>
          <mat-icon matSuffix svgIcon="bread"></mat-icon>
          <span class="example-label">{{
            'RECIPE.BATCH_YIELD_HELP_DIALOG.BREAD' | translate
          }}</span>
          <span class="example-text">{{
            'RECIPE.BATCH_YIELD_HELP_DIALOG.30_SLICES' | translate
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>
