import { RecipeConstants } from '@gfs/constants';
import {
  CountableItem,
  IngredientItem,
  PrintableRecipeVM,
  Recipe,
  RecipeIngredient,
  RecipePrice,
  RecipePriceType,
  Worksheet,
} from '@gfs/shared-models';
import {
  arrayToEntityDictionary,
  createStatePatchByPath,
  entityDictionaryToArray,
} from '@gfs/shared-services/core/entityUtil';
import * as recipe from '../actions/recipe.actions';

export interface State {
  recipes: { [s: string]: Recipe };
  printableRecipes: { [s: string]: PrintableRecipeVM };
  currentRecipeId?: string;
  ingredients: { [s: string]: RecipeIngredient };
  ingredientItemData: { [s: string]: IngredientItem };
  ingredientCountableItemData: { [s: string]: CountableItem };
  mostRecentWorksheet: Worksheet;
  recipePricing: { [recipeId: string]: RecipePrice };
  isLoading: boolean;
  error: string;
}

export const initialState: State = {
  recipes: null,
  printableRecipes: null,
  currentRecipeId: null,
  ingredients: null,
  ingredientItemData: null,
  ingredientCountableItemData: null,
  mostRecentWorksheet: null,
  recipePricing: null,
  isLoading: false,
  error: '',
};

export function recipeReducer(
  state: State = initialState,
  action: recipe.ActionUnion
): State {
  switch (action.type) {
    case recipe.ActionTypes.GetMostRecentWorksheetAttempt:
    case recipe.ActionTypes.CreateRecipeAttempt:
    case recipe.ActionTypes.PatchRecipeAttempt:
    case recipe.ActionTypes.GetRecipesAttempt: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case recipe.ActionTypes.PatchRecipeSuccess:
    case recipe.ActionTypes.CreateRecipeSuccess: {
      const mapId = action.payload.recipe.id;
      let ingredientsWithIds = {};
      if (!!action.payload.recipe.ingredients) {
        ingredientsWithIds = arrayToEntityDictionary<RecipeIngredient>(
          action.payload.recipe.ingredients,
          (x) => x.id
        );
      }

      return {
        ...state,
        isLoading: false,
        recipes: {
          ...state.recipes,
          [mapId]: action.payload.recipe,
        },
        ingredients: ingredientsWithIds
      };
    }
    case recipe.ActionTypes.GetRecipesSuccess: {
      const recipes = arrayToEntityDictionary<Recipe>(
        action.recipes,
        (x) => x.id
      );
      const loadedPricingData = action.recipes
        .filter((itsARecipe) => !!itsARecipe.recipePrice)
        .map((itsARecipe) => ({
          [itsARecipe.id]: {
            ...itsARecipe.recipePrice,
            menuPrice: itsARecipe.recipePrice.menuPrice.toFixed(2),
            totalCost: itsARecipe.recipePrice.totalCost.toFixed(2),
            foodCostPercent: itsARecipe.recipePrice.foodCostPercent.toFixed(2),
            ingredientPrices: [...itsARecipe.recipePrice.ingredientPrices],
            calculationConfig: {
              constantType: RecipePriceType.MENU_PRICE,
              constantValue: itsARecipe.recipePrice.menuPrice,
            },
          },
        }))
        .reduce((acc, next) => ({ ...acc, ...next }), {});

      return {
        ...state,
        isLoading: false,
        recipes,
        recipePricing: { ...state.recipePricing, ...loadedPricingData } as any,
      };
    }
    case recipe.ActionTypes.DeleteRecipeAttempt: {
      const recipeId = action.recipe.id;
      const withoutDeleted = Object.keys(state.recipes)
        .filter((key) => key !== recipeId)
        .reduce((acc, next) => {
          acc[next] = state.recipes[next];
          return acc;
        }, {});

      return {
        ...state,
        recipes: withoutDeleted,
        isLoading: true,
      };
    }
    case recipe.ActionTypes.DeleteRecipeSuccess: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case recipe.ActionTypes.GetRecipeIngredientsSuccess: {
      const incomingUpdate = arrayToEntityDictionary<RecipeIngredient>(
        action.payload.ingredients,
        (x) => x.id
      );
      return {
        ...state,
        isLoading: false,
        ingredients: incomingUpdate,
      };
    }
    case recipe.ActionTypes.DeleteRecipeError:
    case recipe.ActionTypes.CreateRecipeError:
    case recipe.ActionTypes.PatchRecipeError:
    case recipe.ActionTypes.GenericHttpError:
    case recipe.ActionTypes.GetRecipesError: {
      return {
        ...state,
        isLoading: false,
        error: action.error.message,
      };
    }
    case recipe.ActionTypes.SetActiveRecipeId: {
      const newIngredients = filterStaleIngredients(state.ingredients);
      const loadedRecipePricing =
        (state.recipes ?? {})[action.nextId]?.recipePrice ??
        ({} as RecipePrice);
      loadedRecipePricing.ingredientPrices =
        loadedRecipePricing.ingredientPrices ?? [];
      loadedRecipePricing.calculationConfig = {
        constantType: RecipePriceType.MENU_PRICE,
        constantValue: (
          (state.recipes ?? {})[action.nextId]?.recipePrice?.menuPrice ?? 0
        ).toFixed(2),
      };
      return {
        ...state,
        recipes: {
          ...state.recipes,
          [RecipeConstants.NEW_ENTITY_ID_PLACEHOLDER]: {
            id: RecipeConstants.NEW_ENTITY_ID_PLACEHOLDER,
          } as Recipe,
        },
        recipePricing: {
          ...state.recipePricing,
          [RecipeConstants.NEW_ENTITY_ID_PLACEHOLDER]: undefined,
          [action.nextId]: {
            ...loadedRecipePricing,
          },
        },
        currentRecipeId: action.nextId,
        ingredients: newIngredients,
      };
    }
    case recipe.ActionTypes.GenericRecipeStoreUpdate: {
      const patch = createStatePatchByPath(state, action);
      return {
        ...patch,
      };
    }
    case recipe.ActionTypes.GetMostRecentWorksheetSuccess: {
      return {
        ...state,
        mostRecentWorksheet: action.worksheet,
      };
    }
    case recipe.ActionTypes.PricingCalculationSuccess: {
      const currentPricingConfig = (state.recipePricing ?? {})[state.currentRecipeId]?.calculationConfig;
      return {
        ...state,
        recipePricing: {
          ...state.recipePricing,
          [state.currentRecipeId]: {
            ...action.pricing,
            calculationConfig: {
              ...currentPricingConfig,
              calculationInProgress: false
            },
          } as RecipePrice,
        },
      };
    }
    case recipe.ActionTypes.RefreshRecipePricingSuccess: {
      return {
        ...state,
        recipePricing: {
          ...state.recipePricing,
          [action.pricing.recipeId]: {
            ...action.pricing,
            calculationConfig: {
              ...action.pricing.calculationConfig,
              calculationInProgress: false
            }
          },
          [RecipeConstants.NEW_ENTITY_ID_PLACEHOLDER]: undefined,
        },
      };
    }
    case recipe.ActionTypes.PrintRecipeBegin: {
      return {
        ...state,
        printableRecipes: {
          ...state.printableRecipes,
          [action.recipeId]: { isLoading: true, isReady: false } as PrintableRecipeVM,
        }
      };
    }
    case recipe.ActionTypes.TryRecalculateRecipePricing: {
      return {
        ...state,
        recipePricing: {
          ...state.recipePricing,
          [action.recipeId]: {
            ...state.recipePricing[action.recipeId],
            calculationConfig: {
              ...state.recipePricing[action.recipeId]?.calculationConfig,
              calculationInProgress: true
            }
          } as RecipePrice,
        },
      };
    }
    default: {
      return state;
    }
  }
}
function filterStaleIngredients(state: { [s: string]: RecipeIngredient }) {
  const filteredIngredients = entityDictionaryToArray<RecipeIngredient>(state)
    .filter((x) => !x.isDeleted)
    .filter(
      (x) =>
        ![x.id, x.recipeId].some((y) =>
          y.startsWith(RecipeConstants.NEW_ENTITY_ID_PLACEHOLDER)
        )
    );
  return arrayToEntityDictionary<RecipeIngredient>(
    filteredIngredients,
    (key) => key.id
  );
}
