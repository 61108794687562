import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@gfs/store/recipe/reducers';
import { IQuickAddItemsFeatureBridge } from '@gfs/store/feature/quick-add-items';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QuickAddItemsContextFactoryService {
  public static factory(factory: QuickAddItemsContextFactoryService) {
    return factory.Build();
  }

  constructor(private store: Store<AppState>) {}

  Build(): IQuickAddItemsFeatureBridge {
    return {
      pk$: this.store.select((state) => state.auth.pk),
      currentLang$: this.store.select((state) => state.layout.language),
      isMobile$: this.store.select((state) => state.layout.isMobile),
      customItemData$: of([]),
      selectedWorksheet$: this.store.select(
        (state) => state.recipe.mostRecentWorksheet
      ),
      isSavingWorksheet$: of(null),
    };
  }
}
