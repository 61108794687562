import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileAddedEvent, ImageRemovedEvent, PhotoListItemViewModel, ReorderImageEvent } from './photo-manager.model';

@Component({
  selector: 'app-photo-manager',
  templateUrl: './photo-manager.component.html',
  styleUrls: ['./photo-manager.component.scss']
})
export class PhotoManagerComponent {

  @Input()
  data: PhotoListItemViewModel[];

  @Output()
  fileSelected = new EventEmitter<FileAddedEvent>();

  @Output()
  imageRemoved = new EventEmitter<ImageRemovedEvent>();

  @Output()
  moveImage = new EventEmitter<ReorderImageEvent>();

}
