import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-photo-content',
  templateUrl: './photo-content.component.html',
  styleUrls: ['./photo-content.component.scss']
})
export class PhotoContentComponent {
  @Input()
  content: string;
}
