export class RecipeImageUploadConfig {
    maxSizeBytes: number;
    allowedMimeTypes: string[];
    maxImageCount: number;
}

export class PhotoListItemViewModel {
    constructor(public data: {
        name: string;
        content: string;
        key?: string;
        index: number;
        maxIndex: number;
        isNew?: boolean;
        isDeleted?: boolean;
        isUpdated?: boolean;
        file?: File;
    }) {

    }
    hasContent = () => this.data.content?.length > 0;
    deleteContent = () => this.data = { ...this.data, key: null, content: null, isDeleted: true };
    updateContent = (content: string) => this.data = { ...this.data, content, isDeleted: false, isUpdated: true, isNew: !this.data.key };
}

export interface UIFileAddedEvent {
    target: {
        files: FileList;
    };
}

export interface FileAddedEvent {
    imageIndex: number;
    file: File;
}

export interface ImageRemovedEvent {
    imageIndex: number;
}

export interface ReorderImageEvent {
    imageIndex: number;
    shiftIndex: number;
}
