//#region IMPORTS
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { registerLocaleData } from '@angular/common';
import locale_fr_CA from '@angular/common/locales/fr-CA';
import {
  AfterViewChecked, Component,
  ElementRef, Inject, OnDestroy, OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  ActivatedRoute, NavigationStart, Router
} from '@angular/router';
import { EditItemDialogComponent } from '@gfs/shared-components';
import {
  RECIPE_PROFIT_CALCULATOR_CONFIG,
  RecipeProfitCalculatorConfig,
  IAppContext, ItemReference, PrintableRecipeVM, RecipeCategory, RecipeIngredient, APPLICATION_USER_ROLE, ApplicationUserRole
} from '@gfs/shared-models';
import {
  InjectionTokens, MessageService,
  WINDOW,
  unitGr
} from '@gfs/shared-services';
import { caseInsensitiveEquals } from '@gfs/shared-services/extensions/primitive';
import { firstValueFrom, isTruthy, notifySubject, pushToSubject, tapLog } from '@gfs/shared-services/extensions/rxjs';
import { ActionTypes, GetMeasurementUnitsAttempt, GetSuppliersAttempt } from '@gfs/store/feature/add-items';
import { GetAllCustomItemsAttempt, GetAllGeneralItemsAttempt } from '@gfs/store/inventory/actions/customerItems.actions';
import { GetAllCustomItemDataAttempt } from '@gfs/store/inventory/actions/worksheets.actions';
import {
  AppState
} from '@gfs/store/recipe/reducers';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { ComponentIsSaving } from '@inventory-ui/v2/common/guards/save-in-progress.guard';
import { ActionsSubject, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CategoryCreateModalComponent } from '@recipe-ui/category/category-create-modal/category-create-modal.component';
import { BatchYieldHelpDialogComponent } from '@recipe-ui/recipe/batch-yield-help-dialog/batch-yield-help-dialog.component';
import { PrintRecipeDialogComponent } from '@recipe-ui/recipe/print-recipe-dialog/print-recipe-dialog.component';
import { RecipeCancelConfirmComponent } from '@recipe-ui/recipe/recipe-cancel-confirm/recipe-cancel-confirm.component';
import { PhotoListItemViewModel } from '@recipe-ui/ui/photo-manager/photo-manager.model';
import { checkForDecimalsAndFractionsInputGateFn } from 'libs/shared-components/src/v2/form/v2.form.module';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY, forkJoin, iif, Observable,
  of,
  Subject
} from 'rxjs';
import {
  catchError,
  concatMap, debounceTime, delay, distinctUntilChanged, filter, first,
  map, startWith,
  switchMap,
  takeUntil,
  tap, timeout, withLatestFrom
} from 'rxjs/operators';
import { CalculationState, ManageRecipeVM, RecipeEditStore, RecipeIngredientState, RecipeState } from './edit.component.store';
import { RecipeEditFormControl, recipeFormControlFeelConfig } from './form-factory.service';
import { Constants } from '@gfs/constants';
import { ofType } from '@ngrx/effects';
import { RecipeErrorStateMatcher, RecipeinitialStateErrorMatcher } from '@recipe-ui/services/shared/recipe-error-state-matcher/recipe-error-state-matcher';

//#endregion

type PrimitiveRecipeIngredient = {
  itemId: string;
  itemType: string;
  itemDescription: string;
};

export type ResolvedEditRecipeResources = {
  initialState: {
    recipeEditStore: RecipeEditStore;
    recipeState: RecipeState;
    formControl: RecipeEditFormControl;
  };
};


@Component({
  selector: 'app-create-recipe2',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
})
export class EditRecipeV2Component
  implements OnInit, ComponentIsSaving, OnDestroy, AfterViewChecked {
  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT)
    public appCtx: IAppContext,
    private dialog: MatDialog,
    public store: Store<AppState>,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private messageService: MessageService,
    private loadingSpinner: LoadingSpinnerOverlayService,
    @Inject(RECIPE_PROFIT_CALCULATOR_CONFIG) public recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
    @Inject(WINDOW) public window: Window,
    @Inject(APPLICATION_USER_ROLE) public applicationUserRole : ApplicationUserRole,
    public actionSubject$ : ActionsSubject

  ) {
    registerLocaleData(locale_fr_CA);
  }

  initialStateMatcher = new RecipeErrorStateMatcher();
  editedStateMatcher =  new RecipeinitialStateErrorMatcher();
  selectedBatchYieldUnit = null;
  batchCostUnit = null;
  vm$: BehaviorSubject<ManageRecipeVM> = new BehaviorSubject(null);
  destroy$ = new Subject<void>();
  calculationInProgress$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @ViewChild('recipeNameInput') recipeNameInput: ElementRef;
  @ViewChild('categorySelect') categoryInput;

  editingTitle = false;
  container: RecipeEditStore;
  form$ = new BehaviorSubject<UntypedFormGroup>(null);
  notifyViewChildReady$ = new Subject<void>();
  formControl$ = new BehaviorSubject<RecipeEditFormControl>(null);
  saveInProgress$ = new BehaviorSubject<UntypedFormGroup>(null);
  formErrors$ = new BehaviorSubject<any>(null);
  prepInstructionsEnterPressed$ = new Subject<void>();
  firstLoadComplete$ = new BehaviorSubject<boolean>(false);
  photoManagerVM$: BehaviorSubject<PhotoListItemViewModel[]> = new BehaviorSubject<PhotoListItemViewModel[]>([]);
  submitSubject = new Subject<RecipeState>();
  allowSave$ = new BehaviorSubject<boolean>(false);
  allowSaveFlagWithDelay$ = this.allowSave$.pipe(
    concatMap(f =>
      iif(
        () => f,
        of(f).pipe(delay(500)),
        of(f)
      )),
  );
  categories = this.vm$.pipe(map(r => r.recipeCategories));

  public ingredientDropSubject = new Subject<CdkDragDrop<RecipeIngredient>>();

  checkForDecimalsAndFractions = checkForDecimalsAndFractionsInputGateFn;
  isNavigationEvent = isNavigationEventFn;

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewChecked(): void {
    if (this.recipeNameInput) {
      this.notifyViewChildReady$.next();
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  identify(_idx, item) { return item.value.itemId; }

  getSavingNotifier() {
    return {
      saveInProgressNotifier$: this.container.createVM$().pipe(map(g => g.isSaving)),
      onSaveComplete: this.container.createVM$().pipe(map(g => g.isSaveComplete))
    };
  }

  async ngOnInit(): Promise<UntypedFormGroup> {
    return this.activatedRoute.data.pipe(
      first(),
      map((resolve: ResolvedEditRecipeResources) => {
        this.container = resolve.initialState.recipeEditStore;
        this.formControl$.next(resolve.initialState.formControl);

        const r = this.initFormEffects(resolve.initialState.formControl)
          .pipe(
            takeUntil(resolve.initialState.formControl.destroy$)
          ).subscribe();

        return resolve.initialState.formControl;
      }),
      pushToSubject(this.formControl$),
      map((formControl) => formControl.f),
      pushToSubject(this.form$),
    ).toPromise();
  }

  private initFormEffects(formControl: RecipeEditFormControl) {
    return combineLatest([
      this.createBehaviorRecipePricingCalculation$(formControl),
      this.createBehaviorSelectedBatchQuantityUOMLocalization$(),
      this.createBehaviorCostPerBatchUnitLabelResolution$(formControl),
      this.createBehaviorFormSubmission$(this.submitSubject),
      this.createBehaviorAutoAppendNewLineToPrepInstructions$(this.prepInstructionsEnterPressed$),
      this.createBehaviorPreventDataLossOnNavigation$(),
      this.createBehaviorCleanupComponentOnDestroy$(formControl, this.destroy$),
      this.createBehaviorAllowSave$(this.allowSave$),
      this.createBehaviorVMUpdate$(this.container.createVM$()),
      this.createBehaviorStopSpinnerOnFirstLoad$(this.firstLoadComplete$),
      this.createEffectIngredientResequence$(this.ingredientDropSubject),
      this.createNotifierCalculationProgress$(formControl, this.calculationInProgress$),
      this.createBehaviorSelectNameInputOnNewRecipe$(this.vm$, this.notifyViewChildReady$, () => this.recipeNameInput)
    ]);
  }

  //#region ui effects
  private createBehaviorSelectNameInputOnNewRecipe$(
    recipeVm$: Subject<ManageRecipeVM>,
    notifier,
    getElementRef: () => ElementRef
  ) {
    return combineLatest([
      recipeVm$,
      notifier.pipe(first())
    ])
      .pipe(
        filter(([recipeVm]) => getElementRef() && !recipeVm?.recipe?.id),
        tap(() => { getElementRef().nativeElement.select(); }),
        first(),
      );
  }

  private createBehaviorCleanupComponentOnDestroy$(
    formControl: RecipeEditFormControl,
    onDestroy$: Observable<void>
  ): Observable<void> {
    return onDestroy$.pipe(tap(() => { formControl.f.dispose(); }));
  }

  private createBehaviorVMUpdate$(vmSubject$: Observable<any>) {
    return vmSubject$
      .pipe(
        debounceTime(recipeFormControlFeelConfig.generalUIDebounce),
        pushToSubject(this.vm$)
      );
  }
  private createBehaviorStopSpinnerOnFirstLoad$(notifier: Subject<boolean>) {
    return notifier.pipe(
      isTruthy(),
      distinctUntilChanged(),
      tap(() => { this.loadingSpinner.hide(); })
    );
  }
  private createBehaviorAutoAppendNewLineToPrepInstructions$(s: Subject<void>) {
    return s.pipe(
      withLatestFrom(this.form$),
      tap(([_, { value, controls }]) => {
        value.prepInstructions += '\r\n';
        controls.prepInstructions.setValue(value.prepInstructions);
      })
    );

  }
  private createBehaviorPreventDataLossOnNavigation$() {
    return this.router.events
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.form$),
        filter(([value, { dirty }]) =>
          this.isNavigationEvent(value)
        ),
        filter(([value, { dirty }]) =>
          dirty
        ),
        filter(([value, { dirty }]) =>
          !confirm(this.translate.instant('RECIPE.CREATE.CANCEL_ALERT'))
        ),
        tap(() => { this.router.navigateByUrl(this.router.url); })
      );
  }

  private createBehaviorAllowSave$($allowSaveSubject: BehaviorSubject<boolean>) {
    return this.form$.pipe(
      isTruthy(),
      concatMap(form => {
        return combineLatest([
          form.valueChanges
            .pipe(
              startWith(form.value),
              map(() => form.dirty || form.controls.ingredients.dirty || form.controls.images.dirty),
              distinctUntilChanged(),
            ),
          form.valueChanges
            .pipe(
              startWith(form.value),
              map(({ calculationState }) => [
                CalculationState.CalculationInProgress,
                CalculationState.CalculationPending,
              ].indexOf(calculationState) === -1),
              distinctUntilChanged(),
              tap(r => { console.log('calculation pending or in progress', r); })
            ),
          form.statusChanges.pipe(
            startWith(form.status),
            distinctUntilChanged(),
            map(() => form.valid),
          )
        ]).pipe(
          map(g => g.indexOf(false) === -1),
          distinctUntilChanged(),
        );
      })).pipe(pushToSubject($allowSaveSubject));
  }

  // @note Recipe Ingredient Resequencing
  private createEffectIngredientResequence$(dropSubject: Subject<CdkDragDrop<RecipeIngredient>>) {
    return dropSubject
      .pipe(
        filter((drop: CdkDragDrop<RecipeIngredient>) => drop.previousIndex !== drop.currentIndex),
        withLatestFrom(this.form$),
        tap(([drop, form]: [CdkDragDrop<RecipeIngredient>, UntypedFormGroup]) => {
          const ingredients = [...form.value.ingredients];
          moveItemInArray(ingredients, drop.previousIndex, drop.currentIndex);
          form.patchValue({ ingredients }, { emitEvent: false });
          form.markAsDirty();
          form.updateValueAndValidity();
        })
      );
  }

  private createBehaviorFormSubmission$($submitSubject: Subject<RecipeState>): Observable<any> {
    return $submitSubject.pipe(
      withLatestFrom(this.allowSave$),
      filter(([, canSubmit]) => canSubmit),
      tap(() => { this.loadingSpinner.show(); }),
      debounceTime(recipeFormControlFeelConfig.generalUIDebounce),
      concatMap(([value]) => {

        return forkJoin([of(value), this.container.updateRecipe$(value)]);
      }),
      withLatestFrom(this.form$),
      tap(([[state, saveResult], form]) => {
        if (!state.id) {
          form.disable();
          form.reset(form.value);
          this.router.navigateByUrl(`/recipe/${saveResult.updatedRecipeId}`);
        } else {
          form.reset(form.value);
          this.loadingSpinner.hide();
        }
      }),
      catchError(err => { throw err; }),
    );
  }

  private createBehaviorRecipePricingCalculation$(formControl: RecipeEditFormControl) {
    return formControl.onRecalculateRecipePrice$
      .pipe(
        takeUntil(formControl.destroy$),
        notifySubject(formControl.notifyCalculationInProgress$),
        switchMap((priceRequest) => this.container.recalculatePricing$(priceRequest)),
        pushToSubject(formControl.notifyCalculationCompleted$),
      );
  }

  private createNotifierCalculationProgress$(
    formControl: RecipeEditFormControl,
    bs: BehaviorSubject<boolean>
  ) {
    return formControl.f.controls.calculationState.valueChanges
      .pipe(
        debounceTime(recipeFormControlFeelConfig.generalUIDebounce),
        map(calculationState => {
          console.log(calculationState);
          return [
            CalculationState.CalculationInProgress,
          ].indexOf(calculationState) > -1;
        }),
        distinctUntilChanged(),
        pushToSubject(bs),
      );
  }

  private createBehaviorCostPerBatchUnitLabelResolution$(
    recipeFormControl: RecipeEditFormControl
  ): Observable<string> {
    return this.vm$.pipe(
      isTruthy(),
      concatMap(() =>
        recipeFormControl.f.controls.batchYieldUnit.valueChanges
          .pipe(startWith(recipeFormControl.f.value.batchYieldUnit ?? ''))
      ),
      debounceTime(500),
    )
      .pipe(
        withLatestFrom(this.vm$),
        map(([yieldUnit, { measurementUnitsLookup }]) => ({
          type: recipeFormControl.f.value.type,
          batchYieldUnitOption: measurementUnitsLookup[yieldUnit]
        })),
        map((value) => {
          const { type, batchYieldUnitOption: batchYieldUnitText } = value;
          const unitText = (caseInsensitiveEquals(type, 'BATCHRECIPE')
            ? batchYieldUnitText?.name.trim()
            : '') ?? '';

          return unitText;
        }),
        filter(r => r.length > 0),
        tap(unitText => {
          console.log('updated batch yield unit display text', unitText);
          this.batchCostUnit = unitText;
        })
      );
  }

  async setBatchYieldUnit(key: string) {

    await this.form$.pipe(
      first(),
      tap((f) => {
        f.controls.batchYieldUnit.markAsDirty();
        f.controls.batchYieldUnit.markAsTouched();
        f.controls.batchYieldUnit.setValue(key);
      })
    ).toPromise();
  }
  //#endregion

  //#region FORMATTERS

  /**
   * truncate recipe title if too long
   *
   * @param title recipeForm.value.name
   * @returns recipe title truncated with ellipsis if too long
   */
  formatRecipeTitle(title: string): string {
    if (title.length > 34) {
      return `${title.slice(0, 34)}...`;
    }
    return title;
  }

  //#endregion

  //#region USER ACTIONS

  /**
   * user clicked '< Back' button
   */
  back(): void {
    if(this.applicationUserRole === ApplicationUserRole.Employee){
    this.router.navigateByUrl(Constants.RecipeFeaturePaths
      .RECIPE_PROFIT_CALCULATOR[ApplicationUserRole.Employee]
      .RECIPE_PROFIT_CALCULATOR_PATH,);
    } else {
      this.router.navigateByUrl('/category');
    }
  }

  // @note User Action: Open Create Category Dialog
  openCreateCategoryModal(): Promise<any> {
    console.log('test');
    return this.vm$
      .pipe(
        first(),
        tap(r => { console.log('test'); }),
        map(s => s.recipeCategories),
        concatMap(cats =>
          this.dialog.open(CategoryCreateModalComponent, {
            data: {
              categories: cats,
              categoryName: '',
            },
            panelClass: 'recipe-create-category-dialog',
          }).afterClosed()
        ),
        concatMap((categoryName: string) => {
          return iif(() => !!categoryName,
            this.container.createNewRecipeCategory$(categoryName),
            EMPTY);
        }),
        first(),
        withLatestFrom(this.form$),
        tap(([newCategory, recipeForm]) => {
          this.selectNewlyCreatedCategory(recipeForm, newCategory);
        }),
        catchError(() => EMPTY),
      ).toPromise();
  }

  private selectNewlyCreatedCategory(recipeForm: UntypedFormGroup, newCategory: RecipeCategory) {
    recipeForm.patchValue({ categoryId: newCategory.id });
    this.categoryInput.close();
  }

  // @note User Action: Open Item Configuration Dialog
   openItemConfigurationModal(ingredientState: RecipeIngredientState) {
    const weakItemReference = { type: ingredientState.itemType, key: ingredientState.itemId };
    this.container.getItemData$(weakItemReference, { useDisplayItem: true })
      .pipe(
        tap(() => {
          this.store.dispatch(new GetSuppliersAttempt());
          this.store.dispatch(new GetAllCustomItemsAttempt());
          this.store.dispatch(new GetAllGeneralItemsAttempt());
          this.store.dispatch(new GetMeasurementUnitsAttempt());
          this.store.dispatch(new GetAllCustomItemDataAttempt());
        }),
        concatMap(resolvedItem =>
          this.dialog.open(EditItemDialogComponent, {
            data: {
              item: resolvedItem,
              isEdit: true,
              focusSection: 'unit-of-measure-panel',
            },
            width: '840px',
            autoFocus: false,
            panelClass: 'inventory-edit-item-dialog',
          })
          .afterClosed()
          ),
        first(),
        concatMap(() => {
         return this.actionSubject$.pipe(ofType(ActionTypes.UpdateCustomItemDataSuccess))
          .pipe(
            timeout({
              each: 5000,
              with: () => this.container.refreshItemDataByItemReference$(weakItemReference)
            }),
            concatMap(()=>{
               console.log('refreshing item data with reference:', weakItemReference);
              return this.container.refreshItemDataByItemReference$(weakItemReference);
            })
            )
        }),
        tapLog('item refresh completed'),
        takeUntil(this.destroy$),
      ).subscribe()
  }

  // @note User Action: New Ingredient Selected
  onNewIngredientSelected(
    newIngredient: PrimitiveRecipeIngredient
  ) {
    this.onNewIngredientSelectedFn(newIngredient)
      .subscribe();
  }

  private onNewIngredientSelectedFn(
    newIngredient: PrimitiveRecipeIngredient
  ) {
    console.log('adding ingredient', newIngredient);
    return this.container.createNewIngredient$({
      key: newIngredient.itemId,
      type: newIngredient.itemType
    } as ItemReference)
      .pipe(
        withLatestFrom(this.formControl$),
        tap(r => { console.log(''); }),
        tap(([[, ingredientState], form]) => {
          form.addNewIngredient$.next(ingredientState);
          this.notifyNewIngredientAddedToRecipe(newIngredient);
        }),
      );
  }

  getPrintableRecipeVmBuilder$(
    isNavigatingFromCalculator: boolean,
    recipeState: RecipeState
  ): Observable<PrintableRecipeVM> {
    return isNavigatingFromCalculator
      ? this.container.buildPrintableRecipeVMFromRecipeState$(recipeState)
      : this.container.createPrintableRecipe$(recipeState.id , this.batchCostUnit);
  }

  // @note User Action: Print Recipe
  async printCurrentRecipe$(): Promise<any> {
    return this.form$
      .pipe(
        first(),
        concatMap(formGroup => {
          const isNavigatingFromCalculator = this.recipeProfitCalculatorConfig.isNavigatingFromCalculator(this.window);
          const recipeState: RecipeState = formGroup.value;
          const vm$ = this.getPrintableRecipeVmBuilder$(isNavigatingFromCalculator, recipeState);
          return firstValueFrom(
            this.dialog.open(PrintRecipeDialogComponent, {
              data: { vm$ },
              maxWidth: '100vw',
              maxHeight: '100vh',
              height: '100%',
              width: '100%',
            }).afterClosed()
          );
        }),
      ).toPromise();
  }

  // @note open static help dialog
  openBatchYieldHelpModal(): void {
    this.dialog.open(BatchYieldHelpDialogComponent, {
      width: '840px',
      panelClass: 'dialog-with-header',
    });
  }

  // @note confirm removal of ingredient by its key
  async openRemoveIngredientConfirmModal(removeIdx: number) {
    await this.dialog
      .open(RecipeCancelConfirmComponent, {
        panelClass: ['dialog-with-header', 'dialog-width-500'],
        data: {
          message: 'RECIPE.CREATE.CONFIRM_REMOVE_INGREDIENT',
          confirmation: 'SHARED.YES',
          cancellation: 'SHARED.CANCEL',
        },
      })
      .beforeClosed()
      .pipe(
        filter((isConfirmed) => isConfirmed),
        withLatestFrom(this.formControl$),
        tap(([, formControl]) => {
          formControl.tryRemoveIngredientAtIndex$.next(removeIdx);
        }),
      ).toPromise();
  }
  //#endregion

  //#region NOTIFICATION

  private notifyNewIngredientAddedToRecipe(newIngredient: PrimitiveRecipeIngredient): void {
    return this.messageService.queue(
      this.translate.instant('RECIPE.CREATE.INGREDIENT_ADDED', {
        value: newIngredient.itemDescription,
      }));
  }

  //#endregion

  //#region Measurement Unit UI Kit
  getOptionDisplayText(option: { name: string }): string {
    return option.name.trim();
  }

  public isUnitGroupForItemType(recipeType, unitGroupType) {
    const a = unitGr[recipeType];
    const b = a.indexOf(unitGroupType);
    return b > -1;
  }

  private createBehaviorSelectedBatchQuantityUOMLocalization$() {
    return this.vm$.pipe(
      isTruthy(),
      map(vm => vm.measurementUnitsLookup),
      takeUntil(this.container.destroy$),
      withLatestFrom(this.form$),
      filter(([, form]) => !!form.value.batchYieldUnit),
      tap(([batchYieldUnitLookup, form]) => {
        this.selectedBatchYieldUnit = batchYieldUnitLookup[form.value.batchYieldUnit];
      }));
  }
  //#endregion
}






function isNavigationEventFn(value) {
  return value instanceof NavigationStart &&
    { imperative: true, popstate: true }[value.navigationTrigger];
}
