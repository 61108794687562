import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Recipe } from '@gfs/shared-models';
@Component({
  selector: 'app-category-recipe-card-mobile',
  templateUrl: './category-recipe-card-mobile.component.html',
  styleUrls: ['./category-recipe-card-mobile.component.scss'],
})
export class CategoryRecipeCardMobileComponent {
  @Input() recipe: Recipe;
  @Input() isMobile: boolean;
  @Output()
  deleteRecipeClicked: EventEmitter<Recipe> = new EventEmitter<Recipe>();
}
