<ng-container *ngIf="(form$ | async) as form">
  <ng-container *ngIf="(vm$ | async) as vm">
    {{firstLoadComplete$.next(true)}}
    <div class="new-recipe-container no-print">
      <div class="new-recipe-back-container">
        <span>
          <span class="breadcrumb-back-tick">&lt;</span>
          <a class="back-button " type="button" (click)="back()">{{ 'RECIPE.BACK_BUTTON' | translate }}</a>
        </span>
        <div class="print-functionality">
          <a mat-button
          class="print-button"  [disabled]="(form$ | async).value.id === null ? true : false" (click)="printCurrentRecipe$()"
          attr.aria-label="{{ 'RECIPE.CREATE.PRINT' | translate }}">
          <div class="print-container">
            <mat-icon class="icon print-icon" svgIcon="print"></mat-icon>
            <div>{{ 'RECIPE.CREATE.PRINT' | translate }}</div>
          </div>
        </a>
        <div *ngIf="(form$ | async).value.id === null ? true : false" class="tooltiptext">
          <mat-icon matSuffix class="warning-icon" svgIcon="warning-icon">
          </mat-icon>
          <div>{{ 'RECIPE.CREATE.DISABLED_PRINT' | translate }}</div>
        </div>
        </div>
        
    
      </div>
      <mat-divider></mat-divider>
      
      <form [formGroup]="form" (ngSubmit)="submitSubject.next(form.value)" (keydown.enter)="(false)">
        <div>
          <mat-form-field class="recipe-title-field">
            <mat-label>{{'RECIPE.CREATE.RECIPE_NAME' | translate}}</mat-label>
            <input #recipeNameInput *ngIf="form.controls['name']?.touched" id="recipe-name-input1" matInput type="text" [errorStateMatcher]="editedStateMatcher" class="recipe-title"
              formControlName="name" maxlength="200" autocomplete="off" />
              <input #recipeNameInput *ngIf="!(form.controls['name']?.touched)" id="recipe-name-input2" matInput type="text" [errorStateMatcher]="initialStateMatcher" class="recipe-title"
              formControlName="name" maxlength="200" autocomplete="off" />
            <mat-error *ngIf="form.get('name').hasError('recipeExists')">{{
              'RECIPE.CREATE.RECIPE_ALREADY_EXISTS_ERROR' | translate
              }}</mat-error>
            <mat-error *ngIf="form.get('name').hasError('valueNotModified')">{{
              'RECIPE.CREATE.RECIPE_ALREADY_EXISTS_ERROR' | translate
              }}</mat-error>
            <mat-error *ngIf="form.get('name').hasError('required')">{{
              'RECIPE.CREATE.RECIPE_IS_REQUIRED_ERROR' | translate
              }}</mat-error>

          </mat-form-field>
          <mat-icon class="pencil-icon">edit</mat-icon>
        </div>

        <div style="max-width: 817px;">
          <mat-accordion id="category-recipe-accordian" multi="true" [togglePosition]="'before'">
            <!-- Recipe Details -->
            <mat-expansion-panel class="darkpanel" [expanded]="true">
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title tabindex="-1">
                  {{ 'RECIPE.CREATE.RECIPE_DETAILS' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="new-recipe-info-panel">
                <div class="new-recipe-input">
                  <mat-radio-group class="recipe-type-radio-group" id="recipe-type-radio" attr.aria-label="{{
                  'RECIPE.CREATE.RECIPE_TYPE_ARIA_LABEL' | translate
                }}" #radioGroup="matRadioGroup" formControlName="type">
                    <mat-radio-button id="menuItemRadio" value="MENUITEMRECIPE">
                      <mat-icon class="icon" svgIcon="menu-item"></mat-icon>
                      {{ 'RECIPE.CREATE.MENU_ITEM' | translate }}
                    </mat-radio-button>
                    <mat-radio-button id="batchRadio" value="BATCHRECIPE">
                      <mat-icon class="icon" svgIcon="batch-recipe"></mat-icon>
                      {{ 'RECIPE.CREATE.BATCH_RECIPE' | translate }}
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div class="recipe-type-help-text">
                  <span *ngIf="radioGroup.value === 'BATCHRECIPE'">
                    {{ 'RECIPE.CREATE.BATCH_RECIPE_HELP_TEXT' | translate }}
                  </span>
                  <span *ngIf="radioGroup.value === 'MENUITEMRECIPE'">
                    {{ 'RECIPE.CREATE.MENU_ITEM_HELP_TEXT' | translate }}
                  </span>
                </div>
                <div class="new-recipe-input">
                  <mat-form-field>
                    <mat-label>{{
                      'RECIPE.CREATE.SELECT_CATEGORY' | translate
                      }}</mat-label>
                    <mat-select #categorySelect formControlName="categoryId" id="categorySelect">
                      <button id="newCategoryOption" mat-menu-item class="new-category-option category-select-button"
                        (click)="openCreateCategoryModal()" attr.aria-label="{{
                      'RECIPE.CREATE.NEW_CATEGORY_ARIA_LABEL' | translate
                    }}" tabindex="0">
                        <div class="new-category-label">
                          <mat-icon class="icon category-plus-icon" svgIcon="plus-icon"></mat-icon>
                          <span>
                            {{ 'RECIPE.CREATE.NEW_CATEGORY' | translate }}
                          </span>
                        </div>
                      </button>
                      <mat-option *ngFor="let category of vm.recipeCategories" [value]="category.id">
                        {{ category.name | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>

            <!-- Batch Yield -->
            <mat-expansion-panel class="darkpanel" [expanded]="true" *ngIf="form.value.type === 'BATCHRECIPE'">
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title tabindex="-1">
                  {{ 'RECIPE.CREATE.BATCH_YIELD' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="new-recipe-info-panel">
                <h2 class="batch-yield-heading">
                  {{ 'RECIPE.CREATE.HOW_MUCH_DOES_RECIPE_YIELD' | translate }}
                  <span class="info-container">
                    <mat-icon class="info-container" [inline]="true" svgIcon="info_icon"></mat-icon>
                    <span class="info-overlay">
                      <mat-icon [inline]="true" svgIcon="info_icon_hover" (click)="openBatchYieldHelpModal()">
                      </mat-icon>
                    </span>
                  </span>
                </h2>
                <div class="new-recipe-input">
                  <div class="new-recipe-batch-info-inputs">
                    <div class="batch-yield-input">
                      <input class="number-input" id="batchYield" #batchYield type="text"
                        formControlName="batchYieldQty" (keypress)="
                      checkForDecimalsAndFractions(
                        $event,
                        form.controls.batchYieldQty.value
                      )
                    " (paste)="validateMeasurementUnitQuantity($event)" />
                    </div>
                    <ng-container *ngIf="!!vm.measurementUnits">
                      <mat-form-field>
                        <mat-label>{{
                          'RECIPE.CREATE.SELECT_UNIT_OF_MEASURE' | translate
                          }}</mat-label>

                        <input #batchYieldUnits type="text" matInput [matAutocomplete]="auto"
                          id="batch-yield-units-input" [value]="selectedBatchYieldUnit?.name" />
                        <mat-icon matSuffix class="mat-autocomplete-arrow" svgIcon="arrow-icon"></mat-icon>
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionDisplayText"
                          (optionSelected)="setBatchYieldUnit($event.option.value.key)">
                          <ng-container *ngFor="let unitGroup of vm.measurementUnits">
                            <mat-optgroup
                              *ngIf="isUnitGroupForItemType(form.value.type, unitGroup.groupType, vm.measurementUnits)"
                              label="{{
                                unitGroup.name | translate | titlecase
                              }}">
                              <mat-option *ngFor="let option of unitGroup.options" [value]="option">
                                {{option.name}}
                              </mat-option>
                            </mat-optgroup>
                          </ng-container>

                        </mat-autocomplete>


                      </mat-form-field>
                    </ng-container>
                  </div>
                  <mat-error *ngIf="form.get('batchYieldUnit').hasError('required')">
                    {{ 'RECIPE.CREATE.BATCH_YIELD_UNITS_IS_REQUIRED_ERROR'| translate }}
                  </mat-error>
                </div>
              </div>
            </mat-expansion-panel>

            <!-- Ingredients -->
            <mat-expansion-panel class="darkpanel" [expanded]="true" style="overflow: visible;">

              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title tabindex="-1">
                  {{ 'RECIPE.CREATE.INGREDIENTS' | translate }}
                </mat-panel-title>
              </mat-expansion-panel-header>

              <div class="new-recipe-info-panel">
                <div class="add-ingredient-container ingredient-form" cdkDropList
                  (cdkDropListDropped)="ingredientDropSubject.next($event)">

                  <!-- Includes quick-add region -->
                  <div *ngFor="let ingredientForm of form.controls.ingredients.controls;
                  index as uiIndex;
                  trackBy: identify" cdkDrag>

                    <div class="card-container">
                      <div cdkDragHandle>
                        <img src="assets/images/category_draghandle_gray.svg"
                          alt="{{ 'CATEGORY.CATEGORY_LIST.DRAG_AND_DROP_ALT' | translate }}" />
                      </div>

                      <app-edit-ingredient-card-v2 [ingredientForm]="ingredientForm" [uiIndex]="uiIndex"
                        (removeIngredient)="openRemoveIngredientConfirmModal(uiIndex)"
                        (openItemEditor)="openItemConfigurationModal(ingredientForm.value)"
                        enableLaunchItemEditor="true">
                      </app-edit-ingredient-card-v2>
                    </div>
                  </div>
                </div>

                <gfs-quick-add-items (addEvent)="onNewIngredientSelected($event)">
                </gfs-quick-add-items>
              </div>
            </mat-expansion-panel>
            <gfs-loading-overlay [hidden]="calculationInProgress$"></gfs-loading-overlay>

            <!-- Pricing Calculator -->
            <app-recipe-pricing-panel [recipeType]="form.controls.type.value" [parentForm]="form"
              [totalFoodCost]="form.value.recipeTotalCost" [pricePerPortion]="form.value.recipeBatchPortionCost"
              [pricePerPortionUnit]="batchCostUnit" [isPriceCalcuationInProgress$]="calculationInProgress$">
            </app-recipe-pricing-panel>

            <!-- Recipe And Prep Details -->
            <mat-expansion-panel class="darkpanel" [expanded]="true" style="overflow: visible;">
              <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
                <mat-panel-title tabindex="-1">
                  {{
                  'RECIPE.CREATE.PREP_DETAILS.RECIPE_AND_PREP_DETAILS' | translate
                  }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="new-recipe-info-panel">
                <div class="cook-time-prep-time-container">
                  <div>
                    <div style="display: flex;">
                      <h4>{{ 'RECIPE.CREATE.PREP_DETAILS.COOK_TIME' | translate }}</h4>
                    </div>
                    <div style="display: flex;">
                      <div class="new-recipe-input">
                        <mat-form-field>
                          <mat-label>{{ 'RECIPE.VOCABULARY.HOURS' | translate }}</mat-label>
                          <input type="number" matInput formControlName="cookTimeHours" placeholder="HH" min="0" />
                        </mat-form-field>
                      </div>
                      <div class="new-recipe-input">
                        <mat-form-field>
                          <mat-label>{{ 'RECIPE.VOCABULARY.MINUTES' | translate }}</mat-label>
                          <input type="number" matInput formControlName="cookTimeMinutes" placeholder="MM" min="0" />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style="display: flex;">
                      <h4>{{ 'RECIPE.CREATE.PREP_DETAILS.PREP_TIME' | translate }}</h4>
                    </div>
                    <div style="display: flex;">
                      <div class="new-recipe-input">
                        <mat-form-field>
                          <mat-label>{{ 'RECIPE.VOCABULARY.HOURS' | translate }}</mat-label>
                          <input type="number" matInput formControlName="prepTimeHours" placeholder="HH" min="0" />
                        </mat-form-field>
                      </div>
                      <div class="new-recipe-input">
                        <mat-form-field>
                          <mat-label>{{ 'RECIPE.VOCABULARY.MINUTES' | translate }}</mat-label>
                          <input type="number" matInput formControlName="prepTimeMinutes" placeholder="MM" min="0" />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="new-recipe-input">
                  <mat-form-field appearance="outline">
                    <textarea matInput rows="5" cols="50" formControlName="prepInstructions"
                      (keyup.enter)="prepInstructionsEnterPressed$.next()" [placeholder]="
                    'RECIPE.CREATE.PREP_DETAILS.ADD_PREPARATION_INSTRUCTIONS_AND_RECIPE_DETAILS'
                      | translate
                  "></textarea>
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
            <app-recipe-image-manager [imagesForm]="this.form.controls.images" [vm$]="photoManagerVM$">
            </app-recipe-image-manager>
          </mat-accordion>

          <app-save-bar [targetFormGroup]="form" [allowSave]="this.allowSaveFlagWithDelay$ | async"
            (ingredientAdded)="onNewIngredientSelected($event)" (backClicked)="back()"></app-save-bar>
        </div>
      </form>


    </div>
  </ng-container>
</ng-container>
