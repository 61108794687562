<mat-form-field class="category-select-container" floatLabel="never">
  <mat-label>{{ 'CATEGORY.JUMP_TO_CATEGORY_LABEL' | translate }}</mat-label>
  <mat-select panelClass="category-select-panel">
    <mat-option class="new-category-option">
      <div class='new-category-option'>{{ 'CATEGORY.JUMP_TO_CATEGORY_LABEL' | translate }}</div>
    </mat-option>
    <mat-option class="new-category-option category-select-button" (click)='onEditCategory("")'>
      <div class='new-category-label' attr.aria-label="{{ 'CATEGORY.NEW_CATEGORY_ARIA_LABEL' | translate }}">
        <mat-icon class="icon category-plus-icon" svgIcon="plus-icon"></mat-icon>{{ 'CATEGORY.NEW_CATEGORY' | translate }}
      </div>
    </mat-option>
    <mat-option
            class="new-category-option category-select-button"
            *ngIf="hasRecipes(null)"
            (click)='categorySelected({})'>{{ CATEGORY.UNASSIGNED_CATEGORY_LABEL | translate: {'value': getRecipes(null).length} }}
    </mat-option>
    <mat-option
            class="new-category-option category-select-button"
            *ngFor="let category of (categories$ | async)"
            (click)='categorySelected(category)'>{{category.name}} ({{getRecipes(category.id).length}})
    </mat-option>
  </mat-select>
</mat-form-field>