import { Injectable } from '@angular/core';
import { Recipe } from '@gfs/shared-models';

@Injectable({
  providedIn: 'root'
})
export class CategoryUtilsService {

  hasRecipes(categoryId: string, recipes: Recipe[]) {
    if (recipes) {
      return recipes.some(r => {
        return r.categoryId === categoryId;
      });
    }
    return false;
  }

  getRecipes(categoryId: string, recipes: Recipe[]) {
    if (recipes) {
      return recipes.filter(r => {
        return r.categoryId === categoryId;
      });
    }
    return [];
  }

  /**
   * take in recipe category name and return the
   * hard-coded translation if it exists
   *
   * @param name recipe category name
   */
  getTranslation(name: string) {
    switch (name.toLowerCase()) {
      case 'unassigned':
      case 'non-assigné':
        return 'CATEGORY.UNASSIGNED';
      case 'appetizers':
      case 'entrées':
        return 'CATEGORY.APPETIZERS';
      case 'entrees':
      case 'plats principaux':
        return 'CATEGORY.ENTREES';
      case 'batch':
      case 'préparation':
        return 'CATEGORY.BATCH';
    }
    return name;
  }


}
