import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationModalComponent } from '@gfs/shared-components';
import { IAppContext } from '@gfs/shared-models';
import { AppConfigService, InjectionTokens, MessageService } from '@gfs/shared-services';
import { hasElements, pushToSubject } from '@gfs/shared-services/extensions/rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FileAddedEvent, ImageRemovedEvent, PhotoListItemViewModel, RecipeImageUploadConfig, ReorderImageEvent } from '@recipe-ui/ui/photo-manager/photo-manager.model';
import { RecipeImageUploadBase64 } from '@recipe-ui/ui/photo-manager/services/recipe-image-upload';
import { EntityState, RecipeImageState } from '@recipe-ui/v2/edit/edit.component.store';
import { recipeFormControlFeelConfig } from '@recipe-ui/v2/edit/form-factory.service';
import { combineLatest, EMPTY, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, filter, first, map, skip, startWith, takeUntil, tap, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-recipe-image-manager',
  templateUrl: './recipe-image-manager.component.html'
})
export class RecipeImageManagerComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  @Input()
  vm$: Subject<PhotoListItemViewModel[]>;

  @Input()
  imagesForm: UntypedFormArray;

  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT)
    public appCtx: IAppContext,
    private imageUploadService: RecipeImageUploadBase64,
    private configService: AppConfigService,
    public recipeUploadConfig: RecipeImageUploadConfig,
    private dialog: MatDialog,
    private translate: TranslateService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.initComponentEffects().subscribe();
  }

  private initComponentEffects() {
    return combineLatest([
      (this.imagesForm.valueChanges as Observable<RecipeImageState[]>)
        .pipe(
          startWith(this.imagesForm.value as RecipeImageState[]),
          hasElements(),
          map(collection => [...collection].sort((x, y) => x.ordinal - y.ordinal)),
          concatMap((images: RecipeImageState[]) => from(images).pipe(
            map(g => ({
              name: `Image ${g.ordinal + 1}`,
              ordinal: g.ordinal,
              index: g.ordinal,
              content: g.content,
              key: g.key,
              maxIndex: recipeFormControlFeelConfig.maxImageCount - 1,
            })),
            map(image => new PhotoListItemViewModel(image)),
            toArray()
          )),
          pushToSubject(this.vm$),
          first()
        ),

      this.vm$.pipe(
        skip(1),
        concatMap(g => from(g)
          .pipe(
            map(x => this.mapToRecipeImage(x)),
            toArray()
          )
        ),
        tap(g => {
          this.imagesForm.markAsDirty();
          this.imagesForm.setValue(g);
        }),
        catchError(() => EMPTY)
      )
    ]).pipe(
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  imageSelectedHandler = async ($event: FileAddedEvent): Promise<any> =>
    forkJoin([
      of($event),
      of(this.imageUploadService.validateFile($event.file)),
      this.imageUploadService.getBase64Content($event.file),
      this.vm$.pipe(first()),
      of(this.vm$)
    ]).
      pipe(
        filter(([, isValid, , ,]) => isValid),
        concatMap(([selectEvent, , base64Content, vmState, setVmState]) =>
          this.imageUploadService.cropImage(base64Content)
            .pipe(
              map(cropped => ({
                selectEvent,
                base64Content: cropped,
                vmState,
                setVmState
              })))),
        tap(({
          selectEvent,
          base64Content,
          vmState,
          setVmState
        }) => {
          const elementVm = vmState[selectEvent.imageIndex];
          elementVm.data.file = selectEvent.file;
          elementVm.updateContent(base64Content);

          setVmState.next(vmState);
        })).toPromise();

  imageMovedHandler = async ($event: ReorderImageEvent): Promise<any> =>
    forkJoin([of($event), this.vm$.pipe(first()), of(this.vm$)])
      .pipe(
        first(),
        tap(([moveEvent, vm, setVmState]) => {
          const newIdx = moveEvent.imageIndex + moveEvent.shiftIndex;

          if (!isValidMove(newIdx, vm.length)) {
            return;
          }

          vm[moveEvent.imageIndex].data.index = newIdx;
          vm[newIdx].data.index = moveEvent.imageIndex;
          vm = swap(vm, moveEvent.imageIndex, newIdx);

          setVmState.next(vm);
        })).toPromise();

  imageRemovedHandler = async ($event: ImageRemovedEvent): Promise<any> =>
    forkJoin([of($event), this.vm$.pipe(first()), of(this.vm$)])
      .pipe(
        first(),
        concatMap(([removeEvent, vm, setVmState]) => this.confirmationModal([removeEvent, vm, setVmState])),
        filter(modalResponse => !!modalResponse),
        tap(([removeEvent, vm, setVmState]) => {
          const elementVm = vm[removeEvent.imageIndex];
          elementVm.deleteContent();
          const imageDeleted = this.translate.instant('RECIPE.IMAGE_UPLOAD.DELETED');
          this.messageService.queue(imageDeleted);
          setVmState.next(vm);
        })).toPromise();

  mapToRecipeImage(x: PhotoListItemViewModel): RecipeImageState {

    const entityState = [
      { s: x.data.isNew, e: EntityState.NEW },
      { s: x.data.isDeleted, e: EntityState.DELETED },
      { s: x.data.isUpdated, e: EntityState.UPDATED },
    ].reduce((acc, curr) => (EntityState.PLACEHOLDER && curr.s) ? curr.e : acc, EntityState.PLACEHOLDER);

    return ({
      key: x.data.key,
      content: x.data.content,
      ordinal: x.data.index,
      entityState
    } as RecipeImageState);
  }

  show(): boolean {
    return this.configService.getSettings().FF_ENABLE_USER_RECIPE_IMAGES;
  }

  getFileTypes(): string {
    return [...this.recipeUploadConfig
      .allowedMimeTypes]
      .map(mime => `.${mime.replace('image/', '')}`)
      .sort((curr, next) => curr.localeCompare(next))
      .join(', ');
  }

  private confirmationModal(data: any): Observable<any> {
    return this.dialog
      .open(ConfirmationModalComponent, {
        data: {
          returnData: data,
          title: 'RECIPE.IMAGE_UPLOAD.DELETE',
          submitButtonAriaLabel: 'MODALS.DELETE_BUTTON_ARIA_LABEL',
          submitButtonLabel: 'MODALS.DELETE'
        },
        disableClose: true
      })
      .afterClosed();
  }
}

function swap(list, x, y) {
  const tmp = list[x];
  list[x] = list[y];
  list[y] = tmp;
  return list;
}

const isValidMove = (newIdx: number, length: number): boolean => newIdx > -1 && newIdx < length;
