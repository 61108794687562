<ng-container *ngIf="this.searchFilter$">
  <mat-accordion
    id="category-recipe-accordian"
    multi="true"
    [togglePosition]="'before'"
  >
    <mat-expansion-panel
      *ngIf="hasRecipes(null) | async"
      class="category-recipe-panel"
      id="category-recipe-panel-unassigned"
      [expanded]="true"
    >
      <mat-expansion-panel-header
        class="category-recipe-panel-header item-background"
        [collapsedHeight]="'40px'"
        [expandedHeight]="'40px'"
      >
        <mat-panel-title class="category-recipe-panel-title">
          <div class="category-title">
            {{
              'CATEGORY.UNASSIGNED_CATEGORY_LABEL'
                | translate
                  : {
                      value: (
                        recipes$
                        | recipeNameFilter: (this.searchFilter$ | async)
                        | withCategoryId: null
                        | async
                      ).length
                    }
                | uppercase
            }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-category-recipe-list
        [recipes$]="
          recipes$
            | withCategoryId: null
            | recipeNameFilter: (this.searchFilter$ | async)
        "
        [paginationState]="getOrCreatePaginationState(null)"
        [paginationConfig]="sharedPaginationConfig"
        [isMobile]="isMobile$ | async"
        (deleteRecipeClicked)="onDeleteRecipe($event)"
      ></app-category-recipe-list>
    </mat-expansion-panel>

    <mat-expansion-panel
      class="category-recipe-panel"
      *ngFor="let category of categories$ | async | orderBy: 'ordinal'"
      [expanded]="category.expandStatus"
      id="category-recipe-panel-{{ category.name.replace(' ', '-') }}-{{
        category.id
      }}"
    >
      <mat-expansion-panel-header
        class="category-recipe-panel-header item-background"
        [collapsedHeight]="'40px'"
        [expandedHeight]="'40px'"
        (keydown.ArrowRight)="onToggleArrowFocus(category.id, category.name)"
        (keydown.ArrowLeft)="onToggleArrowFocus(category.id, category.name)"
        (click)="fireSetStorageAreaExpandStatus(category)"
      >
        <mat-panel-title class="category-recipe-panel-title" tabindex="-1">
          <div class="category-title">
            {{ categoryUtils.getTranslation(category.name) | translate | truncated: 17 | uppercase }} ({{
              category
                | categoryRecipesCount
                  : (recipes$
                      | recipeNameFilter: (this.searchFilter$ | async)
                      | async)
            }})
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <app-category-recipe-list
        [recipes$]="
          recipes$
            | withCategoryId: category.id
            | recipeNameFilter: (this.searchFilter$ | async)
        "
        [paginationState]="getOrCreatePaginationState(category.id)"
        [paginationConfig]="sharedPaginationConfig"
        [isMobile]="isMobile$ | async"
        (deleteRecipeClicked)="onDeleteRecipe($event)"
      ></app-category-recipe-list>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
