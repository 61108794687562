import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RecipeConstants } from '@gfs/constants';
import { RecipeIconService } from '@recipe-ui/services/images/recipe-icon.service';
import { PhotoManagerComponent } from '@recipe-ui/ui/photo-manager/photo-manager.component';
// eslint-disable-next-line max-len
import { PhotoContentPlaceholderComponent } from '@recipe-ui/ui/photo-manager/photo-content-placeholder/photo-content-placeholder.component';
import { PhotoContentComponent } from '@recipe-ui/ui/photo-manager/photo-content/photo-content.component';
import { PhotoControlComponent } from '@recipe-ui/ui/photo-manager/photo-control/photo-control.component';
import { PhotoListItemComponent } from '@recipe-ui/ui/photo-manager/photo-list-item/photo-list-item.component';
import { PhotoListComponent } from '@recipe-ui/ui/photo-manager/photo-list/photo-list.component';
import { MaterialModule, SharedComponentsModule } from '@gfs/shared-components';
import { RecipeImageUploadConfig } from './photo-manager.model';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropModalComponent } from './crop-modal/crop-modal.component';

const { icons } = RecipeConstants;

@NgModule({
    declarations: [
        PhotoManagerComponent,
        PhotoContentPlaceholderComponent,
        PhotoContentComponent,
        PhotoControlComponent,
        PhotoListComponent,
        PhotoListItemComponent,
        CropModalComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        ImageCropperModule,
        SharedComponentsModule
    ],
    exports: [PhotoManagerComponent]
})
export class PhotoManagerModule {
    readonly recipeIcons = [
        icons.warning,
        icons.arrowLeft
    ];

    constructor(public recipeIconService: RecipeIconService) {
        recipeIconService.registerIcons(this.recipeIcons);
    }
}

export const mockRecipeImageUploadConfig = ({
    maxSizeBytes: -1,
    allowedMimeTypes: [
        'image/png',
        'image/jpeg',
    ]
} as RecipeImageUploadConfig);
