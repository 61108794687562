import {Observable} from "rxjs";
import {inject} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from "@angular/router";
import { UnitSelectionRecipeSingleUnitRedirectGuard } from './recipe-unit-selection-single-unit-redirect.guard';

export const unitSelectionSingleUnitRecipeRedirectGuardFn: CanActivateFn =
  (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> => {
    const service = inject(UnitSelectionRecipeSingleUnitRedirectGuard);
    return service.canActivate(route, state);
  };
