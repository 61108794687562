import { registerLocaleData } from '@angular/common';
import locale_fr_CA from '@angular/common/locales/fr-CA';
import { Component, Input, OnInit } from '@angular/core';
import { Recipe } from '@gfs/shared-models';
import { AppState } from '@gfs/store/recipe/reducers';
import { Store } from '@ngrx/store';
import { PriceUtilsService } from '@recipe-ui/services/price-utils.service';

interface CalculatedPrice {
  margin: number;
  foodCostPercent: number;
  menuPrice: number;
  totalCost: number;
  portionPrice: number;
}

@Component({
  selector: 'app-category-recipe-card-pricing-information',
  templateUrl: './category-recipe-card-pricing-information.component.html',
  styleUrls: ['./category-recipe-card-pricing-information.component.scss']
})
export class CategoryRecipeCardPricingInformationComponent implements OnInit {

  @Input() recipe: Recipe;

  currentLang$ = this.store.select(state => state.layout.language);

  calculatedPrice: CalculatedPrice = {
    margin: 0,
    foodCostPercent: 0,
    menuPrice: 0,
    totalCost: 0,
    portionPrice: 0
  };

  constructor(
    private store: Store<AppState>,
    private priceUtils: PriceUtilsService
    ) { }

  /**
   * destructure and calculate recipe pricing values
   */
  ngOnInit() {
    registerLocaleData(locale_fr_CA);

    const {
      foodCostPercent = 0,
      menuPrice = 0,
      totalCost = 0
    } = { ...this.recipe?.recipePrice };
    const portionPrice = this.recipe?.details?.batch?.totalYieldQty
      ? totalCost / this.convertFractionToDecimal(this.recipe.details.batch.totalYieldQty)
      : 0;

    this.calculatedPrice = {
      margin: totalCost - menuPrice,
      foodCostPercent,
      menuPrice,
      totalCost,
      portionPrice
    };
  }

  convertFractionToDecimal(fraction: string): number {
    return this.priceUtils.convertFractionToDecimal(fraction);
  }
}
