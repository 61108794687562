import { Component, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { Recipe, RecipeCategory } from '@gfs/shared-models';
import { CategoryUtilsService } from '@recipe-ui/services/category-utils.service';
import { select, Store } from '@ngrx/store';
import { AppState, selectAllCategories } from '@gfs/store/recipe/reducers';
import { Observable } from 'rxjs';
import { SetCategoryExpandStatus } from '@gfs/store/recipe/actions/category.actions';

@Component({
  selector: 'app-category-select',
  templateUrl: './category-select.component.html',
  styleUrls: ['./category-select.component.scss']
})
export class CategorySelectComponent {

  categories$: Observable<RecipeCategory[]> = this.store.pipe(select(selectAllCategories));

  @Input() recipes: Recipe[];
  @Output() editCategorySelected: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private store: Store<AppState>,
    private zone: NgZone,
    private categoryUtils: CategoryUtilsService
  ) { }

  onEditCategory(category: string) {
    this.editCategorySelected.emit(category);
  }

  categorySelected(category: RecipeCategory) {
    this.store.dispatch(new SetCategoryExpandStatus({status: true, categoryId: category.id}));

    const element = document.getElementById(`category-recipe-panel-${category.name.replace(' ', '-')}-${category.id}`);
    const header = document.getElementsByTagName('app-header')[0] as HTMLElement;

    this.zone.runOutsideAngular(() => {
      if (element) {
        window.scroll(0, element.offsetTop);
        const scrolledY = window.pageYOffset;
        if (scrolledY && header) {
          const adjuestedScrollY = scrolledY - ((header).offsetHeight + 29);
          window.scroll(
            0,
            adjuestedScrollY
          );
        }
      }
    });
  }

  hasRecipes(categoryId: string) {
     return this.categoryUtils.hasRecipes(categoryId, this.recipes);
  }

  getRecipes(categoryId: string) {
     return this.categoryUtils.getRecipes(categoryId, this.recipes);
  }
}
