import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Recipe } from '@gfs/shared-models';
import { RecipeService } from '@gfs/shared-services';

@Component({
  selector: 'app-category-recipe-card',
  templateUrl: './category-recipe-card.component.html',
  styleUrls: ['./category-recipe-card.component.scss'],
})
export class CategoryRecipeCardComponent {

  @Input() recipe: Recipe;
  @Input() isMobile: boolean;
  @Output() deleteRecipeClicked: EventEmitter<Recipe> = new EventEmitter<Recipe>();

  constructor(
    private recipeService: RecipeService
  ) { }

}
