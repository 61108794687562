import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Recipe } from '@gfs/shared-models';

@Pipe({
  name: 'withCategoryId',
})
export class WithCategoryIdPipe implements PipeTransform {
  transform(
    recipeList: Observable<Recipe[]>,
    categoryId: string
  ): Observable<Recipe[]> {
    return recipeList.pipe(
      map((results) => results.filter((x) => x.categoryId === categoryId))
    );
  }
}
