import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  constructor(
    private translate: TranslateService,
    private router: Router
  ) { }

  onBackToCategory() {
    this.router.navigateByUrl('/category');
  }

}
