import {
  AfterViewInit,
  Component, EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Recipe } from '@gfs/shared-models';
import { AppState } from '@gfs/store/recipe/reducers';
import { Store } from '@ngrx/store';
import { PaginationConfig, PaginationState } from '@recipe-ui/models';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-category-recipe-list',
  templateUrl: './category-recipe-list.component.html',
  styleUrls: ['./category-recipe-list.component.scss']
})
export class CategoryRecipeListComponent implements AfterViewInit {

  @Input() recipes$: Observable<Recipe[]>;
  @Input() paginationState: PaginationState = {};
  @Input() paginationConfig: PaginationConfig = {};
  @Input() isMobile: boolean;
  infiniteScrollEnabled$ = this.store.select(state=> state.category.isInfinitescroll)

  @Output() deleteRecipeClicked: EventEmitter<Recipe> = new EventEmitter<Recipe>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(public store: Store<AppState>){}

  ngAfterViewInit() {
    this.paginator.pageIndex = this.paginationState.pageIndex;
    this.store.select(state=>state.category.isInfinitescroll).pipe(
      tap((value)=> {
        this.paginationState.pageStart = 0;
        this.paginationState.pageEnd = this.paginationState.pageStart +this.paginationConfig.PageSize
      })
      ).subscribe()
  }


  onDeleteRecipe = (recipe: Recipe) => this.deleteRecipeClicked.emit(recipe);

  onPage(evt: PageEvent) {
    this.paginationState.pageStart = evt.pageIndex * evt.pageSize;
    this.paginationState.pageEnd = this.paginationState.pageStart + evt.pageSize;
  }
}
