import { Injectable } from '@angular/core';
import { createEffect, Actions } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

@Injectable()
export class AppEffects {
  constructor(private actions$: Actions) {}


  actionLogger$ = createEffect(() => this.actions$.pipe(
    tap(action => {
      const ACTIONS_TO_EXCLUDE = [
        '[Placeholder] Placeholder Action Name'];
      if (!ACTIONS_TO_EXCLUDE.includes(action.type)) {
        console.log(action);
      }
    })
  ), {dispatch: false});
}
