import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppContext } from '@gfs/shared-models';
import { AppState } from '@gfs/store/recipe/reducers';
import { AppFeatureFlags } from '@gfs/shared-services';

@Injectable({
  providedIn: 'root',
})
export class UserContextFactoryService {
  public static factory(factory: UserContextFactoryService) {
    return factory.Build();
  }

  constructor(
    private store: Store<AppState>,
    private featureFlags: AppFeatureFlags
  ) { }

  Build(): IAppContext {
    return {
      customerPK: this.store.select(state => state.auth.pk),
      entitlements: this.store.select((state) =>
        state.auth.user ? state.auth.user.entitlements : null
      ),
      title: this.store.select((x) => x.layout.title),
      headerHeight: this.store.select((x) => x.layout.headerHeight),
      isMobile: this.store.select((x) => x.layout.isMobile),
      language: this.store.select((x) => x.layout.language),
      featureFlags: this.featureFlags,
      isOnline$: this.store.select(state => state.network.isOnline),
      authorizations$: this.store.select(state => state.auth.authorizations),
      user$: this.store.select(state => state.auth.user),
    };
  }
}
