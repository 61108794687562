<div id="category-header-container">
  <app-category-header></app-category-header>
  <div *ngIf="isMobile$ | async">
    <app-category-select
      class="app-category-select"
      (editCategorySelected)="editCategoryHandler($event)"
    >
    </app-category-select>
  </div>
</div>
<div id="category-main-container">
  <div *ngIf="isMobile$ | async">
    <app-category-recipe-panel
      (deleteRecipeClicked)="deleteRecipeHandler($event)"
      (editCategoryClicked)="editCategoryHandler($event)"
      (deleteCategoryClicked)="deleteCategoryHandler($event)"
    >
    </app-category-recipe-panel>
  </div>
  <mat-sidenav-container *ngIf="!(isMobile$ | async)">
    <mat-sidenav
      mode="side"
      opened
      class="category-sidenav"
      [disableClose]="true"
    >
      <app-category-list
        (editCategoryClicked)="editCategoryHandler($event)"
        (deleteCategoryClicked)="deleteCategoryHandler($event)"
      >
      </app-category-list>
      <button
        id="new-category-button"
        class="ghost-button"
        (click)="editCategoryHandler()"
      >
        <mat-icon
          class="icon category-plus-icon"
          svgIcon="plus-icon"
        ></mat-icon>
        <div class="new-category-label">
          {{ 'CATEGORY.NEW_CATEGORY' | translate }}
        </div>
      </button>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="category-sidenav-content">
        <app-category-recipe-panel
          (deleteRecipeClicked)="deleteRecipeHandler($event)"
          (editCategoryClicked)="editCategoryHandler($event)"
          (deleteCategoryClicked)="deleteCategoryHandler($event)"
        >
        </app-category-recipe-panel>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
