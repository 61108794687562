<div class="modal-container">
  <button
    class="close-button"
    (click)="closeModal()"
    attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}"
  >
    <img
      class="close-button-img"
      src="assets/images/close-icon.svg"
      alt="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}"
    />
  </button>

  <div class="confirmation-modal-content">
    <gfs-loading-overlay [hidden]="(isReady$ | async)"></gfs-loading-overlay>
    <div class="modal-warning-text">
      {{ 'RECIPE.IMAGE_UPLOAD.CROP' | translate }}
    </div>

    <image-cropper
      [imageBase64]="imageBase64"
      (imageCropped)="imageCropped($event)"
      (cropperReady)="cropperReady()"
      [aspectRatio]="4 / 3"
      [maintainAspectRatio]="true"
    >
    </image-cropper>

    <div class="modal-button-bar">
      <button
        type="button"
        class="secondary-destructive-button large-button"
        (click)="closeModal()"
      >
        {{ 'MODALS.CANCEL' | translate}}
      </button>

      <!--  -->

      <button
        id="modal-submit-button"
        class="primary-destructive-button large-button"
        type="submit"
        (click)="submit()"
        attr.aria-label="{{ 'SAVE_BUTTON_ARIA_LABEL' | translate }}"
      >
        {{ 'MODALS.SAVE' | translate }}
      </button>
    </div>
  </div>
</div>
