import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { BehaviorSubject } from 'rxjs';

export interface DialogData {
  imageBase64: string;
}

@Component({
  selector: 'app-crop-modal',
  templateUrl: './crop-modal.component.html',
  styleUrls: ['./crop-modal.component.scss']
})
export class CropModalComponent {

  isReady$ = new BehaviorSubject<boolean>(false);

  croppedBase64 = '';
  imageBase64: string = this.data.imageBase64;

  constructor(
    public dialogRef: MatDialogRef<CropModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  imageCropped(event: ImageCroppedEvent) { 
    this.blobToBase64(event.objectUrl).then(base64String => {
      this.croppedBase64 = base64String as string
    });
  }
 async blobToBase64(url){
    const fileReader = new FileReader();
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, _) => {
      fileReader.readAsDataURL(blob);
      fileReader.onloadend = function () {
        resolve(fileReader.result); // Here is the base64 string
      }
    })
  }

  submit() { this.dialogRef.close(this.croppedBase64); }

  closeModal() { this.dialogRef.close(); }

  cropperReady() { this.isReady$.next(true); }
}
