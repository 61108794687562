import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IAppContext } from '@gfs/shared-models';
import { InjectionTokens } from '@gfs/shared-services';
import { firstValueFrom, isTruthy } from '@gfs/shared-services/extensions/rxjs';
import { LoadingSpinnerOverlayService } from '@gfs/v2/shared-components';
import { RecipeEditContainerFactory, RecipeEditStore, RecipeState } from '@recipe-ui/v2/edit/edit.component.store';
import { RecipeEditFormControl, RecipeEditFormControlFactoryService } from '@recipe-ui/v2/edit/form-factory.service';
import { forkJoin, iif, Observable, of } from 'rxjs';
import { concatMap, filter, first, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GetRecipeResolver {

  constructor(
    @Inject(InjectionTokens.IAPP_CONTEXT)
    public appCtx: IAppContext,
    private formFactory: RecipeEditFormControlFactoryService,
    private containerFactory: RecipeEditContainerFactory,
    private loadingSpinner: LoadingSpinnerOverlayService
  ) {
    containerFactory.reset();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<{
    recipeEditStore: RecipeEditStore;
    recipeState: RecipeState;
    formControl: RecipeEditFormControl;
  }> {
    return of(route.params)
      .pipe(
        first(),
        tap(() => { this.loadingSpinner.show(); }), // @note loading spinner is hidden by the component on first render
        concatMap(({ id: recipeId }) => this.preloadRecipe(recipeId)),
      );
  }

  private preloadRecipe(recipeId): Observable<{
    recipeEditStore: RecipeEditStore;
    recipeState: RecipeState;
    formControl: RecipeEditFormControl;
  }> {
    return firstValueFrom(this.containerFactory.instance$)
      .pipe(
        concatMap(recipeEditStore =>
          forkJoin([
            of(recipeEditStore),
            iif(
              () => recipeId,
              recipeEditStore.initRecipeById$(recipeId),
              recipeEditStore.initNewRecipe$()
            )
          ])
        )
      ).pipe(
        concatMap(([recipeEditStore]) => /* wait for recipe store to finish loading*/
          recipeEditStore.createVM$()
            .pipe(
              filter(vm => !vm.isLoading),
              first()
            )
        ),
        concatMap(recipeEditVM => /* create form and push recipe initial state into it*/
          forkJoin([
            firstValueFrom(this.containerFactory.instance$),
            of(recipeEditVM)
              .pipe(
                map(x => x.recipe),
                isTruthy(),
                first()
              )
          ]).pipe(
            first(),
            map(([recipeEditStore, recipeState]) => ({
              recipeEditStore,
              recipeState,
              formControl: this.formFactory.createFormControl(
                recipeState,
                recipeEditStore.isRecipeNameUnique$.bind(recipeEditStore)
              )
            })),
            concatMap((r) =>
              forkJoin([
                of(r),
                firstValueFrom(
                  r.recipeEditStore.createVM$()
                    .pipe(
                      map(xRecipe => xRecipe?.recipe),
                      isTruthy(),
                      first(),
                      concatMap(initialState =>
                        forkJoin([
                          of(initialState),
                          of(r.formControl),
                        ])
                      ),
                      tap(([state, formControl]) => {
                        formControl.f.reset(state);
                      }),
                      map(([_, formControl]) => formControl),
                      tap(() => { this.loadingSpinner.hide(); }), 
                    )
                )
              ])
                .pipe(map(([deps]) => deps))
            )
          )
        )
      );
  }
}
