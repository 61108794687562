import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Recipe, RecipeCategory } from '@gfs/shared-models';
import { RecipeService } from '@gfs/shared-services';
import { SetCategoryExpandStatus } from '@gfs/store/recipe/actions/category.actions';
import { AppState, selectAllCategories, selectAllRecipes } from '@gfs/store/recipe/reducers';
import { select, Store } from '@ngrx/store';
import { PaginationConfig, PaginationState } from '@recipe-ui/models';
import { CategoryUtilsService } from '@recipe-ui/services/category-utils.service';
import { sortBy } from 'lodash-es';
import { combineLatest, iif, Observable, of, Subject } from 'rxjs';
import { concatMap, distinct, distinctUntilChanged, filter, first, map, mergeAll, takeUntil, tap, toArray } from 'rxjs/operators';

@Component({
  selector: 'app-category-recipe-panel',
  templateUrl: './category-recipe-panel.component.html',
  styleUrls: ['./category-recipe-panel.component.scss']
})
export class CategoryRecipePanelComponent implements OnInit, OnDestroy {

  @Output() editCategoryClicked: EventEmitter<RecipeCategory> = new EventEmitter<RecipeCategory>();
  @Output() deleteCategoryClicked: EventEmitter<RecipeCategory> = new EventEmitter<RecipeCategory>();
  @Output() deleteRecipeClicked: EventEmitter<Recipe> = new EventEmitter<Recipe>();

  categories$: Observable<RecipeCategory[]> = this.store.pipe(select(selectAllCategories));
  recipes$: Observable<Recipe[]> = this.store.pipe(
    select(selectAllRecipes),
    map((recipe)=>recipe.filter((recipeUndeleted)=> !recipeUndeleted.deleted)),
    map(recipes => sortBy(recipes, [recipe => recipe.name?.toLowerCase()]))
  );
  searchFilter$: Observable<string> = of('');
  isMobile$: Observable<boolean> = this.store.select(state => state.layout.isMobile);

  recipePaginationState: { [categoryId: string]: PaginationState } = {};
  sharedPaginationConfig: PaginationConfig = { PageSize: 10 };
  destroyNotifer$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private categoryUtils: CategoryUtilsService,
    private recipeSvc: RecipeService,
  ) {
  }

  ngOnInit() {
    this.searchFilter$ = this.recipeSvc.recipeSearchTerm$.asObservable();
    combineLatest([
      this.createBehaviorExpandRecipeCategoriesWithSearchResults$(this.searchFilter$),
      this.createBehaviorExpandPanelsOnLayoutToggle$()
    ]).pipe(
      takeUntil(this.destroyNotifer$)
    ).subscribe();

  }

  createBehaviorExpandRecipeCategoriesWithSearchResults$(searchTermInput: Observable<string>) {
    return searchTermInput
      .pipe(
        map(v => (v ?? '').trim().toLowerCase()),
        concatMap(searchTerm =>
          iif(() => searchTerm === '',
            this.collapseAllCategories$(),
            this.expandCategoriesWithRecipesMatchingSearchTerm$(searchTerm)
          ))
      );
  }

  private expandCategoriesWithRecipesMatchingSearchTerm$(searchTerm: string) {
    return this.recipes$
      .pipe(
        first(),
        mergeAll(),
        filter((recipe) => searchTerm === '' || isMatchingRecipe(recipe, searchTerm)),
        map(v => v.categoryId ?? undefined),
        distinct(),
        toArray(),
        concatMap((recipeCategoryIds) =>
          this.categories$
            .pipe(
              first(),
              mergeAll(),
              filter(x => recipeCategoryIds.indexOf(x.id) > -1),
              tap(
                (cat) => { cat.expandStatus = true; }
              ))
        )
      );
  }

  private collapseAllCategories$() {
    return this.categories$
      .pipe(
        first(),
        mergeAll(),
        tap((cat) => { cat.expandStatus = false; })
      );
  }

  /**
   * cleanup subscriptions
   */
  ngOnDestroy() {
    this.destroyNotifer$.next();
  }

  /**
   * update category expand statuses on init
   */
  createBehaviorExpandPanelsOnLayoutToggle$() {
    return combineLatest([
      this.isMobile$,
      this.categories$
    ])
      .pipe(
        filter(([_, categories]) => categories.length > 0),
        distinctUntilChanged(),
        map(([isMobile, _]) => isMobile),
        first(),
        concatMap(isMobile =>
          iif(() => isMobile,
            this.categories$
              .pipe(
                first(),
                mergeAll(),
                tap(category => {
                  this.store.dispatch(
                    new SetCategoryExpandStatus({
                      status: true,
                      categoryId: category.id
                    })
                  );
                })
              ),
            this.categories$
              .pipe(
                first(),
                mergeAll(),
                tap(category => {
                  this.store.dispatch(
                    new SetCategoryExpandStatus({
                      status: category.ordinal === 0,
                      categoryId: category.id
                    }));
                })
              )
          ))
      );
  }

  /**
   * toggle mat expansion panel header expand status
   * on click
   *
   * @param cat a RecipeCategory
   */
  fireSetStorageAreaExpandStatus(cat: RecipeCategory) {
    if (cat !== null) {
      this.store.dispatch(new SetCategoryExpandStatus({
        status: !cat.expandStatus,
        categoryId: cat.id
      }));
    }
  }

  /**
   * handle (keydown.ArrowRight|ArrowLeft) events for navigating
   * triple dot menu on recipes
   *
   * @param categoryId a RecipeCategory.id
   * @param categoryName a RecipeCategory.name
   */
  onToggleArrowFocus(categoryId: string, categoryName: string) {
    if (document.activeElement.id.startsWith('category-recipe-menu')) {
      document.activeElement.parentElement.parentElement.focus();
    } else {
      document.getElementById('category-recipe-menu-' + categoryName.replace(' ', '-') + '-' + categoryId).focus();
    }
  }

  /**
   * determine if a category has any recipes
   *
   * @param categoryId a RecipeCategory.id
   * @returns an observable boolean if a category has
   * any recipes
   */
  hasRecipes(categoryId: string): Observable<boolean> {
    return this.recipes$.pipe(
      first(),
      map(
        recipes => this.categoryUtils.hasRecipes(categoryId, recipes)
      )
    );
  }

  /**
   * call editCategoryClicked event emitter with a RecipeCategory event
   *
   * @param category a single RecipeCategory
   */
  onEditCategory(category: RecipeCategory) {
    this.editCategoryClicked.emit(category);
  }

  /**
   * call deleteCategoryClicked event emitter with a RecipeCategory[] event
   *
   * @param categories a RecipeCategory[] array
   */
  onDeleteCategory(categories: RecipeCategory) {
    this.deleteCategoryClicked.emit(categories);
  }

  /**
   * call deleteRecipeClicked event emitter with a Recipe event
   *
   * @param recipe a single Recipe
   */
  onDeleteRecipe(recipe: Recipe) {
    this.deleteRecipeClicked.emit(recipe);
  }

  /**
   * get pagination state if it exists, or create a new pagination
   * state
   *
   * @param categoryId a RecipeCategory.id
   * @returns an instance of PaginationState
   */
  getOrCreatePaginationState(categoryId: string): PaginationState {
    return (this.recipePaginationState[categoryId] = this.recipePaginationState[
      categoryId
    ] ?? {
      pageIndex: 0,
      pageStart: 0,
      pageEnd: this.sharedPaginationConfig.PageSize,
    });
  }
}
function isMatchingRecipe(recipe: Recipe, searchTerm: any): boolean {
  return (recipe.name ?? '')
    .toLowerCase()
    .includes(searchTerm.trim().toLowerCase());
}