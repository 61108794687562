import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImageRemovedEvent, ReorderImageEvent } from '../photo-manager.model';

@Component({
  selector: 'app-photo-control',
  templateUrl: './photo-control.component.html',
  styleUrls: ['./photo-control.component.scss']
})
export class PhotoControlComponent {
  @Input()
  imageIndex = -1;

  @Input()
  maxIndex = -1;

  @Input()
  hasContent = false;

  @Output()
  imageRemoved = new EventEmitter<ImageRemovedEvent>();

  @Output()
  moveImage = new EventEmitter<ReorderImageEvent>();
}
