import { Component, OnInit, Inject, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RecipeErrorStateMatcher } from '@recipe-ui/services/shared/recipe-error-state-matcher/recipe-error-state-matcher';
import { RecipeCategory } from '@gfs/shared-models';
import { RecipeConstants } from '@gfs/constants';

export interface DialogData {
  categories: RecipeCategory[];
  categoryName: string;
}

@Component({
  selector: 'app-category-create-modal',
  templateUrl: './category-create-modal.component.html',
  styleUrls: ['./category-create-modal.component.scss']
})
export class CategoryCreateModalComponent implements OnInit {
  @Input() categories: RecipeCategory[];
  isNewCategory: boolean;
  categoryName: string;
  form: UntypedFormGroup;
  matcher = new RecipeErrorStateMatcher();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<CategoryCreateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  ngOnInit() {
    this.categories = (this.data && this.data.categories) ? this.data.categories : [];
    this.isNewCategory = !(this.data && this.data.categoryName);
    this.categoryName = (this.data && this.data.categoryName) ? this.data.categoryName : '';
    this.form = this.formBuilder.group({
      categoryName: [this.data ? this.data.categoryName : '',
        [ Validators.required, this.duplicateCategoryValidator(this.categoryName), this.categoryNameValidator() ] ],
      updateOn: 'change'
    });
  }

  public duplicateCategoryValidator(
    originalName: string
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control && control.value) {
        if (originalName.toLowerCase() !== control.value.toLowerCase()) {
          if (this.categories.findIndex(category => category.name && category.name.toLowerCase() === control.value.toLowerCase()) >= 0) {
            return { categoryExists: true };
          }
        }
      }
      return null;
    };
  }

  public categoryNameValidator(
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (control && control.value) {
        if ((RecipeConstants.UNASSIGNED_EN_CA.toLowerCase() === control.value.toLowerCase()) ||
            (RecipeConstants.UNASSIGNED_FR_CA.toLowerCase() === control.value.toLowerCase())) {
            return { invalidCategoryName: true };
        }
      }
      return null;
    };
  }

  closeModal() {
    this.form.value.categoryName = '';
    this.dialogRef.close();
  }

  submit(form) {
    this.dialogRef.close(form.value.categoryName);
  }

}
