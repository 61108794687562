<mat-icon
  attr.id="category-recipe-menu-{{ recipe.name.replace(' ', '-') }}-{{
    recipe.id
  }}"
  attr.aria-label="{{ 'CATEGORY.TRIPLE_DOT_MENU_ARIA_LABEL' | translate }}"
  tabindex="-1"
  #menuTrigger="matMenuTrigger"
  [matMenuTriggerFor]="menu"
  class="recipe-card-triple-dot-menu"
  svgIcon="triple_dot_menu"
  (click)="$event.stopPropagation()"
  (keyup.ENTER)="menuTrigger.openMenu()"
>
</mat-icon>
<mat-menu
  attr.id="category-recipe-menux-{{ recipe.name.replace(' ', '-') }}-{{
    recipe.id
  }}"
  mat-menu-below
  #menu="matMenu"
  xPosition="before"
  panelClass="edit-category-context-menu"
  tabindex="0"
  tabindex="-1"
>
  <button
    mat-menu-item
    id="recipe-card-edit-button-{{ recipe.id }}"
    tabindex="-1"
    attr.aria-label="{{
      'CATEGORY.RECIPE_CARD.EDIT_BUTTON_ARIA_LABEL' | translate
    }}"
    routerLink="/recipe/{{ recipe.id }}"
  >
    {{ 'CATEGORY.RECIPE_CARD.TRIPLE_DOT_MENU.EDIT' | translate }}
  </button>
  <button
    mat-menu-item
    id="recipe-card-clone-button-{{ recipe.id }}"
    tabindex="-1"
    attr.aria-label="{{
      'CATEGORY.RECIPE_CARD.CLONE_BUTTON_ARIA_LABEL' | translate
    }}"
    (click)="cloneRecipeHandler(recipe.id)"
  >
    {{ 'CATEGORY.RECIPE_CARD.TRIPLE_DOT_MENU.CLONE' | translate }}
  </button>
  <button
    mat-menu-item
    [matMenuTriggerFor]="moveToCategory"
    id="recipe-card-move-to-button-{{ recipe.id }}"
    tabindex="-1"
    attr.aria-label="{{
      'CATEGORY.RECIPE_CARD.MOVE_TO_BUTTON_ARIA_LABEL' | translate
    }}"
  >
    {{ 'CATEGORY.RECIPE_CARD.TRIPLE_DOT_MENU.MOVE_TO' | translate }}
  </button>
  <button
    mat-menu-item
    id="recipe-card-delete-button-{{ recipe.id }}"
    tabindex="-1"
    attr.aria-label="{{
      'CATEGORY.RECIPE_CARD.DELETE_BUTTON_ARIA_LABEL' | translate
    }}"
    (click)="deleteRecipeClicked.emit(recipe)"
  >
    {{ 'CATEGORY.RECIPE_CARD.TRIPLE_DOT_MENU.DELETE' | translate }}
  </button>
</mat-menu>

<mat-menu #moveToCategory="matMenu">
  <button
    mat-menu-item
    *ngIf="recipe.categoryId"
    (click)="moveRecipeToCategoryHandler(recipe, { categoryId: null })"
  >
    {{
      'CATEGORY.RECIPE_CARD.TRIPLE_DOT_MENU.UNASSIGNED_CATEGORY_LABEL'
        | translate
    }}
  </button>
  <ng-container *ngFor="let category of categories$ | async">
    <button
      mat-menu-item
      *ngIf="category.id !== recipe.categoryId"
      (click)="moveRecipeToCategoryHandler(recipe, category)"
    >
      {{ category.name }}
    </button>
  </ng-container>
</mat-menu>
