<div class="confirm-container">
  <button
    class="close-button"
    (click)="closeModal()"
    attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}"
  >
    <img
      class="close-button-img"
      src="assets/images/close-icon.svg"
      alt="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}"
    />
  </button>
  <div class="modal-text" [innerHTML]="data.message | translate"></div>
  <div class="modal-button-bar">
    <button
      mat-button
      mat-dialog-close
      id="cancel-button"
      class="secondary-destructive-button large-button"
      type="button"
      [innerHTML]="data.cancellation | translate"
    ></button>
    <button
      mat-button
      [mat-dialog-close]="true"
      id="leave-page-button"
      class="primary-destructive-button large-button"
      type="button"
      [innerHTML]="data.confirmation | translate"
    ></button>
  </div>
</div>
