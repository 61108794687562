import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { AppState } from "@gfs/store/inventory/reducers";
import { filter, map, tap } from "rxjs/operators";
import { WINDOW } from '../services/window.service';

@Injectable({ providedIn: 'root' })
export class UnitSelectionRecipeSingleUnitRedirectGuard {

  constructor(
    private store: Store<AppState>,
    private router: Router,
    @Inject(WINDOW) private window: Window,

  ) {
  }

  entitlements$ = this.store
    .select(state => state.auth.user?.entitlements)
    .pipe(
      filter(value => {
        // Wait for SetUserAttributes to resolve user.entitlements before emitting
        return !!value?.length;
      }),
    );

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.checkIfMultiUnit();
  }

  checkIfMultiUnit(): Observable<boolean> {
    const storedCustomerJson = this.window.localStorage.getItem('customer');
    // Map entitlements to customerIds, filter out duplicates, and check if there is only one customerId
    // Temporary fix to allow customer unit location selections even for single unit customers to enable them to navigate to inventory page
    return this.entitlements$
      .pipe(
        map(entitlements => entitlements.map(entitlement => entitlement.customerPK.customerId)),
        map(customerIds => customerIds.filter((customerId, index, self) => self.indexOf(customerId) === index)),
        map(customerIds => {
          if (customerIds.length === 1) {
            this.router.navigate(['category']);
            return false
          } else {
            return true
          }
        }),
      );
  }
}
