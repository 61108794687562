<ng-container *ngIf="show()">
  <mat-expansion-panel class="darkpanel" [expanded]="true" style="overflow: visible;"
    *ngIf="appCtx.featureFlags.Recipe.EnableUserRecipeImages">
    <mat-expansion-panel-header [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
      <mat-panel-title tabindex="-1">
      {{ 'RECIPE.IMAGE_UPLOAD.TITLE' | translate }}
      </mat-panel-title>
    </mat-expansion-panel-header>
  <div class="italic">{{ 'RECIPE.IMAGE_UPLOAD.HEADER' | translate }} {{ 'RECIPE.IMAGE_UPLOAD.LIMIT' | translate }}</div>
  <div class="italic">{{ 'RECIPE.IMAGE_UPLOAD.FILE_TYPES' | translate }}<span> {{getFileTypes()}}</span></div>
    <app-photo-manager [data]="vm$ | async" (fileSelected)="imageSelectedHandler($event)"
      (imageRemoved)="imageRemovedHandler($event)" (moveImage)="imageMovedHandler($event)">
    </app-photo-manager>
  </mat-expansion-panel>
</ng-container>