import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';
import { RecipeConstants } from '@gfs/constants';
import { RecipeIconService } from '@recipe-ui/services/images/recipe-icon.service';
import { PhotoManagerModule } from '@recipe-ui/ui/photo-manager/photo-manager.module';

const { icons } = RecipeConstants;

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
    ],
    exports: [PhotoManagerModule]
})
export class UIModule {
    readonly recipeIcons = [
        icons.warning
    ];

    constructor(public recipeIconService: RecipeIconService) {
        recipeIconService.registerIcons(this.recipeIcons);
    }
}
