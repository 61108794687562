import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@gfs/store/recipe/reducers';
import { IAddItemsFeatureBridge } from '@gfs/store/feature/add-items';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddItemsContextFactoryService {
  public static factory(factory: AddItemsContextFactoryService) {
    return factory.Build();
  }

  constructor(private store: Store<AppState>) {}

  Build(): IAddItemsFeatureBridge {
    return {
      pk$: this.store.select((state) => state.auth.pk),
      currentLang$: this.store.select((state) => state.layout.language),
      isMobile$: this.store.select((state) => state.layout.isMobile),
      customItemData$: this.store.select((state) => state.worksheets.customItemData),
      selectedWorksheet$: this.store.select(
        (state) => state.recipe.mostRecentWorksheet
      ),
      isSavingWorksheet$: of(null),
    };
  }
}
