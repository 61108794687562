<div [ngSwitch]="vm.hasContent()">
  <app-photo-content *ngSwitchCase="true" [content]="vm.data.content"></app-photo-content>
  <app-photo-content-placeholder (fileSelected)="fileSelected.emit($event)" [imageIndex]="vm.data.index"
    *ngSwitchDefault>
  </app-photo-content-placeholder>
</div>
<div class="image-name">
  {{vm.data.name}}
</div>
<app-photo-control [imageIndex]="vm.data.index" [maxIndex]="vm.data.maxIndex" (moveImage)="moveImage.emit($event)"
  [hasContent]="this.vm.hasContent()" (imageRemoved)="imageRemoved.emit($event)"></app-photo-control>
