<div style="width:40px">
  <button mat-icon-button type="button" (click)="moveImage.emit({imageIndex, shiftIndex:-1})" *ngIf="imageIndex > 0">
    <mat-icon svgIcon="arrow-left"></mat-icon>
  </button>
</div>
<div>
  <button mat-icon-button type="button" (click)="imageRemoved.emit({imageIndex})" *ngIf="hasContent">
    <mat-icon svgIcon="trash-icon"></mat-icon>
  </button>
</div>
<div style="width:40px">
  <button mat-icon-button type="button" (click)="moveImage.emit({imageIndex, shiftIndex:1})"
    *ngIf="imageIndex < maxIndex">
    <mat-icon svgIcon="arrow-left"></mat-icon>
  </button>
</div>
