<div *ngIf="!(isMobile$ | async)"
  class="search-container-bar"
  (keydown.enter)="searchTermSubject.next(searchTerm.value)">
  <mat-form-field class="full-width" appearance="outline">
    <input
      matInput
      #searchTerm
      type="text"
      autocomplete="off"
      placeholder="{{ searchText | translate }}"
      attr.aria-label="{{ searchText | translate }}"
      (keyup)="oninputChange(searchTerm.value)"
    />
    <mat-icon
    (click)="searchTermSubject.next(searchTerm.value)"
    (keydown.enter)="searchTermSubject.next(searchTerm.value)"
    class="search-icon" svgIcon="search_icon" matPrefix></mat-icon>
  </mat-form-field>
</div>
