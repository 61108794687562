import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm, FormControl } from '@angular/forms';

// Error when invalid control is dirty or touched
export class RecipeErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched))
  }
}

// Error when control is only invalid ( used for pre populated data that might contain errors)
export class RecipeinitialStateErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.invalid);
  }
}
