<div class="header-text-container">
  <h1 id="h1-category-title">{{ 'CATEGORY.HEADER_TITLE' | translate }}</h1>
</div>
<div class="search-container">
  <app-category-recipe-search-input  [searchText]="'RECIPE.SEARCH.RECIPE_PLACEHOLDER'"></app-category-recipe-search-input>
  <div class="recipe-infinite-scroll-toggle">
    <mat-slide-toggle
    [checked]="infiniteScrollState$ | async"
    (change)="toggleInfiniteScrolling($event)"
  >
  {{ 'CATEGORY.INFINITE_SCROLLING' | translate }}
  </mat-slide-toggle>
  </div>
</div>

<div class="recipe-title-button-bar">
  <button
    id="new-recipe-button"
    class="primary-button large-button"
    routerLink="/recipe"
  >
    {{ 'CATEGORY.NEW_RECIPE' | translate }}
  </button>
</div>
