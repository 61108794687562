import { Action } from '@ngrx/store';
import { MeasurementUnit } from '@gfs/shared-models';
import { HttpErrorResponse } from '@angular/common/http';

export enum ActionTypes {
  GetMeasurementUnitsAttempt = '[Measurement Unit] Get Measurement Units Attempt',
  GetMeasurementUnitsSuccess = '[Measurement Unit] Get Measurement Units Success',
  GetMeasurementUnitsError = '[Measurement Unit] Get Measurement Units Error'
}

export class GetMeasurementUnitsAttempt implements Action {
  readonly type = ActionTypes.GetMeasurementUnitsAttempt;
}

export class GetMeasurementUnitsSuccess implements Action {
  readonly type = ActionTypes.GetMeasurementUnitsSuccess;
  constructor(public units: MeasurementUnit[]) {}
}

export class GetMeasurementUnitsError implements Action {
  readonly type = ActionTypes.GetMeasurementUnitsError;
  constructor(public error: HttpErrorResponse) {}
}

export type ActionUnion =
  GetMeasurementUnitsAttempt |
  GetMeasurementUnitsSuccess |
  GetMeasurementUnitsError;
