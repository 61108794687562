<ng-container *ngIf="recipes$ | async  as recipes">
<ng-container *ngIf="infiniteScrollEnabled$ | async">
<div class="infinite-container"
  [ngStyle]="{'height': recipes.length <= 3 ? 'auto' : '500px'}"
>
  <ng-container *ngIf="!isMobile">
    <app-category-recipe-card
      id="category-recipe-card-{{ recipe.id }}"
      *ngFor="let recipe of (recipes | slice : this.paginationState.pageStart : recipes.length)"
      [recipe]="recipe"
      [isMobile]="isMobile"
      (deleteRecipeClicked)="onDeleteRecipe($event)"
    >
    </app-category-recipe-card>
  </ng-container>
  <ng-container *ngIf="isMobile">
    <mat-accordion multi [togglePosition]="'before'">
      <app-category-recipe-card-mobile
        id="category-recipe-card-{{ recipe.id }}"
        *ngFor="let recipe of (recipes | slice : this.paginationState.pageStart : recipes.length)"
        [recipe]="recipe"
        [isMobile]="isMobile"
        (deleteRecipeClicked)="onDeleteRecipe($event)"
      >
      </app-category-recipe-card-mobile>
    </mat-accordion>
  </ng-container>
</div>
</ng-container>
<ng-container *ngIf="!(infiniteScrollEnabled$ | async)">
    <ng-container *ngIf="!isMobile">
      <app-category-recipe-card
      id="category-recipe-card-{{ recipe.id }}"
      *ngFor="let recipe of (recipes | slice : this.paginationState.pageStart :  this.paginationState.pageEnd)"
      [recipe]="recipe"
      [isMobile]="isMobile"
      (deleteRecipeClicked)="onDeleteRecipe($event)"
      >
      </app-category-recipe-card>
    </ng-container>
    <ng-container *ngIf="isMobile">
      <mat-accordion multi [togglePosition]="'before'">
      <app-category-recipe-card-mobile
        id="category-recipe-card-{{ recipe.id }}"
        *ngFor="let recipe of (recipes | slice : this.paginationState.pageStart :  this.paginationState.pageEnd)"
        [recipe]="recipe"
        [isMobile]="isMobile"
        (deleteRecipeClicked)="onDeleteRecipe($event)"
      >
      </app-category-recipe-card-mobile>
    </mat-accordion>
    </ng-container>
  <mat-paginator
    #paginator
    *ngIf="!(infiniteScrollEnabled | async)"
    [pageSize]="paginationConfig.PageSize"
    [showFirstLastButtons]="true"
    (page)="onPage($event)"
    [length]="(recipes$ | async).length"
  >
  </mat-paginator>
</ng-container>
</ng-container>
