import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { catchError, tap, mergeMap, withLatestFrom, map, concatMap, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  ActionUnion,
  ActionTypes,
  GetCategoriesSuccess,
  GetCategoriesError,
  GetCategoriesOrderSuccess,
  GetCategoriesOrderError,
  PatchCategorySuccess,
  PatchCategoryError,
  PutCategoriesOrderSuccess,
  PutCategoriesOrderError,
  DeleteCategoryError,
  DeleteCategorySuccess,
  CreateCategorySuccess,
  CreateCategoryError,
  PutCategoriesOrderAttempt
} from '../actions/category.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../reducers';
import { CategoryOrder, Recipe } from '@gfs/shared-models';
import { MessageService } from '../../../lib/services/message.service';
import { CategoryService } from '../../../lib/services/category.service';
import {
  entityDictionaryToArray,
} from '@gfs/shared-services';
import {
  PatchRecipeAttempt,
} from '../actions/recipe.actions';

@Injectable()
export class CategoryEffects {

  constructor(
    private actions$: Actions<ActionUnion>,
    private categoryService: CategoryService,
    private messageService: MessageService,
    private store: Store<AppState>
  ) { }


  getCategories$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetCategoriesAttempt),
    mergeMap(() => this.store.select(state => state.auth.pk).pipe(
      filter(pk => !!pk),
      concatMap(pk => this.categoryService.getCategories(pk)),
      map(cats => new GetCategoriesSuccess(cats)),
      catchError(err => of(new GetCategoriesError(err)))
    ))));



  getCategoriesOrders$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.GetCategoriesOrderAttempt),
    concatMap(() => this.store.select(state => state.auth.pk).pipe(
      filter((pk) => !!pk),
      concatMap(pk => this.categoryService.getCategoriesOrder(pk)),
      map(categoriesOrder => new GetCategoriesOrderSuccess(categoriesOrder)),
      catchError(err => of(new GetCategoriesOrderError(err)))
    ))));


  createCategory$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.CreateCategoryAttempt),
    withLatestFrom(this.store),
    mergeMap(([action, state]) => {
      return this.categoryService.createCategory(action.categoryName, state.auth.pk)
        .pipe(
          map(newCat => new CreateCategorySuccess(newCat)),
          catchError(err => of(new CreateCategoryError(err))
          )
        );
    })
  ));


  patchCategory$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PatchCategoryAttempt),
    mergeMap((action) => {
      return this.categoryService.patchCategory(action.category)
        .pipe(
          map(cat => new PatchCategorySuccess(cat)),
          catchError(err => of(new PatchCategoryError(err)))
        );
    })
  ));


  putCategoriesOrders$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.PutCategoriesOrderAttempt),
    mergeMap((action: PutCategoriesOrderAttempt) => {
      return this.categoryService.putCategoriesOrder(action.categoryOrder)
        .pipe(
          map((categoryOrder: CategoryOrder) => new PutCategoriesOrderSuccess(categoryOrder)),
          catchError(error => of(new PutCategoriesOrderError(error)))
        );
    })
  ));


  deleteCategory$ = createEffect(() => this.actions$.pipe(
    ofType(ActionTypes.DeleteCategoryAttempt),
    withLatestFrom(this.store),
    mergeMap(([action, state]) => {
      const orphanedCategoriesPatch = entityDictionaryToArray<Recipe>(
        state.recipe.recipes
      )
        .filter((next) => next.categoryId === action.category.id)
        .map((next) => ({
          ...next,
          categoryId: null,
        }))
        .map(
          (recipe) =>
            new PatchRecipeAttempt({
              recipe,
              analyticsActionName: 'delete category',
            })
        )
        .forEach((patchAction) => this.store.dispatch(patchAction));

      return this.categoryService.deleteCategory(action.category).pipe(
        map(() => new DeleteCategorySuccess()),
        catchError((err) => of(new DeleteCategoryError(err)))
      );
    })
  ));


  categoryActionLogger$ = createEffect(() => this.actions$.pipe(
    ofType(
      ActionTypes.GetCategoriesError,
      ActionTypes.PatchCategoryError,
      ActionTypes.CreateCategoryError),
    tap(err => {
      this.messageService.queue(err.error.message);
    })
  ), { dispatch: false });
}
