import { DOCUMENT, registerLocaleData } from '@angular/common';
import locale_fr_CA from '@angular/common/locales/fr-CA';
import { AfterViewChecked, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Constants, InventoryConstants, RecipeConstants } from '@gfs/constants';
import { AppConfigService, WINDOW } from '@gfs/shared-services';
import { LogInAttempt, SetMobile, SetTitle } from '@gfs/store/common';
import { AppState } from '@gfs/store/inventory/reducers';
import { Store } from '@ngrx/store';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@gfs/shared-services/auth';
import LogRocket from 'logrocket';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { concatMap, debounceTime, filter, first, takeUntil } from 'rxjs/operators';
import { DeviceIdentifierService } from './services/shared/device-identifier/device-identifier.service';
import { ApplicationUserRole, RECIPE_PROFIT_CALCULATOR_CONFIG, RecipeProfitCalculatorConfig } from '@gfs/shared-models';

declare const require: any;
const matSelectArrowIcon = require('!!raw-loader!../assets/images/down-arrow.svg').default;
export const gordonNowChatBotFeature = new BehaviorSubject<boolean>(true);
export function disableGordonNowChatBot() { gordonNowChatBotFeature.next(false); }
export function enableGordonNowChatBot() { gordonNowChatBotFeature.next(true); }

// Make TS happy by extending `Window` to include the custom `__gordonNowChatUi`
// function that we use to init and configure the chatbot.
declare global {
  interface Window {
    __gordonNowChatUi: {
      init: (
        initializeFunctions: {
          language: { override: string };
          logRocket: { appId: string; isEnabled: boolean; parentIframeDomain: string };
        }) => void
    };
  }
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {

  isMobile$: Observable<boolean> = this.store.select(state => state.layout.isMobile);
  customerPk$ = this.store.select(state => state.auth.pk);
  headerHeight$ = this.store.select(state => state.layout.headerHeight);
  userClaims$ = this.store.select((state: AppState) => state.auth.user?.claims);
  isCustomerEntity$: Observable<boolean>;

  // local state because it's very simple. Can be pulled into the store if needed in other components
  showHeader = false;
  showHeaderElements = false;
  showSubHeader = false;
  notifier = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private documentService: Document,
    @Inject(WINDOW) private window: Window,
    private deviceIdentifierService: DeviceIdentifierService,
    private configService: AppConfigService,
    private translate: TranslateService,
    private store: Store<AppState>,
    private router: Router,
    public authService: AuthenticationService,
    @Inject(RECIPE_PROFIT_CALCULATOR_CONFIG) public recipeProfitCalculatorConfig: RecipeProfitCalculatorConfig,
  ) {
    this.initializeLogRocket();
    this.setAppDeviceType();

    // this language will be used as a fallback when a translation isn't found in the current language
    this.store.select((state: AppState) => {
      return state.layout.language;
    }).subscribe(language => this.translate.setDefaultLang(language));

    const noHeaderRoutes = [RecipeConstants.MOBILE_HAMBURGER_MENU_PATH];

    this.router.events.pipe(takeUntil(this.notifier)).subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showHeader = noHeaderRoutes.indexOf(event.url?.split('?')[0]) < 0;
        this.showHeaderElements = ( this.router.url !== InventoryConstants.CUSTOMER_UNIT_SELECTION_PATH 
                                   && this.router.url !== Constants.RecipeFeaturePaths.RECIPE_PROFIT_CALCULATOR[ApplicationUserRole.Customer].RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATHNAME 
                                   && this.router.url !== Constants.RecipeFeaturePaths.RECIPE_PROFIT_CALCULATOR[ApplicationUserRole.Employee].RECIPE_PROFIT_CALCULATOR_CUSTOMER_SELECTION_PATHNAME )
        this.showSubHeader = this.router.url !== RecipeConstants.CUSTOMER_UNIT_SELECTION_PATH;
      }
    });
  }

  async ngOnInit() {
    this.userClaims$.pipe(takeUntil(this.notifier)).subscribe(claims => {
      this.identifyLogRocket(claims);
    });

    // needed for datetime locales in DatePipe
    registerLocaleData(locale_fr_CA);

    this.setPageTitle();
    this.appendGoogleAnalytics();
  
    // TODO: analyze this potentially blocking event
    // there may be scenarios where this never resolves because of an unstable app/session state,
    // we need to handle it better with a timeout
    this.authService.authenticationState$()
      .pipe(filter(authState => authState.isAuthenticated))
      .subscribe(() => this.getCustomerInfoFromLocalStorage());

    const title = this.translate.instant('TITLE');
    this.store.dispatch(new SetTitle(title));

    this.translate.onLangChange.subscribe((newLang: LangChangeEvent) => {
      this.setPageTitle();
    });

    this.translate.onLangChange.subscribe((newLang: LangChangeEvent) => {
      this.initializeChatbot();
    });
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  private setPageTitle() {
    this.translate.get('TITLE').subscribe(title => {
      this.store.dispatch(new SetTitle(title));
    });
  }

  private appendGoogleAnalytics() {
    const config = this.configService.getSettings();

    // append GA minified script to head
    const scriptElement = document.createElement('script');
    const scriptText = document.createTextNode(config ? config.GOOGLE_TAG_MANAGER_SNIPPET : '');
    scriptElement.appendChild(scriptText);
    this.documentService.head.appendChild(scriptElement);
  }

  loadChatbot(): void {
    const config = this.configService.getSettings();
    const script = this.buildChatbotScriptNode(config && config.GORDON_NOW_CHAT_ENV ? config.GORDON_NOW_CHAT_ENV : '', 'gordon-now-chat-env');
    this.documentService.head.appendChild(script);
  }

  // This method is broken out for easier testing/mocking as the implementation result
  // is determined by executing an external script at runtime.
  isChatbotScriptLoaded(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const timeStartMs = new Date().getTime();
      const timeoutMs = 4000;
      const intervalCheckMs = 100;

      // Check every 100ms and resolve if the chatbot init function has completed.
      // Timeout entirely if the function is not ready after 4000ms
      // This promise allows us to wait until the setup function has finished running before
      // we try to initialize or update its properties.
      // Once the `init` function exists, we know the script is ready and can proceed.
      const wait = setInterval(function () {
        if (window.__gordonNowChatUi
          && window.__gordonNowChatUi.init
          && typeof window.__gordonNowChatUi.init === 'function') {
          clearInterval(wait);
          resolve();
        } else if ((new Date().getTime() - timeStartMs) > timeoutMs) { // Timeout
          clearInterval(wait);
          reject();
        }
      }, intervalCheckMs);
    });
  }

  buildChatbotScriptNode(url: string, id: string = ''): HTMLScriptElement {
    const node = document.createElement('script');
    node.setAttribute('data-manualInit', 'true');
    node.id = id;
    node.src = url;
    node.type = 'text/javascript';
    node.defer = true;
    return (node);
  }

  // Reinitialize chatbot language if there is an `LangChangeEvent`
  initializeChatbot(): Promise<void> {
    // Only initialize the chatbot if the currentLanguage has finished resolving.
    // Ignore changes where it is undefined
    let chatbotLanguageCode = '';
    if (this.translate.currentLang === RecipeConstants.LANGUAGES.EN_CA) {
      chatbotLanguageCode = 'en-CA';
    } else if (this.translate.currentLang === RecipeConstants.LANGUAGES.FR_CA) {
      chatbotLanguageCode = 'fr-CA';
    } else {
      return;
    }

    const isUserInRecipeProfitCalculator = this.recipeProfitCalculatorConfig.isNavigatingFromCalculator(this.window)
    if (isUserInRecipeProfitCalculator) {
      return;
    }

    const config = this.configService.getSettings();
    const logRocketProject = config ? config.LOGROCKET_PROJECT : '';
    const parentDomain = `${this.window.location.protocol}//${this.window.location.hostname}`;

    return this.isChatbotScriptLoaded().then(() => {
      window.__gordonNowChatUi.init({
        language: {
          override: chatbotLanguageCode.toString()
        },
        logRocket: {
          appId: logRocketProject,
          isEnabled: true,
          parentIframeDomain: parentDomain,
        },
      });
    });
  }

  getCustomerInfoFromLocalStorage(): void {
    const storedCustomer = JSON.parse(localStorage.getItem('customer'));
    this.store.dispatch(new LogInAttempt({ storedCustomerId: storedCustomer?.customerId }));
  }

  ngAfterViewChecked(): void {
    this.overrideMatSelectArrow();
  }

  initializeLogRocket() {
    const config = this.configService.getSettings();
    const logRocketProject = config ? config.LOGROCKET_PROJECT : '';

    if (logRocketProject) {
      console.log('Initialize LogRocket');
      LogRocket.init(logRocketProject, {
        console: {
          shouldAggregateConsoleErrors: true,
        },
        mergeIframes: true,
        childDomains: [
          // While we would prefer to be specific with our childDomains,
          // LogRocket isn't working when we do so.
          // We're falling back to the wildcard and relying on our CSP
          '*',
        ],
      } as any);
    }
  }

  identifyLogRocket(claims) {
    if (claims) {
      console.log('Identifying LogRocket');
      LogRocket.identify(claims.guid, {
        name: claims.firstName + ' ' + claims.lastName,
        email: claims.email,
      });
    }
  }

  private overrideMatSelectArrow() {
    const matSelectArrows = Array.from(window.document.getElementsByClassName('mat-select-arrow'));
    for (const arrow of matSelectArrows) {
      arrow.outerHTML = matSelectArrowIcon;
    }
  }

  private setAppDeviceType(): void {
    this.deviceIdentifierService.observeDeviceType()
      .pipe(debounceTime(500))
      .subscribe(isMobile => {
        this.store.dispatch(new SetMobile(isMobile));
      });
  }
}
