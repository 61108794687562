import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { PrintableRecipeVM } from '@gfs/shared-models';
import { WINDOW } from '@gfs/shared-services';

@Component({
  selector: 'app-printable-recipe',
  templateUrl: './printable-recipe.component.html',
  styleUrls: ['./printable-recipe.component.scss'],
})
export class PrintableRecipeComponent implements OnInit , OnDestroy{
  @Input()
  AutoPrint = false;

 @Input()
  public set vm(value: PrintableRecipeVM) {
    this.refreshedVM = value;
    if(value !== null){
      this.formatVm(this.refreshedVM)
      setTimeout(()=>{
        this.printRecipePDF()
      },500)
    }
  }
  public removePrintEventListener: () => void;
  refreshedVM : PrintableRecipeVM 

  constructor(
    @Inject(WINDOW) private window: Window,
    public elementRef: ElementRef, 
    public renderer: Renderer2
  ) {}

  ngOnDestroy(): void {
    this.removePrintEventListener();
  }

  ngOnInit(): void {
    this.removePrintEventListener = this.renderer.listen(document, 'keydown', (event) => {
          if (event.keyCode === 80 && (event.ctrlKey || event.metaKey) && !event.altKey && (!event.shiftKey)) {event.preventDefault();
            this.printRecipePDF();}
    });
  }

  formatVm(vm:PrintableRecipeVM): PrintableRecipeVM{
    const roundedCost = vm.recipeTotalCost.toFixed(2);
    const roundedCostNumeric = Number(roundedCost);
    vm.recipeTotalCost = roundedCostNumeric ; 
    return vm as PrintableRecipeVM
  }
  printRecipePDF(): void { 
    let printWindow = null;
    let printed : boolean;
    const myRecipeLabel = 'My Recipe'
    const heightWidthDetails = 'height=768,width=1024'
    
    const htmlStartTag = '<html>'
    const headStartTag = '<head>'
    const titleStartTag = '<title>'
    const bodyStartTag = '<body>'
    const htmlEndTag = '</html>'
    const headEndTag = '</head>'
    const titleEndTag = '</title>'
    const bodyEndTag = '</body>'

    const recipePrintElementId = 'recipe-print'
    const afterPrintEvent = 'afterprint'


    printWindow =  this.window.open('', myRecipeLabel, heightWidthDetails); 
    printWindow.document.write(htmlStartTag+headStartTag+titleStartTag+myRecipeLabel+titleEndTag+headEndTag); 
    printWindow.document.write(bodyStartTag + document.getElementById(recipePrintElementId).innerHTML + bodyEndTag+htmlEndTag);
    printWindow.addEventListener(afterPrintEvent, ()=> {
      printWindow.close();
    });
    setTimeout(()=>{
      printWindow.print();
      printed = true;
      printWindow.removeEventListener(afterPrintEvent, ()=> {printWindow.close()});
    },500)
  }

}
