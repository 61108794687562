import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  recipeName: string;
}

@Component({
  selector: 'app-recipe-delete-modal',
  templateUrl: './recipe-delete-modal.component.html',
  styleUrls: ['./recipe-delete-modal.component.scss']
})
export class RecipeDeleteModalComponent implements OnInit {
  recipeName: string;
  form: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<RecipeDeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.recipeName = (this.data && this.data.recipeName) ? this.data.recipeName : '';
    this.form = this.formBuilder.group({
      recipeName: this.data ? this.data.recipeName : ''
    });
  }

  closeModal() {
    this.form.value.recipeName = '';
    this.dialogRef.close();
  }

  submit(form): void {
    this.dialogRef.close(form.value.recipeName);
  }
}
