<div class="modal-container">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <button class="close-button" (click)="closeModal()" attr.aria-label="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}">
      <img class="close-button-img" src="assets/images/close-icon.svg" alt="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}"/>
    </button>
    <div class="delete-modal-container">
      <div class="modal-warning-text">{{ 'CATEGORY.DELETE_CATEGORY_MODAL.DELETE_CATEGORY_DIALOG_TITLE' | translate: {'value': categoryName} }}</div>
      <div class="modal-warning-subtext">{{ 'CATEGORY.DELETE_CATEGORY_MODAL.DELETE_CATEGORY_DIALOG_SUBTITLE' | translate }}</div>
      <div class="modal-button-bar">
        <button id="cancel-button"
                class="secondary-destructive-button large-button"
                (click)="closeModal()"
                attr.aria-label="{{ 'MODALS.CANCEL_BUTTON_ARIA_LABEL' | translate }}">
                {{ 'MODALS.CANCEL' | translate }}
        </button>
        <button id="delete-button"
                class="primary-destructive-button large-button"
                type="submit"
                attr.aria-label="{{ 'CATEGORY.DELETE_CATEGORY_BUTTON_ARIA_LABEL' | translate }}">
                {{ 'CATEGORY.DELETE_CATEGORY_BUTTON' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
