<div class="modal-container">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div class="rename-modal-container">
      <div *ngIf="isNewCategory" class="modal-title">{{ 'CATEGORY.CREATE_CATEGORY_MODAL.CREATE_TITLE' | translate }}</div>
      <div *ngIf="!isNewCategory" class="modal-title">{{ 'CATEGORY.CREATE_CATEGORY_MODAL.RENAME_TITLE' | translate }}</div>
      <mat-form-field class="create-category-form-field">
        <input matInput
                #categoryName
                class="recipe-heading"
                formControlName="categoryName"
                [errorStateMatcher]="matcher"
                maxlength="25"
                placeholder="{{ 'CATEGORY.CREATE_CATEGORY_MODAL.CATEGORY_NAME_PLACEHOLDER' | translate }}">
        <mat-hint align="end">{{categoryName.value?.length || 0}}/25</mat-hint>
        <mat-error *ngIf="form.get('categoryName').hasError('categoryExists')">{{ 'CATEGORY.CREATE_CATEGORY_MODAL.CATEGORY_ALREADY_EXISTS_ERROR' | translate }}</mat-error>
        <mat-error *ngIf="form.get('categoryName').hasError('invalidCategoryName')">{{ 'CATEGORY.CREATE_CATEGORY_MODAL.CATEGORY_ALREADY_EXISTS_ERROR' | translate }}</mat-error>
        <mat-error *ngIf="form.get('categoryName').hasError('required')">{{ 'CATEGORY.CREATE_CATEGORY_MODAL.CATEGORY_IS_REQUIRED_ERROR' | translate }}</mat-error>
      </mat-form-field>
      <div class="modal-button-bar">
        <button id="cancel-button"
                class="secondary-button large-button"
                type="button"
                (click)="closeModal()"
                attr.aria-label="{{ 'MODALS.CANCEL_BUTTON_ARIA_LABEL' | translate }}">
                {{ 'MODALS.CANCEL' | translate }}
        </button>
        <button *ngIf="isNewCategory"
                id="create-category-button"
                class="primary-button large-button"
                type="submit"
                [disabled]="!form.valid"
                attr.aria-label="{{ 'CATEGORY.CREATE_CATEGORY_MODAL.CREATE_CATEGORY_BUTTON_ARIA_LABEL' | translate }}">
                {{ 'CATEGORY.CREATE_CATEGORY_MODAL.CREATE_CATEGORY_BUTTON' | translate }}
        </button>
        <button *ngIf="!isNewCategory"
                id="edit-category-button"
                class="primary-button large-button"
                type="submit"
                [disabled]="!form.valid"
                attr.aria-label="{{ 'CATEGORY.EDIT_CATEGORY_BUTTON_ARIA_LABEL' | translate }}">
                {{ 'CATEGORY.EDIT_CATEGORY_BUTTON' | translate }}
        </button>
      </div>
    </div>
    <button class="close-button" (click)="closeModal()" attr.aria-label="{{ 'MODALS.CANCEL_BUTTON_ARIA_LABEL' | translate }}">
      <img class="close-button-img" src="assets/images/close-icon.svg" alt="{{ 'MODALS.CLOSE_BUTTON_ARIA_LABEL' | translate }}"/>
    </button>
  </form>
</div>
