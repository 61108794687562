import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { SharedDirectivesModule } from '@gfs/directives';
import { MaterialModule, PipeSharingModule, QuickAddItemsModule, SharedComponentsModule } from '@gfs/shared-components';
import { SharedComponentsV2Module } from '@gfs/v2/shared-components';
import { TranslateModule } from '@ngx-translate/core';
import { RecipeModule } from '@recipe-ui/recipe/recipe.module';
import { UIModule } from '@recipe-ui/ui/ui.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { EditRecipeV2Component } from './edit/edit.component';
import { RecipeEditContainerFactory, RecipeEditStore } from './edit/edit.component.store';
import { RecipeEditFormControlFactoryService } from './edit/form-factory.service';
import { EditIngredientCardV2Component } from './edit/edit-ingredient/edit-ingredient.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    PipeSharingModule,
    NgxPermissionsModule,
    SharedComponentsModule,
    MaterialModule,
    MatDividerModule,
    RecipeModule,
    UIModule,
    QuickAddItemsModule,
    SharedDirectivesModule,
    SharedComponentsV2Module,
  ],
  declarations: [
    EditRecipeV2Component,
    EditIngredientCardV2Component,
  ],
  exports: [
    EditRecipeV2Component,
  ],
  providers: [
    RecipeEditStore,
    RecipeEditContainerFactory,
    RecipeEditFormControlFactoryService,
    EditRecipeV2Component,
  ]
})

export class RecipeV2Module {

}
