import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { RecipeEditStore, RecipeState } from '@recipe-ui/v2/edit/edit.component.store';
import { RecipeEditFormControl } from '@recipe-ui/v2/edit/form-factory.service';
import { Observable } from 'rxjs';
import { GetRecipeResolver } from './get-recipe.resolver';

export type RecipeComponentData = {
  recipeEditStore: RecipeEditStore;
  recipeState: RecipeState;
  formControl: RecipeEditFormControl;
};

export const getRecipeResolverFn: ResolveFn<Observable<RecipeComponentData>> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  getRecipeResolver = inject(GetRecipeResolver)
): Observable<RecipeComponentData> => {
  return getRecipeResolver.resolve(route, state);
};
