<div class="category-list-container">
<mat-list id="category-list" cdkDropList class="item-drag" (cdkDropListDropped)="drop($event)">
    <mat-list-item 
    *ngIf="hasRecipes(null) | async"
    class="category-bkg"
    (click)="categoryClicked({})"
    (keyup.ENTER)="categoryClicked({})"
    tabindex="0">
    <div class="category-item">
      <div cdkDragHandle class="drag-handle"></div>
      <p class="category-name">{{ 'CATEGORY.UNASSIGNED_CATEGORY_LABEL' | translate: {'value': (getRecipes(null) | async).length} }}</p>
      <div class="icon-container"></div>
    </div>
  </mat-list-item>
  <mat-list-item 
    id="category-list-item-{{category.name.replace(' ', '-')}}-{{category.id}}"
    class="category-bkg"
    *ngFor="let category of orderedCategories | orderBy: 'ordinal'"
    (click)="categoryClicked(category)"
    (focus)="onFocus(category.id, category.name)"
    (mouseover)="onFocus(category.id, category.name)"
    (mouseout)="onBlur(category.id, category.name)"
    (keydown.ArrowRight)="onRightArrow(category.id, category.name)"
    (keydown.ArrowLeft)="onLeftArrow(category.id, category.name)"
    (keyup.ENTER)="categoryClicked(category)"
    tabindex="0"
    cdkDrag
    cdkDragBoundary="category-sidenav">
    <div class="category-item">
      <div cdkDragHandle class="drag-handle">
        <img class="img-drag-handle" src="assets/images/category_draghandle_gray.svg" alt="{{ 'CATEGORY.CATEGORY_LIST.DRAG_AND_DROP_ALT' | translate }}"/>
      </div>
      <p class="category-name">{{categoryUtils.getTranslation(category.name) | translate | truncated:17}} ({{category | categoryRecipesCount:(recipes$ | async)}})</p>
      <div 
        id="category-list-edit-container-{{category.name.replace(' ', '-')}}-{{category.id}}"
        class="icon-container">
        <button 
          id="category-list-edit-button-{{category.name.replace(' ', '-')}}-{{category.id}}"
          class="icon-hover-bkg"
          tabindex="-1"
          attr.aria-label="{{ 'CATEGORY.CATEGORY_LIST.EDIT_BUTTON_ARIA_LABEL' | translate }}"
          (click)="onEditCategory(category)">
          <mat-icon class="icon category-edit-icon" svgIcon="edit-icon"></mat-icon>
        </button>
      </div>
      <div
        id="category-list-delete-container-{{category.name.replace(' ', '-')}}-{{category.id}}"
        class="icon-container">
        <button 
          id="category-list-delete-button-{{category.name.replace(' ', '-')}}-{{category.id}}"
          class="icon-hover-bkg"
          tabindex="-1"
          attr.aria-label="{{ 'CATEGORY.CATEGORY_LIST.DELETE_BUTTON_ARIA_LABEL' | translate }}"
          (click)="onDeleteCategory(category)">
          <mat-icon class="icon category-edit-icon" svgIcon="trash-icon"></mat-icon>
        </button>
      </div>
    </div>
  </mat-list-item>
</mat-list>
</div>
