import { Pipe, PipeTransform } from '@angular/core';
import { Recipe, RecipeCategory } from '@gfs/shared-models';

@Pipe({
  name: 'categoryRecipesCount'
})
export class CategoryRecipesCountPipe implements PipeTransform {

  transform(category: RecipeCategory, recipeList: Recipe[]): number {
    return (recipeList ?? [])
      .reduce((acc: number, next: Recipe) => {
        if (next.categoryId === category.id) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);

  }

}
